import React, { useState, FC, useEffect } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { ChipSet, Chip } from '@fv-components/chips';

import useQueryProjectTeam, { ITeamMember, TeamTypes } from '../../../util-api/useQueryProjectTeam';
import useInput from '../../../util-hooks/useInput';
import useDebounce from '../../../util-hooks/useDebounce';

import SearchLimitWarning from '../../SearchLimitWarning';
import Persona from '../Persona';
import SecondarySearchBar from '../SecondarySearchBar';
import TeamMember from './TeamMember';

const cssCommon = require('../../../Common.module.scss');
const css = require('./Team.module.scss');

interface ITeamProps {
  projectId?: number;
}

interface IGroups {
  Follower: ITeamMember[];
  Collaborator: ITeamMember[];
  Guest: ITeamMember[];
}

const buildTeamGroups = (teamData: ITeamMember[]) => {
  const groups: IGroups = {
    Follower: [],
    Collaborator: [],
    Guest: [],
  };

  teamData.forEach((m) => {
    groups[m.level].push(m);
  });

  return groups;
};

const Team: FC<ITeamProps> = ({ projectId }: ITeamProps) => {
  // Search and fetch
  const [values, handleValueChange] = useInput({ teamSearchTerm: '' });
  const searchPlaceholderText = 'Search team by name, email or username.';

  const {
    getProjectTeam,
    projectTeamSearchResults: {
      team, loading, error, called,
    },
  } = useQueryProjectTeam();
  const teamMembers = (team && team.items) || [];
  const debouncedTeamSearchTerm = useDebounce(values.teamSearchTerm, 300).trimStart();

  useEffect(() => {
    if (projectId) {
      getProjectTeam(
        projectId,
        debouncedTeamSearchTerm.startsWith('@') ? debouncedTeamSearchTerm.slice(1) : debouncedTeamSearchTerm,
        1000,
      );
    }
  }, [projectId, debouncedTeamSearchTerm, getProjectTeam]);

  // Setup group filters
  const [teamGroups, setTeamGroups] = useState<IGroups>();
  useDeepCompareEffect(() => {
    setTeamGroups((
      (teamMembers && teamMembers.length) && !error
    ) ? buildTeamGroups(teamMembers) : undefined);
  }, [error, teamMembers]);
  const teamTypes:TeamTypes[] = ['Follower', 'Collaborator', 'Guest'];
  const defaultTeamTypes = [teamTypes[0]];
  const [selectedTeamTypeIds, setSelectedTeamTypeIds] = useState<string[]>(() => defaultTeamTypes);

  return (
    <>
      <div className={css.teamHeader}>
        <SecondarySearchBar
          searchInputName="teamSearchTerm"
          searchPlaceholderText={searchPlaceholderText}
          searchTerm={values.teamSearchTerm}
          handleSearchTermChange={handleValueChange}
          loading={loading}
        />
        <ChipSet
          choice
          className={css.teamTypes}
          selectedChipIds={selectedTeamTypeIds}
          handleSelect={(
            ids: string[],
          ) => setSelectedTeamTypeIds(ids.length ? ids : defaultTeamTypes)}
        >
          {teamTypes.map((t: TeamTypes) => (
            <Chip
              key={t}
              id={t}
              label={`${t} (${teamGroups ? teamGroups[t].length : 0})`}
              className={css.teamLevelChip}
            />
          ))}
        </ChipSet>
      </div>
      {!teamGroups ? (called && !loading && (
        <div className={css.teamSearchNoResults}>
          No team members found
        </div>
      ))
        : (
          <>
            {team && team.hasMore && (
            <SearchLimitWarning limit={team.limit} />
            )}
            {teamTypes.map((t: TeamTypes) => (
              <div
                key={t}
                className={
                  [
                    css.teamGroupContainer,
                    t === selectedTeamTypeIds[0] ? '' : cssCommon.hidden,
                  ].join(' ')
                }
              >
                {teamGroups[t].length > 0
                  ? teamGroups[t].map((m: ITeamMember) => (
                    <TeamMember
                      key={m.username}
                      username={m.username}
                      userId={m.userId}
                      fullname={m.fullname}
                      isAdmin={m.isAdmin}
                      isPrimary={m.isPrimary}
                    />
                  ))
                  : <div className={css.teamSearchNoResults}>{`No ${t.toLowerCase()}s found`}</div>}
              </div>
            ))}
          </>
        )}
      {(!teamGroups && loading) && <div className={css.teamLoading}><Persona loading small /></div>}
    </>
  );
};

export default Team;
