import React, { FC } from 'react';
import LinearProgress from '@fv-components/linear-progress';

const css = require('./SecondarySearchBar.module.scss');

interface ISecondarySearchBarProps {
  searchInputName: string;
  searchPlaceholderText: string;
  searchTerm: string;
  handleSearchTermChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  loading?: boolean;
}

const SecondarySearchBar: FC<ISecondarySearchBarProps> = ({
  searchInputName,
  searchPlaceholderText,
  searchTerm,
  handleSearchTermChange,
  loading,
}: ISecondarySearchBarProps) => (
  <>
    <div className={css.secondarySearchBarContainer}>
      <input
        className={`${css.secondarySearchBarInput} mdc-menu-surface--anchor`}
        name={searchInputName}
        placeholder={searchPlaceholderText}
        value={searchTerm}
        onChange={handleSearchTermChange}
      />
    </div>
    <LinearProgress
      indeterminate={loading}
      closed={!loading}
      className={css.secondarySearchBarLoading}
    />
  </>
);

export default SecondarySearchBar;
