import React, {
  FC, useEffect, useState,
} from 'react';
import dayjs from 'dayjs';

import { INote } from '../../../../util-api/useQueryNote';
import { dateFormat } from '../../../../util-helpers/common';
import { getIsMobile } from '../../../../util-helpers/office';

const css = require('./EmailActivityHeader.module.scss');

const formatDueDate = (dueDateISO: string) => (
  dayjs(dueDateISO, { utc: true }).format(dateFormat.DOW_AND_DATE)
);
const labels = {
  overdue: 'Overdue',
  today: 'Due today',
  future: 'Set for',
};
const taskDueDateStatus = (dueDate: string): string => {
  const now = new Date();
  const today = Date.UTC(now.getFullYear(), now.getMonth(), now.getDate());
  const isOverdue = Date.parse(dueDate) < today;
  const isDueToday = Date.parse(dueDate) === today;
  if (isOverdue) {
    return labels.overdue;
  }
  if (isDueToday) {
    return labels.today;
  }
  return labels.future;
};

const updateEmailSubject = (isComposing: boolean): string => {
  if (!isComposing && Office?.context?.mailbox?.item) {
    return Office.context.mailbox.item.subject;
  }
  return '';
};

interface IEmailActivityHeaderProps {
  isComposing: boolean;
  currentEmailId?: string;
  note?: INote;
  getTeamMemberName: (id: number) => string;
  isInputFocus: boolean;
}

const EmailActivityHeader: FC<IEmailActivityHeaderProps> = (
  {
    isComposing,
    currentEmailId,
    getTeamMemberName,
    note,
    isInputFocus,
  }: IEmailActivityHeaderProps,
) => {
  // Set the email subject and keep it updated if the user changes emails
  const [emailSubject, setEmailSubject] = useState(() => updateEmailSubject(isComposing));
  useEffect(() => {
    setEmailSubject(() => updateEmailSubject(isComposing));
  }, [currentEmailId, isComposing]);

  const taskStatus = note?.targetDate && taskDueDateStatus(note?.targetDate);
  const taskDueDateStatusCSSClass = (taskStatusStr: string) => {
    switch (taskStatusStr) {
      case labels.today:
        return css.emailActivityTaskLabelDueToday;
      case labels.overdue:
        return css.emailActivityTaskLabelOverdue;
      case labels.future:
      default:
        return css.emailActivityTaskLabel;
    }
  };
  const formattedDueDate = note?.targetDate && formatDueDate(note.targetDate);
  const hideOnFocus = isInputFocus && getIsMobile();
  return (
    <div className={hideOnFocus ? css.EmailActivityHeaderInputFocus : css.emailActivityHeader}>
      <div className={css.emailActivitySubject}>
        {emailSubject}
      </div>

      {/* Task assignment widget */}
      {note && (note.assigneeId || note.targetDate) && (
      <>
        <div className={css.emailActivityTask}>
          {note.assigneeId && note.assigneeId.native && (
          <div className={css.emailActivityTaskDetail}>
            <span className={css.emailActivityTaskLabel}>Assigned to: </span>
            {getTeamMemberName(note.assigneeId.native)}
          </div>
          )}

          {/* Task Due in Future */}
          {note.targetDate && !note.isCompleted && taskStatus && (
          <div>
            <span className={taskDueDateStatusCSSClass(taskStatus)}>{`${taskStatus}:`}</span>
            &nbsp;
            {formattedDueDate}
          </div>
          )}

          {/* Completed Task */}
          {note.targetDate && note.isCompleted && note.completedDate && (
          <div>
            <span className={css.emailActivityTaskLabel}>Completed on: </span>
            {formatDueDate(note.completedDate)}
          </div>
          )}

        </div>
        <div className={css.emailActivityTaskDivider} />
      </>
      )}
    </div>
  );
};

export default EmailActivityHeader;
