import React, {
  FC, useContext, useEffect, useState,
} from 'react';

import useQueryBillingSettings from '../../../util-api/useQueryBillingSettings';
import useQueryProjectClient from '../../../util-api/useQueryProjectClient';
import useQueryProjectVitals from '../../../util-api/useQueryProjectVitals';
import { getIsComposing, getIsMeeting } from '../../../util-helpers/office';

import FilevineIcon from '../../FilevineIcon';
import ProjectContext from '../../ProjectContext';
import BillingTimer from '../BillingTimer';
import CollapsibleSection from '../CollapsibleSection';
import ContactCard from '../ContactCard';
import GenericCard from '../GenericCard';
import Persona from '../Persona';
import Vitals from '../Vitals';

const css = require('./ProjectHeader.module.scss');

const ProjectHeader: FC = () => {
  const { currentProjectId, currentProjectData } = useContext(ProjectContext);
  const isComposing = getIsComposing();
  const isMeeting = getIsMeeting();

  // Get org billing settings
  const {
    getBillingSettings,
    called: billingCalled,
    loading: billingLoading,
    error: billingError,
    billingSettings,
  } = useQueryBillingSettings();
  useEffect(() => {
    getBillingSettings();
  }, [getBillingSettings]);

  // Get vitals data when project changes
  const { getProjectVitals, projectVitalsSearchResult: { vitals } } = useQueryProjectVitals();
  useEffect(() => {
    if (currentProjectId) {
      getProjectVitals(currentProjectId);
    }
  }, [currentProjectId, getProjectVitals]);

  // Get client data when project changes
  const {
    getProjectClient,
    projectClientSearchResults: {
      client,
      error: clientError,
      loading: clientLoading,
    },
  } = useQueryProjectClient();
  const [expandedContactId, setExpandedContactId] = useState(0);
  useEffect(() => {
    if (currentProjectId) {
      getProjectClient(currentProjectId);
    }
  }, [currentProjectId, getProjectClient]);

  return (
    <>
      {clientLoading
        && <div className={css.projectContactLoading}><Persona loading small /></div>}
      {client
        && client.personId
        && !clientError
        && (
          <ContactCard
            emails={client.emails}
            fullName={client.fullName}
            contactId={client.personId.native}
            pictureUrl={client.pictureUrl}
            primaryEmail={client.primaryEmail}
            primaryEmailLabel={client.primaryEmailLabel}
            userRole="client"
            isExpanded={expandedContactId === client.personId.native}
            setExpandedContactId={setExpandedContactId}
            isComposing={isComposing}
            isMeeting={isMeeting}
          />
        )}
      {currentProjectId
        && billingCalled
        && !billingError
        && !billingLoading
        && billingSettings?.billingV2Enabled
        && billingSettings?.isBillingEnabled
        && (
        <GenericCard
          outlined={false}
          buttonTitle=" Billing"
          buttonIcon={<FilevineIcon icon="clock-o" />}
          child={
            <BillingTimer projectId={currentProjectId} />
          }
        />
        )}
      <CollapsibleSection
        collapsed
        title="Vitals"
        icon="heartbeat"
        content={(
          <Vitals
            vitals={vitals}
            phaseName={currentProjectData ? currentProjectData.phaseName : undefined}
          />
        )}
      />
    </>
  );
};

export default ProjectHeader;
