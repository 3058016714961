import { useLazyQuery } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client/errors/ApolloError';
import gql from 'graphql-tag';
import { useCallback } from 'react';

interface IVital {
  fieldName: string;
  friendlyName: string;
  fieldType: string;
  value?: string;
  position: number;
}

interface IProjectVitalsResponse {
  vitals: IVital[];
}

interface IProjectVitalsPayload {
  called: boolean;
  loading: boolean;
  error?: ApolloError;
  vitals: IVital[] | [];
}

export interface IQueryProjectVitalsHook {
  projectVitalsSearchResult: IProjectVitalsPayload;
  getProjectVitals: (projectId: number) => void;
}

export const VITALS_QUERY = gql`
  query vitalsQuery {
    vitals(id: $id)
      @rest(type: "Vitals", path: "/projects/{args.id}/vitals") {
        fieldName
        friendlyName
        fieldType
        value
        position
    }
  }`;

const useQueryProjectVitals = (): IQueryProjectVitalsHook => {
  const [executeQuery, {
    data, error, loading, called,
  }] = useLazyQuery<IProjectVitalsResponse>(
    VITALS_QUERY, { notifyOnNetworkStatusChange: true, fetchPolicy: 'cache-and-network' },
  );

  const getProjectVitals = useCallback(
    (id: number) => executeQuery({ variables: { id } }), [executeQuery],
  );

  return {
    projectVitalsSearchResult: {
      called,
      error,
      loading,
      vitals: (data && data.vitals) ? data.vitals : [],
    },
    getProjectVitals,
  };
};

export default useQueryProjectVitals;
