import { useState, useEffect } from 'react';

interface IUseIpifyHook {
 clientIpAddress?: string
}

interface IClientIpAddress {
  ip: string;
}

const useIpAddress = () : IUseIpifyHook => {
  const [clientIpAddress, setClientIpAddress] = useState<string>();

  // i wrapped it in a use effect hook with an empty
  // array to make sure it doesn't run multiple times
  useEffect(() => {
    fetch('https://api.ipify.org/?format=json', {
      method: 'get',
    }).then((response) => response.json())
      .then((data: IClientIpAddress) => {
        setClientIpAddress(data.ip);
      });
  }, []);

  return {
    clientIpAddress,
  };
};

export default useIpAddress;
