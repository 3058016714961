import { useLazyQuery } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client/errors/ApolloError';
import gql from 'graphql-tag';
import { useCallback } from 'react';

export interface IFolder {
  'folderId':{
    'native': number;
    'partner'?: number;
  },
  'parentId'?:{
    'native': number;
    'partner'?: number;
  },
  'projectId':{
    'native': number;
    'partner'?: number;
  },
  'name':string;
  'links':{
    'project': string;
    'self': string;
    'children': string;
    'parent': string;
  }
}

interface IFolderList {
    'count': number;
    'offset': number;
    'limit': number;
    'hasMore': boolean;
    'requestedFields': string;
    'items': IFolder[];
    'links': {
      'parent': string;
    }
}

interface IFolderListResponse {
  folderList: IFolderList;
}

export interface IFolderListPayload {
  called: boolean;
  loading: boolean;
  error?: ApolloError;
  folderList?: IFolderList;
}
export interface IQueryFolderListHook {
  folderListResult: IFolderListPayload;
  getFolderList: (folderPath?: string, projectId?: number) => void;
}

export const FOLDER_LIST_QUERY = gql`
  query folderListQuery {
    folderList(projectId: $projectId, folderPath: $folderPath)
      @rest(type: "folderList", path: "{args.folderPath}?projectId={args.projectId}") {
        count
        hasMore
        items
        limit
        links
        offset
        requestedFields
      }
  }`;

const useQueryFolderList = (): IQueryFolderListHook => {
  const [executeQuery, {
    data, error, loading, called,
  }] = useLazyQuery<IFolderListResponse>(
    FOLDER_LIST_QUERY, { notifyOnNetworkStatusChange: true, fetchPolicy: 'cache-and-network' },
  );

  const getFolderList = useCallback(
    (folderPath: string = '/folders', projectId?: number) => executeQuery({
      variables: {
        projectId,
        folderPath,
      },
    }), [executeQuery],
  );

  return {
    folderListResult: {
      called,
      error,
      loading,
      folderList: data ? data.folderList : undefined,
    },
    getFolderList,
  };
};

export default useQueryFolderList;
