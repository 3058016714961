import React from 'react';
import { IFolder } from '../../util-api/useQueryFolderList';
import EditableChip from '../EditableChip';
import FolderPicker from '../FolderPicker';

interface IDocumentEditorProps {
  filename: string;
  id: string;
  handleSelection: (id: string) => void;
  isSelectedAttachment: (id: string) => boolean;
  onRenameFile: (id: string, newName: string) => void;
  onFolderSelectionCallback: (folder: IFolder | undefined) => void;
  clearSelected: () => void;
  selectedFolder: IFolder | undefined;
  setParentFolder: (parentFolder: IFolder) => void;
  getParentFolder: (parentKey: number) => IFolder;
}
const DocumentEditor = ({
  filename,
  id,
  handleSelection,
  isSelectedAttachment,
  onRenameFile,
  onFolderSelectionCallback,
  clearSelected,
  selectedFolder,
  setParentFolder,
  getParentFolder,
}: IDocumentEditorProps) => (
  <>
    <EditableChip
      label={filename}
      id={id}
      key={id}
      handleSelection={handleSelection}
      isSelectedAttachment={isSelectedAttachment}
      onRenameFile={onRenameFile}
    />
    <p>Select Folder Destination</p>
    <FolderPicker
      filename={filename}
      currentlySelectedFolder={selectedFolder}
      setSelectedCallback={onFolderSelectionCallback}
      clearSelectedCallback={clearSelected}
      setParentFolder={setParentFolder}
      getParentFolder={getParentFolder}

    />
  </>
);

export default DocumentEditor;
