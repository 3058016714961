import React, {
  FC,
} from 'react';

import FilevineIcon from '../../../FilevineIcon';

const css = require('./FolderBreadcrumbs.module.scss');

interface IBreadcrumbProps {
  currentProjectName: string;
  isProjectRoot: boolean;
  shouldHideParentLink: boolean;
  parentFID: string;
  folderNames: {
    [key: string]: string;
  };
  currFolderId: string;
  handleProjectFolderClicked: any;
  handleParentFolderClicked: any;
}

const FolderBreadcrumbs: FC<IBreadcrumbProps> = ({
  currentProjectName,
  isProjectRoot,
  shouldHideParentLink,
  parentFID,
  folderNames,
  currFolderId,
  handleProjectFolderClicked,
  handleParentFolderClicked,
}) => (
  <div className={css.folderLinks}>
    {isProjectRoot && (
      <span
        data-test="project-folder-link"
        className={`${css.folderDir} ${css.folderDirRoot}`}
      >
        {currentProjectName}
      </span>
    )}
    {!isProjectRoot && (
      <span
        role="link"
        tabIndex={0}
        data-test="project-folder-link"
        className={`${css.folderLink} ${css.folderDir}`}
        onClick={() => handleProjectFolderClicked()}
        onKeyDown={(e: React.KeyboardEvent<HTMLSpanElement>) => {
          if (e.key === 'Enter') {
            handleProjectFolderClicked();
          }
        }}
      >
        {currentProjectName}
      </span>
    )}
    {!shouldHideParentLink
      && !isProjectRoot
          && (
            <>
              <span
                role="link"
                tabIndex={0}
                data-test="parent-folder-link"
                className={`${css.folderLink} ${css.folderDir}`}
                onClick={() => handleParentFolderClicked()}
                onKeyDown={(e: React.KeyboardEvent<HTMLSpanElement>) => {
                  if (e.key === 'Enter') {
                    handleParentFolderClicked();
                  }
                }}
              >
                {(parentFID && folderNames[parentFID]) && <FilevineIcon icon="chevron-right" className={css.folderIcon} />}
                {(parentFID && folderNames[parentFID]) || 'back'}
              </span>
            </>
          )}
    {currFolderId && folderNames[currFolderId]
        && (
          <>
            <span className={css.folderDir}>
              <FilevineIcon icon="chevron-right" className={css.folderIcon} />
              {folderNames[currFolderId] || ''}
            </span>
          </>
        )}
  </div>
);

export default FolderBreadcrumbs;
