import {
  IAttachment,
  EmailPayload,
  IEmailContact,
} from '../util-api/useMutateEmail';
import {
  IOutlookItemEmailAddress,
  IOutlookLocalItemPayload,
} from '../util-api/models/outlookItem';
import { simplifyHtml } from './common';
import { IEmlInformation, IOfficeAttachment } from '../Taskpane/ProjectWorkspace/EmailActivity/EmailActivity';
import { IComposeAttachment } from '../Taskpane/ProjectWorkspace/EmailActivity/EmailCompose/EmailCompose';

const getIsOfficeJs = () => !!Office?.context?.diagnostics?.host;
const getIsOfficeApp = () => getIsOfficeJs()
  && Office?.context?.host?.toString().toLocaleLowerCase().includes('word' || 'excel' || 'powerpoint');
const getIsOutlook = () => getIsOfficeJs()
  && (Office.context?.diagnostics?.host === Office.HostType.Outlook);
const getIsComposing = () => getIsOutlook()
  && !!Office.context.mailbox.item && !Office.context.mailbox.item.itemId;
const getIsReading = () => getIsOutlook()
  && !!Office.context.mailbox.item && !!Office.context.mailbox.item.itemId;
const getIsWeb = () => getIsOutlook()
  && (Office.context.diagnostics.platform === Office.PlatformType.OfficeOnline);
const getIsDesktop = () => getIsOutlook()
  && (Office.context.diagnostics.platform === Office.PlatformType.PC
    || Office.context.diagnostics.platform === Office.PlatformType.Mac);
const getIsMobile = () => getIsOutlook()
    && (Office.context.diagnostics.platform === Office.PlatformType.Android
      || Office.context.diagnostics.platform === Office.PlatformType.iOS);
const itemTypeMeetingClass = 'IPM.Schedule.Meeting';
const getIsMeetingClass = () => getIsOutlook() && Office.context?.mailbox?.item?.itemClass
  && `${Office.context.mailbox.item.itemClass}`.includes(itemTypeMeetingClass);
const getIsMeeting = () => getIsOutlook()
  && !!Office.context?.mailbox?.item
  && Office.context?.mailbox?.item?.itemType === 'appointment';
// NOTE: convertToRestId() is not available on the Office class on iOS and Android app
const mobileConvertToRestId = (str: string) => str.replace(/\+|\/|-/g, '_');
const getAddressAsyncCallback = (asyncResult: Office.AsyncResult<Office.EmailAddressDetails[]>) => {
  const finder = (emailAddressDetails: Office.EmailAddressDetails) => (
    emailAddressDetails.emailAddress === asyncResult.asyncContext.email.emailAddress
  );
  if (!asyncResult.error && !asyncResult.value.find(finder)) {
    asyncResult.asyncContext.onComplete([asyncResult.asyncContext.email]);
  } else if (asyncResult.error) {
    // in the case where there was an async error trying to validate that
    // the address wasn't already there let's go ahead and add it just to be safe
    asyncResult.asyncContext.onComplete([asyncResult.asyncContext.email]);
  }
};

const getAsyncAppointmentAttendees = async (
  recipients: Office.Recipients,
): Promise<string[]> => (
  new Promise<string[]>((resolve, reject) => recipients.getAsync(
    (res: Office.AsyncResult<Office.EmailAddressDetails[]>) => {
      if (res.status === Office.AsyncResultStatus.Succeeded) {
        const addresses = res.value.map(
          (address: Office.EmailAddressDetails) => address.emailAddress,
        );
        return resolve(addresses);
      }
      return reject(Error(`An error occurred getting Appointment Attendees: ${res.error.message}`));
    },
  )));

const addAttachmentToEmail = async (
  file: { url: string; name: string; },
  callback: (asyncResult: Office.AsyncResult<string>) => void,
  isOffice: boolean,
  isReading: boolean,
) => {
  if (!isReading && Office?.context?.mailbox?.item) {
    fetch(file.url)
      .then((resp: any) => {
        if (!resp.ok) return Error(resp.error);
        return resp.blob();
      })
      .then((blob) => {
        const reader = new FileReader();
        reader.onload = () => {
          const b64 = reader.result?.toString() || '';
          const cleanedB64 = b64.replace(/^data:.+;base64,/, '');
          // eslint-disable-next-line no-unused-expressions
          Office?.context.mailbox.item.addFileAttachmentFromBase64Async(cleanedB64,
            file.name, { isInline: false }, callback);
        };
        reader.readAsDataURL(blob);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        // Office.AsyncResult requires these properties
        callback({
          value: error, status: Office.AsyncResultStatus.Failed, error, asyncContext: '', diagnostics: '',
        });
      });
  }
};

const addRecipientToEmail = (
  email: {
    emailAddress: string,
    displayName: string,
  },
  field: 'to' | 'cc' | 'bcc' | 'optionalAttendees' | 'requiredAttendees',
  isOffice: boolean,
  isReading: boolean,
) => {
  if (!isReading && Office?.context?.mailbox?.item) {
    Office.context.mailbox.item[field].getAsync(
      {
        asyncContext: {
          email,
          onComplete: Office.context.mailbox.item[field].addAsync,
        },
      },
      getAddressAsyncCallback,
    );
  }
};

const handleAttachmentsCallback = (
  ac: Office.AttachmentContent,
  ad: Office.AttachmentDetailsCompose,
  folderId: number,
) : IAttachment => {
  // Parse string to be a url, an .eml file, a base64-encoded string, or an .icalendar file.
  switch (ac.format) {
    case Office.MailboxEnums.AttachmentContentFormat.Base64:
      return {
        name: ad.name,
        content: ac.content,
        isBase64: true,
        folderId,
      };
    case Office.MailboxEnums.AttachmentContentFormat.Eml:
      return {
        name: `${ad.name}.eml`,
        content: ac.content,
        isBase64: false,
        folderId,
      };
    case Office.MailboxEnums.AttachmentContentFormat.ICalendar:
    case Office.MailboxEnums.AttachmentContentFormat.Url:
    default:
      return {
        name: ad.name,
        content: ac.content,
        isBase64: false,
      };
  }
};

const getAttachmentsContentFromRest = (
  headers: Headers,
  restId: string,
  attachmentId: string,
  attachmentName: string,
): Promise<IAttachment> => new Promise<IAttachment>((resolve, reject) => fetch(
  `${Office.context.mailbox.restUrl}/v2.0/me/messages/${restId}/attachments/${attachmentId}`,
  {
    headers,
    method: 'GET',
  },
).then((response) => {
  if (response.ok) {
    response.text().then(async (json: string) => {
      const parsed = JSON.parse(json);
      resolve({
        content: parsed.ContentBytes,
        isBase64: true,
        name: attachmentName,
        contentType: parsed.ContentType,
      });
    }).catch((error: Error) => reject(new Error(`Parsing the response failed: ${error.message}`)));
  } else {
    reject(new Error(`GET: ${restId}/attachments/${attachmentId} call failed with: ${response.body} : ${response.text()}`));
  }
})
  .catch((error: Error) => reject(new Error(`${error.message}`))));

const getRestAttachmentContent = (
  itemId: string,
  attachmentId: string,
  attachmentName: string,
): Promise<IAttachment> => (
  new Promise<IAttachment>((resolve, reject) => {
    Office.context.mailbox.getCallbackTokenAsync(
      { isRest: true },
      (result: Office.AsyncResult<string>) => {
        if (result.status === Office.AsyncResultStatus.Succeeded) {
          const token = `Bearer ${result.value}`;
          const headers = new Headers();
          headers.append('Authorization', token);
          const isMobile = getIsMobile();
          const restId = isMobile ? itemId
            : Office.context.mailbox.convertToRestId(
              itemId,
              Office.MailboxEnums.RestVersion.v2_0,
            );
          // GET https://outlook.office.com/api/v2.0/me/messages/{message_id}/attachments/{attachment_id}
          resolve(getAttachmentsContentFromRest(headers, restId, attachmentId, attachmentName));
        }
        reject(new Error('Unable to get authentication token from exchange'));
      },
    );
  })
);

// Gets the attachment content for each attachment locator in attachments array
const getEmailAttachments = async (
  currentItem: Office.ItemRead & Office.MessageRead & Office.AppointmentRead,
  attachments: IOfficeAttachment[],
) : Promise<IAttachment[]> => Promise.all(attachments.map(
  (
    attachment: IOfficeAttachment,
  ) => new Promise<IAttachment>(
    // getAttachmentContentAsync is available on both MessageCompose and MessageRead
    // https://docs.microsoft.com/en-us/javascript/api/outlook/office.messagecompose?view=outlook-js-preview#getAttachmentContentAsync_attachmentId__options__callback_
    (resolve, reject) => {
      const isMobile = getIsMobile();
      if (!isMobile) {
        currentItem.getAttachmentContentAsync(
          attachment.attachmentDetails.id,
          {},
          (asyncResult: Office.AsyncResult<Office.AttachmentContent>) => {
            if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
              resolve(handleAttachmentsCallback(
                asyncResult.value, attachment.attachmentDetails, attachment.folderId,
              ));
            } else {
              reject(new Error(`There's an error getting the attachment content: ${asyncResult.error.message}`));
            }
          },
        );
      } else {
        const { attachmentType } = attachment.attachmentDetails;
        const attachmentId = attachmentType === Office.MailboxEnums.AttachmentType.Cloud
          ? attachment.attachmentDetails.id
          : attachment.attachmentDetails.id.replace(/\//g, '-');
        getRestAttachmentContent(
          currentItem.itemId,
          attachmentId,
          attachment.attachmentDetails.name,
        ).then((r) => resolve(r))
          .catch((e) => reject(e));
      }
    },
  ),
));

// Gets the attachment content for each attachment locator in attachments array
const getEmailAttachmentsCompose = async (
  currentItem: Office.ItemCompose & Office.MessageCompose,
  attachments: IComposeAttachment[],
) : Promise<IAttachment[]> => Promise.all(attachments.map(
  (
    attachmentDetails: IComposeAttachment,
  ) => new Promise<IAttachment>(
    // getAttachmentContentAsync is available on both MessageCompose and MessageRead
    // https://docs.microsoft.com/en-us/javascript/api/outlook/office.messagecompose?view=outlook-js-preview#getAttachmentContentAsync_attachmentId__options__callback_
    (resolve, reject) => currentItem.getAttachmentContentAsync(
      attachmentDetails.attachment.id,
      {},
      (asyncResult: Office.AsyncResult<Office.AttachmentContent>) => {
        if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
          resolve(handleAttachmentsCallback(
            asyncResult.value, attachmentDetails.attachment, attachmentDetails.folderId,
          ));
        } else {
          reject(new Error(`There's an error getting the attachment content: ${asyncResult.error.message}`));
        }
      },
    ),
  ),
));

const getAsyncEmailAttachments = async (item: Office.MessageCompose): Promise<IAttachment[]> => (
  new Promise<IAttachment[]>((resolve, reject) => {
    item.getAttachmentsAsync(
      {},
      (asyncResult: Office.AsyncResult<Office.AttachmentDetailsCompose[]>) => {
        if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
          resolve(getEmailAttachmentsCompose(
            item, asyncResult.value.map((att) => ({ attachment: { ...att }, folderId: 0 })),
          ));
        } else {
          reject(new Error(`There's an error getting the email attachments: ${asyncResult.error.message}`));
        }
      },
    );
  }));

const getEmailMimeFromRestId = (
  emailSubject: string,
  restId: string,
  emlInformation: IEmlInformation,
): Promise<IAttachment> => (
  new Promise<IAttachment>((resolve, reject) => {
    Office.context.mailbox.getCallbackTokenAsync(
      { isRest: true },
      (result: Office.AsyncResult<string>) => {
        if (!result.error) {
          const headers = new Headers();
          headers.append('Authorization', `Bearer ${result.value}`);
          fetch(`${Office.context.mailbox.restUrl}/v2.0/me/messages/${restId}/$value`, {
            headers,
            method: 'GET',
          }).then(async (response: Response) => {
            if (response.ok) {
              response.text().then(async (mimeText: string) => {
                resolve({
                  content: btoa(mimeText),
                  isBase64: true,
                  name: emlInformation?.name || `${emailSubject}.eml`,
                  folderId: emlInformation?.folderId || 0,
                });
              }).catch((error: Error) => reject(new Error(`There was something wrong with the email ${error.message}`)));
            } else {
              reject(new Error(`The response from the server was not ok response status: ${response.status} ${response.statusText} ${await response.text()}`));
            }
          }).catch((error: Error) => reject(new Error(`There was something wrong with the response from the server ${error.message}`)));
        } else {
          reject(new Error('Unable to get authentication token from exchange'));
        }
      },
    );
  })
);

// eslint-disable-next-line max-len
const getAsyncEmailCategories = async (categories: Office.Categories): Promise<Office.CategoryDetails[]> => (
  new Promise<Office.CategoryDetails[]>((resolve, reject) => {
    categories.getAsync(
      (asyncResult: Office.AsyncResult<Office.CategoryDetails[]>) => {
        if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
          resolve(asyncResult.value || []);
        } else {
          reject(new Error(`There's an error getting the email categories: ${asyncResult.error.message}`));
        }
      },
    );
  })
);

const getAsyncEmailSubject = async (subject: Office.Subject): Promise<string> => (
  new Promise<string>((resolve, reject) => {
    subject.getAsync((asyncResult: Office.AsyncResult<string>) => {
      if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
        resolve(asyncResult.value);
      } else {
        reject(new Error(`There's an error getting the email subject: ${asyncResult.error.message}`));
      }
    });
  })
);

// eslint-disable-next-line max-len
const getAsyncEmailField = async (recipients: Office.Recipients): Promise<IOutlookItemEmailAddress[]> => (
  new Promise<IOutlookItemEmailAddress[]>((resolve, reject) => {
    recipients.getAsync(
      (asyncResult: Office.AsyncResult<Office.EmailAddressDetails[]>) => {
        if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
          if (asyncResult.value.length > 0) {
            resolve(asyncResult.value.map(({ displayName, emailAddress }) => ({
              EmailAddress: {
                Name: displayName,
                Address: emailAddress,
              },
            })));
          } else {
            resolve([]);
          }
        } else {
          reject(new Error(`There's an error getting recipients field: ${asyncResult.error.message}`));
        }
      },
    );
  }));

const getAsyncEmailBody = async (
  body: Office.Body,
  shouldSimplifyHtml?: boolean | false,
): Promise<string> => (
  new Promise<string>((resolve, reject) => {
    body.getAsync('html', { asyncContext: 'body' }, (asyncResult: Office.AsyncResult<string>) => {
      if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
        resolve(shouldSimplifyHtml ? simplifyHtml(asyncResult.value) : asyncResult.value);
      } else {
        reject(new Error(`There's an error getting the email body: ${asyncResult.error.message}`));
      }
    });
  }));

const getLocalItemPayload = async (m: Office.MessageCompose): Promise<IOutlookLocalItemPayload> => (
  new Promise<IOutlookLocalItemPayload>((resolve, reject) => {
    const asyncExecutor = async () => {
      try {
        const emailTo = await getAsyncEmailField(m.to);
        const emailCc = await getAsyncEmailField(m.cc);
        const emailBcc = await getAsyncEmailField(m.bcc);
        const emailSubject = await getAsyncEmailSubject(m.subject);
        const emailBody = await getAsyncEmailBody(m.body);
        const emailCategories = await getAsyncEmailCategories(m.categories);
        const emailAttachments = await getAsyncEmailAttachments(m);

        resolve({
          IsRead: true,
          ToRecipients: emailTo,
          CcRecipients: emailCc,
          BccRecipients: emailBcc,
          Subject: emailSubject,
          Body: {
            Content: emailBody,
            ContentType: 'HTML',
          },
          Categories: emailCategories.map(({ displayName }) => displayName),
          Attachments: emailAttachments.map((a: IAttachment) => ({
            '@odata.type': '#Microsoft.OutlookServices.FileAttachment',
            Name: a.name,
            ContentBytes: a.content,
          })),
        });
      } catch (error) {
        reject(new Error(`${error.message}`));
      }
    };
    asyncExecutor();
  }));

const formatMSEmailArray = (emails?: Office.EmailAddressDetails[]): IEmailContact[] | undefined => (
  emails && emails.map(({ displayName, emailAddress }: Office.EmailAddressDetails) => ({
    name: displayName,
    address: emailAddress,
  }))
);

// This is only called from the EmailActivity component which is only rendered in "read" mode
// We need to ensure that this function only calls methods availabile on the Outlook read mode
// https://docs.microsoft.com/en-us/javascript/api/outlook/office.attachmentdetails?view=outlook-js-preview
const getCurrentEmailPayload = async (
  partnerId: string,
  emlInformation: IEmlInformation,
  attachments?: IOfficeAttachment[],
): Promise<EmailPayload> => new Promise<EmailPayload>((resolve, reject) => {
  // Init required fields with safe values to fall back if needed
  const asyncExecutor = async () => {
    try {
      const emailPayload = new EmailPayload();
      const subject = Office.context.mailbox.item.subject || emailPayload.subject;
      const html = await getAsyncEmailBody(Office.context.mailbox.item.body, true);
      const from = formatMSEmailArray([Office.context.mailbox.item.from]);
      const isMobile = getIsMobile();
      let restId = Office.context.mailbox.item.itemId;
      if (!isMobile) {
        restId = Office.context.mailbox.convertToRestId
          && Office.context.mailbox.convertToRestId(
            Office.context.mailbox.item.itemId,
            Office.MailboxEnums.RestVersion.v2_0,
          );
      }
      if (!restId) {
        reject(new Error('Office.context.mailbox missing the restId conversion method'));
      }
      getEmailMimeFromRestId(subject, restId, emlInformation).then((emlAttachment: IAttachment) => {
        const payload = {
          ...emailPayload,
          subject: Office.context.mailbox.item.subject || emailPayload.subject,
          from: from && from.length > 0 ? from[0] : emailPayload.from,
          to: formatMSEmailArray(Office.context.mailbox.item.to) || emailPayload.to,
          cc: formatMSEmailArray(Office.context.mailbox.item.cc) || emailPayload.cc,
          html,
          headers: {
            ...emailPayload.headers,
            date: Office.context.mailbox.item.dateTimeCreated.toString()
              || emailPayload.headers.date,
          },
          emailId: {
            ...emailPayload.emailId,
            partner: partnerId,
          },
        };
        if (emlInformation.isAttached) {
          payload.attachments.push(emlAttachment);
        }
        if (attachments) {
          getEmailAttachments(
            Office.context.mailbox.item,
            attachments,
          ).then((downloadedAttachments: IAttachment[]) => {
            payload.attachments = [...downloadedAttachments, ...payload.attachments];
            resolve(payload);
          }).catch((error: Error) => (
            reject(new Error(`Get Email Attachments: ${error.message}`))
          ));
        } else {
          resolve(payload);
        }
      }).catch((error: Error) => reject(new Error(`Get Email Mime From RestId: ${error.message}`)));
    } catch (error) {
      reject(new Error(`Get Current Email Payload: ${error.message}`));
    }
  };
  asyncExecutor();
});

const getCurrentEmailId = async () => {
  const item = Office?.context?.mailbox?.item;
  // NOTE: convertToRestID() method is not available on mobile clients
  // .replace(/\//g, '-') is basically a polyfill to make the EWS ID a REST ID
  const isMobile = getIsMobile();
  const isMeeting = getIsMeeting();
  let currentEmailId = item?.itemId;

  // Meeting items sometimes do not have the itemId present in compose mode
  if (isMeeting && !currentEmailId) {
    await item?.getItemIdAsync((id: Office.AsyncResult<string>) => {
      if (id.status === Office.AsyncResultStatus.Succeeded) {
        currentEmailId = id.value;
      }
    });
  }

  if (isMobile) {
    currentEmailId = currentEmailId && mobileConvertToRestId(currentEmailId);
  }

  if (!isMobile) {
    if (item?.itemId && Office?.context?.mailbox?.convertToRestId) {
      currentEmailId = Office?.context?.mailbox?.convertToRestId(
          item?.itemId,
          Office.MailboxEnums.RestVersion.v2_0,
        ) || `NO-EMAIL-ID-YET-${new Date().getTime()}`;
    }
    if (!currentEmailId && !item?.itemId) {
      currentEmailId = `NO-EMAIL-ID-YET-${new Date().getTime()}`;
    }
  }

  return currentEmailId;
};

export {
  addAttachmentToEmail,
  addRecipientToEmail,
  getIsComposing,
  getIsDesktop,
  getIsMobile,
  getIsMeetingClass,
  getIsMeeting,
  getIsOfficeJs,
  getIsOfficeApp,
  getIsOutlook,
  getIsReading,
  getIsWeb,
  getEmailAttachments,
  getEmailAttachmentsCompose,
  getEmailMimeFromRestId,
  getCurrentEmailPayload,
  getCurrentEmailId,
  getLocalItemPayload,
  mobileConvertToRestId,
  getAsyncAppointmentAttendees,
};
