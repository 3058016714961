import { useCallback } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client/errors/ApolloError';
import gql from 'graphql-tag';

interface IProjectClient {
  personId: {
    native: number;
  };
  firstName: string;
  lastName: string;
  fullName: string;
  primaryEmail: string;
  primaryEmailLabel: string;
  pictureUrl: string;
  emails: [
    {
      emailId: {
        native: number;
      };
      address: string;
      label: string;
    }
  ];
}

interface IProjectClientResponse {
  client: IProjectClient;
}

interface IProjectClientPayload {
  called: boolean;
  loading: boolean;
  error?: ApolloError;
  client?: IProjectClient;
}

export interface IQueryProjectClientHook {
  projectClientSearchResults: IProjectClientPayload;
  getProjectClient: (projectId: number) => void;
}

export const PROJECTS_QUERY = gql`
  query projectClientQuery {
    client(id: $id)
      @rest(type: "Client", path: "/projects/{args.id}/client") {
        personId
        firstName
        lastName
        fullName
        primaryEmail
        primaryEmailLabel
        pictureUrl
        emails
    }
  }`;

const useQueryProjectClient = (): IQueryProjectClientHook => {
  const [executeQuery, {
    data, error, loading, called,
  }] = useLazyQuery<IProjectClientResponse>(
    PROJECTS_QUERY, { notifyOnNetworkStatusChange: true },
  );

  const getProjectClient = useCallback(
    (id: number) => executeQuery({ variables: { id } }), [executeQuery],
  );

  return {
    projectClientSearchResults: {
      called,
      error,
      loading,
      client: data && data.client,
    },
    getProjectClient,
  };
};

export default useQueryProjectClient;
