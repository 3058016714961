import React, { FC } from 'react';
import Button from '@fv-components/button';

import {
  addRecipientToEmail, getIsOutlook, getIsReading, getIsMeeting,
} from '../../../util-helpers/office';

interface IEmailActionButtonsProps {
  emailAddress: string;
  displayName: string;
}

const EmailActionButtons: FC<IEmailActionButtonsProps> = ({
  emailAddress, displayName,
}: IEmailActionButtonsProps) => {
  const isOutlook = getIsOutlook();
  const isReading = getIsReading();
  const isMeeting = getIsMeeting();

  const meetingButtons = (
    <>
      <Button
        raised
        dense
        className="email-actions"
        onClick={() => addRecipientToEmail({ emailAddress, displayName }, 'requiredAttendees', isOutlook, isReading)}
      >
        Required
      </Button>
      <Button
        raised
        dense
        className="email-actions"
        onClick={() => addRecipientToEmail({ emailAddress, displayName }, 'optionalAttendees', isOutlook, isReading)}
      >
        Optional
      </Button>
    </>
  );

  const emailButtons = (
    <>
      <Button
        raised
        dense
        className="email-actions"
        onClick={() => addRecipientToEmail({ emailAddress, displayName }, 'to', isOutlook, isReading)}
      >
        To
      </Button>
      <Button
        raised
        dense
        className="email-actions"
        onClick={() => addRecipientToEmail({ emailAddress, displayName }, 'cc', isOutlook, isReading)}
      >
        CC
      </Button>
      <Button
        raised
        dense
        className="email-actions"
        onClick={() => addRecipientToEmail({ emailAddress, displayName }, 'bcc', isOutlook, isReading)}
      >
        BCC
      </Button>
    </>
  );

  // Actions can only be in composing
  if (!isReading) {
    if (!isMeeting) {
      return emailButtons;
    }
    return meetingButtons;
  }
  return <></>;
};

export default EmailActionButtons;
