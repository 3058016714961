import { useLazyQuery } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client/errors/ApolloError';
import gql from 'graphql-tag';
import { useCallback } from 'react';

interface IDocumentLocator {
  contentType: string;
  documentId: {
    native: number;
    partner: number;
  };
  url: string;
}

interface IDocumentLocatorResponse {
  documentLocator: IDocumentLocator;
}

export interface IDocumentLocatorPayload {
  called: boolean;
  loading: boolean;
  error?: ApolloError;
  documentLocator?: IDocumentLocator;
  getDocumentLocator: (id: number | string) => void;
}

export const DOCUMENT_LOCATOR_QUERY = gql`
  query documentLocatorQuery ($id: Number!) {
    documentLocator(id: $id)
      @rest(type: "documentLocator", path: "/documents/{args.id}/locator") {
        contentType
        documentId
        url
      }
  }`;

const useQueryDocumentLocator = (): IDocumentLocatorPayload => {
  const [executeQuery, {
    data, error, loading, called,
  }] = useLazyQuery<IDocumentLocatorResponse>(
    DOCUMENT_LOCATOR_QUERY, { notifyOnNetworkStatusChange: true, fetchPolicy: 'cache-and-network' },
  );

  const getDocumentLocator = useCallback(
    (id: number | string) => executeQuery({ variables: { id } }), [executeQuery],
  );

  return {
    called,
    error,
    loading,
    // If a 2nd call was made that errors (note not found) we don't want to return the old data
    documentLocator: !error && data ? data.documentLocator : undefined,
    getDocumentLocator,
  };
};

export default useQueryDocumentLocator;
