import React, { FC, useState, useEffect } from 'react';

import Button from '@fv-components/button';
import LinearProgress from '@fv-components/linear-progress/';
import Select, { Option } from '@fv-components/select';
import TextField, { Input, HelperText, CharacterCounter } from '@fv-components/text-field';

import useQueryBillingCodes, { IBillingCodes } from '../../../util-api/useQueryBillingCodes';
import useMutateBillingEntry from '../../../util-api/useMutateBillingEntry';
import { countDecimals, truncateText } from '../../../util-helpers/common';
import { getUserId } from '../../../Auth/auth';

import ErrorMsg from '../../../ErrorMsg';
import FilevineIcon from '../../FilevineIcon';

const css = require('./BillingTimer.module.scss');

interface ITimeCode {
  fieldSelector: string;
  fieldName: string;
  value: string;
}

interface IBillingFormData {
  projectId: number;
  userId: string;
  description?: string;
  time?: string;
  timeCodes: {
    [key: string]: ITimeCode;
  }
}

interface IBillingTimerProps {
  projectId: number;
}

const BillingTimer: FC<IBillingTimerProps> = ({ projectId }: IBillingTimerProps) => {
  const userId = getUserId();
  const [billingEntry, setBillingEntry] = useState<IBillingFormData>({
    projectId,
    userId,
    timeCodes: {},
  });

  // Get billing codes
  const {
    getBillingCodes,
    called: codesCalled,
    loading: codesLoading,
    error: codesError,
    billingCodes,
  } = useQueryBillingCodes();
  useEffect(() => {
    getBillingCodes();
  }, [getBillingCodes]);

  const [timeCodes, setTimeCodes] = useState<IBillingCodes[]>();
  useEffect(() => {
    if (codesCalled && billingCodes) {
      setTimeCodes(billingCodes?.filter((c) => c.assignedToBillingTypes.includes('Time')));
    }
  }, [billingCodes, codesCalled]);

  const {
    addBillingEntry,
    called: entryCalled,
    loading: entryLoading,
    error: entryError,
    billingEntryRes,
  } = useMutateBillingEntry();

  const handleSave = (e: React.FormEvent<HTMLFormElement>): void => {
    if (
      e.currentTarget.checkValidity()
      && billingEntry.description
      && billingEntry.time
    ) {
      addBillingEntry({
        ...billingEntry,
        description: billingEntry.description,
        time: billingEntry.time,
      });
    }
  };

  if (codesLoading || entryLoading) {
    return <LinearProgress indeterminate />;
  }

  if (codesError || entryError) {
    return <ErrorMsg errorText={codesError?.message || entryError?.message} />;
  }

  if (entryCalled && billingEntryRes) {
    return (
      <div className={css.success}>
        <FilevineIcon icon="check-circle-o" />
        {' '}
        Billing entry added!
      </div>
    );
  }

  const maxDecimals = 2;
  const hoursMin = 0.01;
  const hoursMax = 1000000;
  const descMax = 2000;

  return (
    <div className={css.container}>
      <form onSubmit={(event: React.FormEvent<HTMLFormElement>) => handleSave(event)}>
        <TextField
          outlined
          className={css.input}
          label="Hours"
          helperText={(
            <HelperText validation>
              {`${hoursMin} - ${hoursMax}`}
            </HelperText>
          )}
        >
          <Input
            required
            type="number"
            max={hoursMax}
            min={hoursMin}
            step={hoursMin}
            value={billingEntry?.time}
            onChange={(event: React.FormEvent<HTMLInputElement>) => {
              setBillingEntry((c: IBillingFormData) => ({
                ...c,
                time: event.currentTarget.value,
              }));
            }}
          />
        </TextField>

        {!!timeCodes?.length && timeCodes.map((tc) => (
          <div key={tc.fieldSelectorName}>
            {!!tc?.codes?.length
              && (
              <Select
                outlined
                className={css.select}
                label={tc.name}
                value={billingEntry.timeCodes[tc.fieldSelectorName]?.value}
                onChange={(event: React.FormEvent<HTMLSelectElement>) => {
                  setBillingEntry((c: IBillingFormData) => ({
                    ...c,
                    timeCodes: {
                      ...c.timeCodes,
                      [tc.fieldSelectorName]: {
                        fieldSelector: tc.fieldSelectorName,
                        fieldName: tc.name,
                        value: event.currentTarget.value,
                      },
                    },
                  }));
                }}
              >
                <Option key="NONE" value="" />
                <>
                  {tc?.codes?.map((c) => (
                    <Option key={c.code} value={c.code}>
                      {`${c.code} | ${truncateText(c.description, 28)}`}
                    </Option>
                  ))}
                </>
              </Select>
              )}
          </div>
        ))}

        <TextField
          outlined
          className={css.input}
          label="Description"
          textarea
        >
          <Input
            required
            inputType="textarea"
            rows={3}
            maxLength={descMax}
            value={billingEntry?.description}
            onChange={(event: React.FormEvent<HTMLInputElement>) => {
              setBillingEntry((c) => ({
                ...c,
                description: event.currentTarget.value,
              }));
            }}
          />
        </TextField>
        <CharacterCounter
          className={css.counter}
          maxLength={descMax}
          count={billingEntry.description?.length}
        />

        <Button
          raised
          type="submit"
          disabled={!billingEntry?.description
            || !billingEntry.time
            || countDecimals(Number(billingEntry.time)) > maxDecimals
            || Number(billingEntry.time) < hoursMin
            || Number(billingEntry.time) > hoursMax}
        >
          Save
        </Button>
      </form>
    </div>
  );
};

export default BillingTimer;
