import { useLazyQuery } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client/errors/ApolloError';
import gql from 'graphql-tag';
import { useCallback } from 'react';

export interface INote {
  noteId: {
    native: number;
    partner: number;
  };
  assigneeId: {
    native : number;
  };
  targetDate: string;
  commentCount: number;
  isCompleted: boolean;
  completedDate?: string;
}

interface INoteResponse {
  note: INote;
}

export interface INotePayload {
  noteCalled?: boolean;
  noteLoading: boolean;
  noteErr?: ApolloError;
  note?: INote;
  getNote: (noteId: number | string) => void;
}

export const NOTE_QUERY = gql`
  query noteQuery($noteId: Number!) {
    note(noteId: $noteId)
      @rest(type: "Note", path: "/notes/{args.noteId}") {
        noteId
        targetDate
        isCompleted
        completedDate
        body
        createdAt
        source
        lastActivity
        authorId
        assigneeId
        completerId
        projectId
        comments
        commentCount
    }
  }`;

const useQueryNote = (): INotePayload => {
  const [executeQuery, {
    data,
    error,
    loading,
    called,
  }] = useLazyQuery<INoteResponse>(
    NOTE_QUERY, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network',
    },
  );

  const getNote = useCallback(
    (noteId: number | string) => executeQuery({ variables: { noteId } }), [executeQuery],
  );

  return {
    noteCalled: called,
    noteErr: error,
    noteLoading: loading,
    // If a 2nd call was made that errors (note not found) we don't want to return the old data
    note: !error && data ? data.note : undefined,
    getNote,
  };
};

export default useQueryNote;
