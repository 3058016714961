import { useEffect, useCallback, useState } from 'react';

interface IScrollState {
  isTopVisible: boolean;
  isBottomVisible: boolean;
}

interface IScrollHook {
  isTopVisible: boolean;
  isBottomVisible: boolean;
}

const useScroll = (
  scrollContainer?: React.RefObject<HTMLElement> | null,
  topElement?: React.RefObject<HTMLElement> | null,
  bottomElement?: React.RefObject<HTMLElement> | null,
  topPixelBuffer: number = 0,
  bottomPixelBuffer: number = 0,
) : IScrollHook => {
  const [state, setState] = useState<IScrollState>({
    isTopVisible: false,
    isBottomVisible: false,
  });

  const trackScrolling = useCallback(() => {
    if (scrollContainer && scrollContainer.current) {
      const { top: scrollContainerTop, bottom: scrollContainerBottom } = scrollContainer
        .current.getBoundingClientRect();
      let isTopVisible = false;
      let isBottomVisible = false;

      if (topElement && topElement.current) {
        const { top } = topElement.current.getBoundingClientRect();
        isTopVisible = top >= (scrollContainerTop - topPixelBuffer);
      }

      if (bottomElement && bottomElement.current) {
        const { bottom } = bottomElement.current.getBoundingClientRect();
        isBottomVisible = bottom <= (scrollContainerBottom + bottomPixelBuffer);
      }
      setState({
        isTopVisible,
        isBottomVisible,
      });
    }
  }, [scrollContainer, topElement, bottomElement, topPixelBuffer, bottomPixelBuffer]);

  useEffect(() => {
    if (scrollContainer && scrollContainer.current) {
      const container = scrollContainer.current;
      container.addEventListener('scroll', trackScrolling);
      return () => {
        container.removeEventListener('scroll', trackScrolling);
      };
    }
    return undefined;
  }, [scrollContainer, trackScrolling]);

  return {
    isTopVisible: state.isTopVisible,
    isBottomVisible: state.isBottomVisible,
  };
};

export default useScroll;
