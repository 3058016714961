import React, { FC } from 'react';
import { ChipSet, Chip } from '@fv-components/chips';
import Persona from '../../Persona';

const css = require('./TeamMember.module.scss');

interface ITeamMemberProps {
  username: string;
  userId: { native: number; };
  fullname: string;
  isAdmin: boolean;
  isPrimary: boolean;
}

const TeamMember: FC<ITeamMemberProps> = ({
  username, userId, fullname, isAdmin, isPrimary,
}) => (
  <>
    <div className={css.teamPersonaContainer}>
      <div key={userId.native} className={css.teamPersona}>
        <Persona
          small
          name={fullname}
          secondLine={`@${username}`}
        />
      </div>
      <ChipSet className={isAdmin && isPrimary ? css.teamAdvancedLevels : css.teamAdvancedLevel}>
        {isPrimary ? <Chip id="Primary" label="Primary" className={css.teamAdvancedChip} /> : ''}
        {isAdmin ? <Chip id="Admin" label="Admin" className={css.teamAdvancedChip} /> : ''}
      </ChipSet>
    </div>
    <hr className={css.teamSeparator} />
  </>
);

export default TeamMember;
