import { useRef } from 'react';
import { getIsProdEnv, getIsTestEnv } from '../util-helpers/common';

const useCountRenders = (title = '') => {
  const isProd = getIsProdEnv();
  const isTest = getIsTestEnv();

  const renders = useRef(0);
  if (!isProd && !isTest) {
    // eslint-disable-next-line no-console
    console.log(`${title} renders: `, renders.current += 1);
  }
};

export default useCountRenders;
