import React, {
  FC, Fragment, useEffect, useState,
} from 'react';

import IContact from '../../../util-api/models/contact';
import useQueryProjectContacts from '../../../util-api/useQueryProjectContacts';
import { getIsComposing, getIsMeeting } from '../../../util-helpers/office';
import useDebounce from '../../../util-hooks/useDebounce';
import useInput from '../../../util-hooks/useInput';

import SearchLimitWarning from '../../SearchLimitWarning';
import ContactCard from '../ContactCard';
import EmailLink from '../EmailLink';
import Persona from '../Persona';
import SecondarySearchBar from '../SecondarySearchBar';

const css = require('./Contacts.module.scss');

interface IContactsProps {
  projectId?: number;
  projectEmail?: string;
}

const Contacts: FC<IContactsProps> = ({ projectId, projectEmail }: IContactsProps) => {
  // Search and fetch
  const [values, handleValueChange] = useInput({ contactSearchTerm: '' });
  const searchPlaceholderText = 'Search contacts by name, email, or role.';
  const isComposing = getIsComposing();
  const isMeeting = getIsMeeting();

  const {
    getProjectContacts,
    projectContactsSearchResults: {
      contacts, error, loading, called,
    },
  } = useQueryProjectContacts();
  const debouncedContactSearchTerm = useDebounce(values.contactSearchTerm, 300).trimStart();

  useEffect(() => {
    if (projectId) {
      const reqParams = {
        projectId,
        searchTerm: debouncedContactSearchTerm,
        limit: 1000,
      };
      getProjectContacts(
        reqParams.projectId,
        reqParams.searchTerm,
        reqParams.limit,
      );
    }
  }, [projectId, debouncedContactSearchTerm, getProjectContacts]);

  const [expandedContactId, setExpandedContactId] = useState(0);

  return (
    <div data-test="contacts-container">
      <div className={css.contactsHeader}>
        {projectEmail && (
          <div className={css.projectEmail}>
            <EmailLink
              displayName={projectEmail}
              emailAddress={projectEmail}
              emailField={!isMeeting ? 'cc' : 'optionalAttendees'}
            />
          </div>
        )}

        <SecondarySearchBar
          searchInputName="contactSearchTerm"
          searchPlaceholderText={searchPlaceholderText}
          searchTerm={values.contactSearchTerm}
          handleSearchTermChange={handleValueChange}
          loading={loading}
        />
      </div>
      {contacts && contacts.hasMore && (
      <SearchLimitWarning limit={contacts.limit} />
      )}

      {((contacts && contacts.count > 0) && !error) ? contacts.items.map(({
        orgContact: {
          emails, fullName, pictureUrl, primaryEmail, primaryEmailLabel,
        },
        projectContactId,
        orgContactId,
        role,
      }: IContact) => {
        const contactId = projectContactId?.native || orgContactId.native;
        return (
          <Fragment key={contactId}>
            <ContactCard
              emails={emails}
              fullName={fullName}
              contactId={contactId}
              pictureUrl={pictureUrl}
              primaryEmail={primaryEmail}
              primaryEmailLabel={primaryEmailLabel}
              userRole={role}
              isExpanded={expandedContactId === contactId}
              setExpandedContactId={setExpandedContactId}
              isComposing={isComposing}
              isMeeting={isMeeting}
            />
            <hr className={css.contactsSeparator} />
          </Fragment>
        );
      })
        : (called && !loading && <div className={css.contactsNoResults}>No contacts found</div>)}
      {!contacts && loading && <div className={css.contactsLoading}><Persona loading small /></div>}
    </div>
  );
};

export default Contacts;
