import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Button from '@fv-components/button';

import { getUserTenant } from './config';
import * as serviceWorker from './serviceWorker';

// Utilities
import {
  configHasError,
  getIsDebug,
  getIsProdEnv,
} from './util-helpers/common';
import {
  getCurrentEmailId,
} from './util-helpers/office';
import { pendoInitialize } from './util-helpers/pendo';

// Child Components
import {
  updateUserFromSession,
  validateUserAuthentication,
  logout,
  handleAmplifyConfigure,
  setSAMLAuth,
} from './Auth/auth';
import Landing from './Auth/Landing';
import Login from './Auth/Login';
import Success from './Auth/Success';
import FullPageError from './FullPageError';
import Taskpane from './Taskpane';

let isAppInitialized = false;

let hasConfigError: boolean | undefined;
let userLoginError: string | undefined;
let validatedUser = false;
let userUpdated = false;

const isProd = getIsProdEnv();
const isDebug = getIsDebug();

const render = async () => {
  const currentEmailId = await getCurrentEmailId();

  // users can get in a bad state, end up on the landing page inside the dialog and then are stuck
  // messageParent only exists on the child / dialog. this will close it in this case
  if (Office?.context?.ui?.messageParent && window.location.pathname === '/landing') {
    Office.context.ui.messageParent('');
  }

  if (getUserTenant() && !userUpdated) {
    hasConfigError = configHasError();
    if (!hasConfigError) {
      // amplify should only be configured with SAML props if on the success page
      // reset here if the user is in a partial state
      if (window.location.pathname !== '/success') {
        setSAMLAuth('');
      }
      await handleAmplifyConfigure();
      validatedUser = await validateUserAuthentication();

      // Once the user is logged in (validatedUser)
      // We want to check this more than just at login but not every render (userUpdated)
      if (validatedUser && !userUpdated) {
        try {
          await updateUserFromSession();
          await pendoInitialize();
          userUpdated = true;
        } catch (error) {
          userLoginError = error.message;
        }
      }
    }
  }

  ReactDOM.render(
    <Router>
      <Switch>
        {hasConfigError && (
          <Route>
            <FullPageError errorMessage="There was an error with configurations." />
          </Route>
        )}
        {userLoginError && (
          <Route>
            <FullPageError errorMessage={userLoginError} />
            <Button
              onClick={logout}
            >
              Sign Out
            </Button>
          </Route>
        )}
        <Route path="/login"><Login /></Route>
        <Route path="/success"><Success /></Route>
        <Route>
          {!validatedUser ? <Landing />
            : (
              <Taskpane
                isAppInitialized={isAppInitialized}
                currentEmailId={currentEmailId}
              />
            )}
        </Route>
      </Switch>
    </Router>,
    document.getElementById('container'),
  );
};

if (Office) {
  Office.initialize = () => {
    isAppInitialized = true;

    // Render again application after Office initializes
    render();
  };
} else {
  // eslint-disable-next-line no-console
  console.error('No `Office` global found.');
}

// Force to true for dev testing in browser
// Or allow testing in other envs
if (!isProd || isDebug) {
  isAppInitialized = true;
}

render();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
