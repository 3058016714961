import React, { useContext, FC } from 'react';

import { IProject } from '../../util-api/models/project';
import useCountRenders from '../../util-hooks/useCountRenders';
import ProjectContext from '../ProjectContext';
import ErrorMsg from '../../ErrorMsg';

const css = require('./ProjectSearchResults.module.scss');

const ProjectSearchResults: FC = () => {
  useCountRenders('ProjectSearchResults');

  const { setCurrentProject, projectSearchResults } = useContext(ProjectContext);

  return (
    <>
      {!projectSearchResults.error
        ? (
          <div>
            {projectSearchResults.items.map(
              (item: IProject) => (
                <div
                  className={css.searchResultsProjectCard}
                  data-test="project-card"
                  key={item.uniqueKey}
                  role="button"
                  tabIndex={0}
                  onClick={() => { setCurrentProject({ data: item, id: item.projectId.native }); }}
                  onKeyDown={(e: React.KeyboardEvent<HTMLSpanElement>) => {
                    if (e.key === 'Enter') {
                      setCurrentProject({ data: item, id: item.projectId.native });
                    }
                  }}
                >
                  <div
                    className={css.searchResultsProjectTitle}
                    data-test="project-title"
                  >
                    {item.projectOrClientName}
                  </div>
                  {`${item.firstPrimaryName} (@${item.firstPrimaryUsername}) •\xa0${item.phaseName} •\xa0${item.projectTypeCode}`}
                  {item.hashtags.length ? ` •\xa0${item.hashtags.join(' ')} ` : ''}
                </div>
              ),
            )}
          </div>
        )
        : (
          <div className={css.searchResultsError}>
            <ErrorMsg errorText="There was an error with your request." />
          </div>
        )}
    </>
  );
};

export default ProjectSearchResults;
