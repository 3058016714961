import React, { FC, useContext, useEffect } from 'react';
import useQueryFolderList, { IFolder } from '../../util-api/useQueryFolderList';
import FilevineIcon from '../FilevineIcon';
import ProjectContext from '../ProjectContext';

const css = require('./FolderPicker.module.scss');

export interface IFolderPicker {
  currentlySelectedFolder: IFolder | undefined;
  filename: string;
  setSelectedCallback: (folder: IFolder | undefined) => void;
  clearSelectedCallback: () => void;
  setParentFolder: (parentFolder: IFolder) => void;
  getParentFolder: (parentKey: number) => IFolder;
}

const FolderPicker: FC<IFolderPicker> = (
  {
    currentlySelectedFolder,
    filename,
    setSelectedCallback,
    clearSelectedCallback,
    setParentFolder,
    getParentFolder,
  }: IFolderPicker,
) => {
  const { currentProjectId, currentProjectData } = useContext(ProjectContext);

  const {
    getFolderList,
    folderListResult: {
      folderList,
      loading: foldersLoading,
    },
  } = useQueryFolderList();

  useEffect(() => {
    getFolderList(currentlySelectedFolder?.links.children, currentProjectId);
  }, [currentProjectId, getFolderList, currentlySelectedFolder]);

  const handleChildFolderClicked = (folder: IFolder) => {
    setParentFolder(currentlySelectedFolder!);
    setSelectedCallback(folder);
  };

  const handleClearSelectedFolder = () => {
    clearSelectedCallback();
  };

  const handleProjectClick = () => {
    clearSelectedCallback();
  };

  const handleParentFolderClicked = () => {
    setSelectedCallback(getParentFolder(currentlySelectedFolder?.parentId?.native || 0));
  };

  const getParentName = () => getParentFolder(
    currentlySelectedFolder?.parentId?.native || 0,
  ).name;

  return (
    <>
      {/* FOLDER BREADCRUMB SECTION */}
      {currentlySelectedFolder
        ? (
          <div className={css.folderBreadcrumbSectionHighlighted}>
            <div className={css.breadCrumbs}>
              {/* Project Root */}
              <span
                role="button"
                tabIndex={0}
                onClick={handleProjectClick}
                onKeyDown={handleProjectClick}
                className={`${css.projectNameHighlighted} ${css.folderLink}`}
                data-test="projectName"
              >
                {currentProjectData
                && currentProjectData?.projectOrClientName?.length > 10
                  ? `${currentProjectData?.projectOrClientName.slice(0, 10).trimEnd()}...`
                  : currentProjectData?.projectOrClientName}
                <FilevineIcon icon="chevron-right" className={css.icon} />
              </span>
              {/* Parent folder */}
              {currentlySelectedFolder.parentId?.native
                !== currentProjectData?.rootDocFolderId?.native
                && (
                <>
                  <span
                    role="button"
                    tabIndex={0}
                    onClick={handleParentFolderClicked}
                    onKeyDown={handleParentFolderClicked}
                    className={`${css.parentNameHighlighted} ${css.folderLink}`}
                  >
                    {getParentName().length > 10 ? `${getParentName().slice(0, 10).trimEnd()}...` : getParentName() || 'Parent'}
                    <FilevineIcon icon="chevron-right" className={css.icon} />
                  </span>
                </>
              )}
              {/* Current Folder */}
              {currentlySelectedFolder.name}
            </div>
            <div
              role="button"
              onClick={handleClearSelectedFolder}
              onKeyDown={handleClearSelectedFolder}
              tabIndex={0}
              data-test="clearFolderButton"
            >
              <FilevineIcon className={css.unattachIcon} icon="times" />
            </div>
          </div>
        )
        : (
          <div className={css.folderBreadcrumbSection} data-test="projectName">
            {currentProjectData?.projectOrClientName}
          </div>
        )}
      {/* SHOW CURRENTLY SELECTED FOLDER AND DIRECTORY */}
      <div className={css.docContainer}>
        {currentlySelectedFolder && (
          <div className={css.folderPlaceholder} data-test="folderPlaceholder">
            <FilevineIcon icon="folder" className={css.icon} />
            {currentlySelectedFolder?.name}
          </div>
        )}

        <div className={css.docPlaceholder} data-test="filenamePlaceholder">
          <FilevineIcon icon="paperclip" className={css.icon} />
          {filename}
        </div>
      </div>

      {/* FOLDER LIST */}
      <div className={css.container}>
        {folderList?.items?.map((folder: IFolder) => (
          <div className={css.folderContainer} key={folder.folderId.native}>
            <div
              key={`${folder.folderId.native}`}
              className={css.folderItem}
              role="button"
              tabIndex={0}
              data-test="folderItem"
              title={folder.name.length > 26 ? folder.name : ''}
              onClick={() => handleChildFolderClicked(folder)}
              onKeyDown={(e: React.KeyboardEvent<HTMLSpanElement>) => {
                if (e.key === 'Enter') {
                  handleChildFolderClicked(folder);
                }
              }}
            >
              <span>
                <FilevineIcon icon="folder" className={css.folderIcon} />
                {folder.name}
              </span>
            </div>
          </div>
        ))}

        {/* If loading */}
        {foldersLoading && <div>Loading... </div>}

        {/* IF NO SUBFOLDERS */}
        {!foldersLoading && (!folderList || folderList.count === 0) && <div>No sub folders</div>}
      </div>
    </>
  );
};

export default FolderPicker;
