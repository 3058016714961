// This util file is a wrapper around common New Relic Browser SPA events
// https://docs.newrelic.com/docs/browser/browser-monitoring/browser-agent-spa-api
// NOTE: https://discuss.newrelic.com/t/typescript-for-browser-agent/35942/13
// eslint-disable-next-line import/no-unresolved
import * as NewRelicBrowser from 'new-relic-browser';

declare global {
  interface Window {
    // eslint-disable-next-line no-undef
    newrelic: typeof NewRelicBrowser;
  }
}

const { newrelic } = window;

const nrPageAction = (name: string, attr: { [key: string]: string | number }) => {
  if (newrelic) {
    newrelic.addPageAction(name, attr);
  }
};

const nrCustomAttribute = (name: string, value: string | number) => {
  if (newrelic) {
    newrelic.setCustomAttribute(name, value);
  }
};

const nrInteraction = () => {
  if (newrelic) {
    newrelic.interaction();
  }
};

const nrInteractionTrace = (name: string, cb: (VoidFunction) | undefined) => {
  if (newrelic) {
    newrelic.interaction().createTracer(name, cb);
  }
};

export {
  nrPageAction,
  nrCustomAttribute,
  nrInteraction,
  nrInteractionTrace,
};
