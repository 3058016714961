import React, { FC } from 'react';
import TabBar, { Tab } from '@fv-components/tab-bar';
import FilevineIcon from '../FilevineIcon';

const css = require('./ProjectWorkspaceNavigation.module.scss');

interface IProjectWorkspaceNavigationProps {
  activeTabIdx: number;
  handleActiveTabUpdate: (idx: number) => void;
}

const ProjectWorkspaceNavigation: FC<IProjectWorkspaceNavigationProps> = ({
  activeTabIdx,
  handleActiveTabUpdate,
}) => (
  <>
    <TabBar
      className={css.projectWorkspaceTabBar}
      activeIndex={activeTabIdx}
      handleActiveIndexUpdate={handleActiveTabUpdate}
    >
      <Tab
        stacked
        tabIndex={0}
        className={css.projectWorkspaceTab}
        data-test="activity-tab"
      >
        <FilevineIcon
          className={`mdc-tab__icon ${css.projectWorkspaceTabIcon}`}
          icon="comments"
        />
        <span className={`mdc-tab__text-label ${css.projectWorkspaceTabLabel}`}>
          Activity
        </span>
      </Tab>
      <Tab
        stacked
        tabIndex={0}
        className={css.projectWorkspaceTab}
        data-test="contacts-tab"
      >
        <FilevineIcon
          className={`mdc-tab__icon ${css.projectWorkspaceTabIcon}`}
          icon="user"
        />
        <span className={`mdc-tab__text-label ${css.projectWorkspaceTabLabel}`}>
          Contacts
        </span>
      </Tab>
      <Tab
        stacked
        tabIndex={0}
        className={css.projectWorkspaceTab}
        data-test="team-tab"
      >
        <FilevineIcon
          className={`mdc-tab__icon ${css.projectWorkspaceTabIcon}`}
          icon="group"
        />
        <span className={`mdc-tab__text-label ${css.projectWorkspaceTabLabel}`}>
          Team
        </span>
      </Tab>
    </TabBar>
  </>
);

export default ProjectWorkspaceNavigation;
