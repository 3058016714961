import { getIsTestEnv } from './util-helpers/common';

export interface IConfig {
  filevineApiBaseUrl: string;
  filevineBaseDomain: string;
  filevineBaseUrl: string;
  filevineTenantUrl: string;
  hostUrl: string;
  ephemeralId: string;
}

interface IIdentityProviders {
  name: string;
}

interface IAppClient {
  appClientID: string;
  clientType: string;
  clientTypeValue: number;
  identityProviders: IIdentityProviders;
}

export interface ITenantConfig {
  appClientID?: string;
  authorizationEndpoint?: string;
  awsRegion?: string;
  cognitoUserPoolID?: string;
  tenantID?: string;
  identityProviders?: IIdentityProviders[];
  error?: string;
}

interface IShardGroup { key: string, name: string }

export const shardGroups: { [key: string]: IShardGroup[] } = {
  prod: [{ key: 'app', name: 'app.filevine.com (United States)' }, { key: 'app-ca', name: 'app.filevine.ca (Canada)' }],
  preprod: [{ key: 'fru', name: 'Free Range Unicorns' }, { key: 'jacksonville', name: 'Jacksonville' }],
};

export const envShards = shardGroups[process.env.REACT_APP_GROUP || ''];

export const testTenantConfig = {
  appClientID: process.env.REACT_APP_COGNITO_CLIENT_ID || '',
  authorizationEndpoint: '',
  awsRegion: 'ca-42',
  cognitoUserPoolID: process.env.REACT_APP_COGNITO_USER_POOL_ID || '',
  tenantID: 'asdf1234',
  identityProviders: [],
};

export const setUserTenant = (userTenant: string): void => {
  localStorage.setItem('userTenant', userTenant);
};

export const getUserTenant = (): string => (
  localStorage.getItem('userTenant') || ''
);

export const getConfig = (): IConfig => {
  const tenant = getIsTestEnv() ? 'fru' : getUserTenant();
  const group = process.env.REACT_APP_GROUP;
  let filevineBaseDomain = group === 'prod' ? '.filevineapp.com' : '.filevinedev.com';
  let filevineTenantUrl = `${tenant}${filevineBaseDomain}`;

  if (tenant === 'app') {
    filevineBaseDomain = '.filevine.com';
    filevineTenantUrl = `app${filevineBaseDomain}`;
  }

  if (tenant === 'app-ca') {
    filevineBaseDomain = '.filevine.ca';
    filevineTenantUrl = `app${filevineBaseDomain}`;
  }

  return {
    filevineApiBaseUrl: `https://${filevineTenantUrl}/v2`,
    filevineBaseDomain,
    filevineBaseUrl: `https://${filevineTenantUrl}`,
    filevineTenantUrl,
    hostUrl: process.env.REACT_APP_HOST || '',
    ephemeralId: process.env.REACT_APP_EPHEMERAL_ID || '',
  };
};

export const fetchTenantConfig = (tenant:string):Promise<ITenantConfig>|ITenantConfig => {
  if (getIsTestEnv()) {
    return testTenantConfig;
  }

  const prodAuthAPIUrl = 'auth-prod-public.filevine.com';
  const nonprodAuthAPIUrl = 'auth-nonprod-public.filevinedev.com';
  // const authAPIUrl = 'auth-auth-dev-public.filevinedev.com'; // leave for testing
  const authAPIUrl = (process.env.REACT_APP_GROUP === 'prod') ? prodAuthAPIUrl : nonprodAuthAPIUrl;
  return fetch(`https://${authAPIUrl}/api/Tenants/authconfig/${tenant}`, {})
    .then(((response) => {
      if (response.status === 404) {
        return response.status;
      }
      return response.json();
    }))
    .then((data) => {
      // if api returns 404 return custom error text
      if (data === 404) {
        return {
          error: `A match for ${tenant} was not found.`,
        };
      }

      const {
        appClients, authorizationEndpoint, awsRegion, cognitoUserPoolID, tenantID,
      } = data;

      if (appClients?.length && cognitoUserPoolID) {
        const { appClientID, identityProviders } = appClients.find((c:IAppClient) => c.clientType === 'Filevine');

        return {
          appClientID,
          authorizationEndpoint,
          awsRegion,
          cognitoUserPoolID,
          identityProviders,
          tenantID,
        };
      }

      return data;
    }).catch(() => ({
      error: 'There was an error fetching your tenant details from Filevine. Error: FVOC984',
    }));
};

export const setTenantConfig = (userTenantConfig: string): void => {
  localStorage.setItem('userTenantConfig', userTenantConfig);
};

export const getTenantConfig = async (): Promise<ITenantConfig> => {
  const lsConfig = localStorage.getItem('userTenantConfig');
  if (lsConfig) {
    return JSON.parse(lsConfig);
  }

  const fConfig = await fetchTenantConfig(getUserTenant());
  if (fConfig?.cognitoUserPoolID) {
    setTenantConfig(JSON.stringify(fConfig));
    return fConfig;
  }

  return fConfig;
};
