import React, {
  FC, useEffect, useState, useCallback, useRef,
} from 'react';

// Utilities
import { ExecutionResult } from 'apollo-link';
import Button from '@fv-components/button';
import LinearProgress from '@fv-components/linear-progress';

// FV API
import useMutateComment from '../../../../util-api/useMutateComment';
import useInput from '../../../../util-hooks/useInput';

// Child Components
import ErrorMsg from '../../../../ErrorMsg';
import TeamLookupMenu, { IUserSearchState } from '../../../TeamLookupMenu';
import RefreshButton from '../../RefreshButton/RefreshButton';

// Styles
const css = require('./EmailActivityInput.module.scss');

interface IEmailActivityInputProps {
  projectId: number;
  userId: string;
  noteId?: number;
  isSavingNote?: boolean;
  addEmailCalled?: boolean;
  onCommentAdded: VoidFunction;
  isComposing: boolean;
  // telling the parent that there is a pending comment
  // that is waiting on a noteId so it can send
  onAddNote: (isCommentPending: boolean) => void;
  refreshComments: VoidFunction;
  onIsInputFocus: (isInputFocus: boolean) => void;
  doneSending: (isSending: boolean) => void;
  errorMessage?: string;
}

const EmailActivityInput: FC<IEmailActivityInputProps> = (
  {
    noteId,
    userId,
    onCommentAdded,
    projectId,
    isSavingNote,
    addEmailCalled,
    onAddNote,
    isComposing,
    refreshComments,
    onIsInputFocus,
    doneSending,
    errorMessage,
  }: IEmailActivityInputProps,
) => {
  const [values, handleValueChange] = useInput({ emailComment: '' });

  // Comment setup
  const {
    // error: commentErr, error doesn't update on mutation bug fixed in apollo v3 beta
    addComment,
    loading: isSendingComment,
  } = useMutateComment();

  const [pendingComment, setPendingComment] = useState<string>();
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const [hasErrorMessage, setHasErrorMessage] = useState<string|undefined>(errorMessage);

  const handleInputFocus = useCallback(() => {
    onIsInputFocus(true);
  }, [onIsInputFocus]);

  const handleInputBlur = useCallback(() => {
    onIsInputFocus(false);
  }, [onIsInputFocus]);

  const handleAddComment = useCallback((comment: string) => {
    handleValueChange({
      target: {
        name: 'emailComment',
        value: '',
      },
    });

    if (userId && noteId) {
      const commentData = {
        body: comment,
        authorId: { native: +userId },
      };
      // add the comment to FV Note/Email
      addComment(noteId, commentData)
        .then((executionResult: ExecutionResult) => {
          if (!executionResult.errors) {
            onCommentAdded();
          }
          setBtnDisabled(false);
        })
        .catch((e: Error) => {
          setHasErrorMessage(e?.message);
        });
    }
  }, [
    addComment,
    userId,
    noteId,
    onCommentAdded,
    handleValueChange,
    setHasErrorMessage,
  ]);

  // Send it
  const handleSend = useCallback(() => {
    doneSending(true);
    setIsSendingMessage(true);

    // disable the button
    setBtnDisabled(true);

    // clear error messages
    setHasErrorMessage('');

    // only send the email if its new
    if (!noteId) {
      if (values?.emailComment?.length) {
        setPendingComment(values.emailComment);
        onAddNote(true);
      } else {
        onAddNote(false);
      }
    } else if (noteId && values?.emailComment?.length) {
      // only post a comment if there's actually a comment to post
      handleAddComment(values.emailComment);
    }
  }, [doneSending, handleAddComment, noteId, onAddNote, values]);

  const isSending = isSavingNote || isSendingComment || isSendingMessage;

  const inputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (errorMessage) {
      setHasErrorMessage(errorMessage);
      setBtnDisabled(false);
      setIsSendingMessage(false);
    }
  }, [setHasErrorMessage, errorMessage, isSending, btnDisabled, noteId, doneSending]);

  // Send the comment once we have an email id or from an existing note id
  useEffect(() => {
    if (noteId && !!pendingComment) {
      setPendingComment(undefined);
      handleAddComment(pendingComment);
    }
  }, [
    noteId,
    pendingComment,
    handleAddComment,
  ]);

  // Send email and comment on shift enter
  // Disable when already sending or no text
  useEffect(() => {
    const submitOnShiftEnter = (e: KeyboardEvent) => {
      if (e.key === 'Enter' && e.shiftKey) {
        e.stopPropagation();
        e.preventDefault();
        if (!isSending && values.emailComment) {
          handleSend();
        }
      }
    };
    document.addEventListener('keydown', submitOnShiftEnter);

    // reset btnDisabled if not currently sending
    if (isSending && btnDisabled && noteId) {
      setBtnDisabled(false);
      doneSending(isSending);
      setIsSendingMessage(false);
    }

    return () => {
      document.removeEventListener('keydown', submitOnShiftEnter);
    };
  }, [
    handleSend,
    isSending,
    values.emailComment,
    btnDisabled,
    setBtnDisabled,
    addEmailCalled,
    values.comment,
    doneSending,
    noteId,
  ]);

  // @username Menu and input stuff
  const getSendButtonText = (): string => {
    if (!noteId && !values.emailComment) {
      if (!isComposing) {
        return 'Attach To Project';
      }
      return 'Send Email';
    }
    if (!noteId) {
      if (!isComposing) {
        return 'Attach With Comment';
      }
      return 'Send Email With Comment';
    }
    return 'Add Comment';
  };

  // TODO this is pretty generic for ripping the search term
  // out of the input and inserting the username maybe can put this in
  // a hook so others can easily make their own
  const handleUserPicked = (username?: string, userSearch?: IUserSearchState) => {
    if (username && userSearch && userSearch.endPos && userSearch.startPos) {
      const value = `${values.emailComment.substring(0, userSearch.startPos)}${username}${values.emailComment.substring(userSearch.endPos)}`;
      handleValueChange({ target: { name: 'emailComment', value } });
      if (inputRef && inputRef.current) {
        inputRef.current.selectionEnd = userSearch.startPos + username.length;
      }
    }
  };

  return (
    <>
      <TeamLookupMenu
        inputRef={inputRef}
        currentProjectId={projectId}
        onSelect={handleUserPicked}
        inputValue={values.emailComment}
      />

      {!!hasErrorMessage && (
        <div className={css.emailSendInputErrorText}>
          <ErrorMsg errorText={hasErrorMessage} />
        </div>
      )}

      <textarea
        ref={inputRef}
        className={css.emailSendInputTextArea}
        data-test="comment-input"
        placeholder="Add a comment or task."
        name="emailComment"
        value={values.emailComment}
        onChange={handleValueChange}
        disabled={isSending}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
      />
      <div className={css.emailSendButtonsRow}>
        <Button
          disabled={btnDisabled}
          unelevated
          className={css.emailSendInputSendBtn}
          data-test="activity-send-button"
          onClick={handleSend}
        >
          {getSendButtonText()}
        </Button>
        {noteId && (
        <div title="Refresh Comments">
          <RefreshButton onRefresh={refreshComments} />
        </div>
        )}
      </div>

      {isSending && (
      <LinearProgress
        indeterminate={isSending}
        closed={!isSending}
        className={css.emailSendInputSending}
      />
      )}
    </>
  );
};

export default EmailActivityInput;
