import { useLazyQuery } from '@apollo/react-hooks';
import { ApolloClient } from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';

import gql from 'graphql-tag';
import { useCallback } from 'react';
import { IProject, IProjectSearchPayload } from './models/project';

export interface IQueryAttachedProjectsHook {
  attachedProjectsResults: IProjectSearchPayload,
  getAttachedProjects: (emailId: string) => void;
}

export const GET_ATTACHED_PROJECTS = gql`
  query attachedProjectsQuery {
    attachedProjects(emailId: $emailId)
      @rest(type: "Projects", path: "/projects/FindByOutlookEmailId/{args.emailId}") {
        clientId
        clientName
        createdDate
        firstPrimaryName
        firstPrimaryUsername
        hashtags
        isArchived
        lastActivity
        links
        orgId
        phaseName
        phaseId
        projectEmailAddress
        projectId
        projectName
        projectOrClientName
        projectUrl
        projectTypeCode
        projectTypeId
        uniqueKey
        rootDocFolderId
    }
  }`;

const useQueryAttachedProjects = (client: ApolloClient<NormalizedCacheObject>):
IQueryAttachedProjectsHook => {
  const [executeQuery, {
    data, error, loading, called,
  }] = useLazyQuery<{attachedProjects: IProject[]}>(
    GET_ATTACHED_PROJECTS, { fetchPolicy: 'cache-and-network', client },
  );

  const getAttachedProjects = useCallback(
    (emailId: string) => executeQuery(
      { variables: { emailId } },
    ), [executeQuery],
  );

  return {
    attachedProjectsResults: {
      called,
      error,
      loading,
      count: 0,
      items: [],
      hasMore: false,
      ...(!!data?.attachedProjects.length && {
        count: data.attachedProjects.length,
        items: data.attachedProjects,
      }),
    },
    getAttachedProjects,
  };
};

export default useQueryAttachedProjects;
