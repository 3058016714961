import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { useCallback } from 'react';

export const UPDATE_RECENT_PROJECTS = gql`
  mutation UpdateRecentProjects($userId: String!, $projectId: Number!) {
    updateRecentProjects(userId: $userId, projectId: $projectId)
      @rest(
        type: "Project",
        path: "/users/{args.userId}/recentprojects/{args.projectId}",
        method: "POST",
        bodyKey: "projectId"
      ) {
          response
      }
  }`;

const useMutateRecentProjects = () => {
  const [executeQuery] = useMutation(UPDATE_RECENT_PROJECTS);
  const updateRecentProjects = useCallback(
    (userId: string, projectId: number) => executeQuery({ variables: { userId, projectId } }),
    [executeQuery],
  );

  return {
    updateRecentProjects,
  };
};

export default useMutateRecentProjects;
