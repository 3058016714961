import { useCallback } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client/errors/ApolloError';
import gql from 'graphql-tag';
import { ExecutionResult } from 'graphql';

export interface IComment {
    projectId: {
        native: number;
    },
    commentId: {
        native: number;
    },
    noteId: {
        native: number;
        partner: number;
    },
    body: string;
    createdAt: string;
    authorId: {
        native: number;
    },
    allowEditing: true,
    documents: [],
}

export interface INoteComments {
  hasMore: boolean;
  items: IComment[];
}

interface INoteCommentsResponse {
  comments: INoteComments;
}

export interface INoteCommentsPayload {
  commentsCalled?: boolean;
  commentsLoading: boolean;
  commentsErr?: ApolloError;
  comments?: INoteComments;
  getNoteComments: (noteId: number) => void;
  getMoreNoteComments: (noteId: number) => Promise<ExecutionResult> | undefined;
  refreshNoteComments: (
    noteId: number,
    isNewCommentAdded: boolean
  ) => Promise<ExecutionResult> | undefined;
}

export const NOTE_COMMENTS_QUERY = gql`
  query commentsQuery($noteId: Number!, $offset: Number!, $limit: Number) {
    comments(noteId: $noteId, offset: $offset, limit: $limit)
      @rest(type: "Comments", path: "/notes/{args.noteId}/comments?offset={args.offset}&orderByDescending=true&limit={args.limit}") {
        items
        hasMore
    }
  }`;

const useQueryNoteComments = (): INoteCommentsPayload => {
  const [executeQuery, {
    data, error, loading, called, fetchMore,
  }] = useLazyQuery<INoteCommentsResponse>(
    NOTE_COMMENTS_QUERY, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network',
    },
  );

  const getNoteComments = useCallback(
    (noteId: number) => executeQuery(
      { variables: { noteId, offset: 0, limit: 50 } },
    ), [executeQuery],
  );

  const getMoreNoteComments = useCallback((noteId: number) => {
    if (fetchMore && data && data.comments
      && data.comments.items && data.comments.hasMore && !loading) {
      return fetchMore({
        variables: {
          offset: data && data.comments ? data.comments.items.length : 0,
          noteId,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return {
            ...prev,
            comments: {
              ...prev.comments,
              items: [
                ...prev.comments.items,
                ...fetchMoreResult.comments.items,
              ],
              hasMore: fetchMoreResult.comments.hasMore,
            },
          };
        },
      });
    }
    return undefined;
  }, [fetchMore, data, loading]);

  const refreshNoteComments = useCallback((noteId: number, isNewCommentAdded: boolean) => {
    if (fetchMore && data && data.comments
      && data.comments.items && !loading) {
      return fetchMore({
        variables: {
          offset: 0,
          noteId,
          limit: data.comments.items.length + (isNewCommentAdded ? 1 : 0) > 50
            ? data.comments.items.length + (isNewCommentAdded ? 1 : 0) : 50,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return {
            ...prev,
            comments: {
              ...prev.comments,
              items: [
                ...fetchMoreResult.comments.items,
              ],
              hasMore: fetchMoreResult.comments.hasMore,
            },
          };
        },
      });
    }
    return undefined;
  }, [fetchMore, data, loading]);

  return {
    commentsCalled: called,
    // in the case of a REST call if an item isn't found a 404 error will be returned
    // this isn't a GraphQL error so the apollo client doesn't treat this like an error
    // and a null object is returned if so then we create a new error object and return it
    // in the case that the result set is empty data.comments will be [] and !== null
    commentsErr: error || (called && !loading && data && data.comments === null ? new ApolloError({ errorMessage: 'possibly a network error?' }) : undefined),
    commentsLoading: loading,
    // If a 2nd call was made that errors (not found) we don't want to return the old data
    comments: !error && data ? data.comments : undefined,
    getNoteComments,
    getMoreNoteComments,
    refreshNoteComments,
  };
};

export default useQueryNoteComments;
