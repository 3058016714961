import React, { FC } from 'react';
import './FilevineIcon.scss';

interface IFilevineIconProps {
  className?: string;
  icon: string;
}

const FilevineIcon: FC<IFilevineIconProps> = ({ className, icon }) => (
  <i className={`fa fa-${icon} ${className}`} />
);

export default FilevineIcon;
