import React, {
  FC, useCallback, useState, useEffect,
} from 'react';
import ErrorMsg from '../../../../ErrorMsg';
import AttachmentChip from '../../../AttachmentChip';
import { IEmlInformation } from '../EmailActivity';

const css = require('./EmailActivityAttachment.module.scss');

const maxFileSize = 25600000;
const tooMuchErrorMessage = 'The files you selected to send to Filevine exceeded the maximum 25MB.  Please unselect a few files';
const cloudErrorMessage = 'Sorry we do not support cloud attachments at this time';
const cloudAttachmentType = 'cloud';

interface IEmailActivityAttachmentProps {
  disabled?: boolean;
  onSelectionChange: (selectedIds: string[]) => void;
  isHidden?: boolean;
  attachments: Office.AttachmentDetailsCompose[];
  selectedIds: string[];
  onRenameFile: (id: string, newName: string) => void;
  onFolderDestinationChange: (id: string, folderId: number) => void;
  setEmlInformation: (eml: IEmlInformation) => void;
  isComposeMode?: boolean;
  currentEmailId: string | undefined;
}

const fileSizeReducer = (
  acc: number, attachmentDetail: Office.AttachmentDetailsCompose,
) => acc + attachmentDetail.size;

const cloudFilter = (
  attachmentDetail: Office.AttachmentDetailsCompose,
) => attachmentDetail.attachmentType !== cloudAttachmentType && !!attachmentDetail.id;

const EmailActivityAttachment: FC<IEmailActivityAttachmentProps> = (
  {
    isHidden,
    onSelectionChange,
    attachments,
    selectedIds,
    onRenameFile,
    onFolderDestinationChange,
    setEmlInformation,
    isComposeMode,
    currentEmailId,
  }: IEmailActivityAttachmentProps,
) => {
  const getAttachmentsFromIds = useCallback((selectedChipIds: string[]) => attachments?.filter(
      (attachmentDetail: Office.AttachmentDetailsCompose) => selectedChipIds
        .includes(attachmentDetail.id),
  ), [attachments]);

  const selectedFileSize = getAttachmentsFromIds(selectedIds).reduce(fileSizeReducer, 0);
  const hasCloudAttachments = !attachments?.findIndex(
    (
      attachmentDetail: Office.AttachmentDetailsCompose,
    ) => attachmentDetail.attachmentType === cloudAttachmentType);

  const [selectedAttachments, setSelectedAttachments] = useState(attachments);
  useEffect(() => {
    setSelectedAttachments(attachments.filter((item) => selectedIds.includes(item.id)));
  }, [attachments, selectedIds]);

  const handleSelection = (id: string) => {
    let val = selectedAttachments;
    if (!selectedAttachments.some((a) => a.id === id)) {
      val = selectedAttachments.concat(attachments.filter((a) => a.id === id));
      setSelectedAttachments(val);
    } else {
      val = selectedAttachments.filter((a) => a.id !== id);
      setSelectedAttachments(val);
    }
    onSelectionChange(val.map((att) => att.id));
  };

  const isSelectedAttachment = (id: string):boolean => {
    const val = !!selectedAttachments
      .find((item) => item.id === id);
    return val;
  };

  const { subject } = Office.context.mailbox.item;
  const [emlFilename, setEmlFilename] = useState<string>(isComposeMode ? '' : `${subject}.eml`);
  const [subjectText, setSubjectText] = useState<string>();

  useEffect(() => {
    setEmlFilename(`${Office.context.mailbox.item.subject}.eml`);
  }, [currentEmailId]);

  useEffect(() => {
    if (isComposeMode) {
      setEmlFilename(`${subjectText}.eml`);
    }
  }, [isComposeMode, subjectText]);

  useEffect(() => {
    const getSubjectCallback = (asyncResult: Office.AsyncResult<string>) => {
      if (subjectText !== asyncResult.value) {
        setSubjectText(asyncResult.value);
      }
    };

    const getSubjectAsync = async () => {
      await Office.context.mailbox.item.subject.getAsync(getSubjectCallback);
    };
    if (isComposeMode) {
      const interval = setInterval(getSubjectAsync, 300);
      return () => clearInterval(interval);
    }
    return () => {};
  }, [isComposeMode, subjectText, setSubjectText]);

  const changeEmlFilename = (id: string, name: string) => {
    setEmlFilename(name);
  };

  // eml attached state
  const [isEmlAttached, setIsEmlAttached] = useState<boolean>(true);
  const toggleEmlAttachment = () => {
    setIsEmlAttached(!isEmlAttached);
  };

  // eml folder destination
  const [folderDestination, setFolderDestination] = useState<number>(0);

  // id is typically required when updating the folder desitination but
  // this for the eml attachment chip and we don't need `id`
  const updateFolderDestination = (id: string, folderId: number) => {
    setFolderDestination(folderId);
  };

  useEffect(() => {
    setEmlInformation(
      {
        name: emlFilename,
        isAttached: isEmlAttached,
        folderId: folderDestination,
      },
    );
  }, [setEmlInformation, emlFilename, isEmlAttached, folderDestination]);

  return (
    <>
      {!isHidden && (
        <div className={css.emailIncludeAttachmentsContainer} data-test="email-attachment-container">
          <AttachmentChip
            filename={emlFilename}
            id="emlFile"
            key="emlFile"
            handleSelection={toggleEmlAttachment}
            isSelectedAttachment={() => isEmlAttached}
            onRenameFile={changeEmlFilename}
            onFolderDestinationChange={updateFolderDestination}
            isEml
          />
          {attachments?.filter(cloudFilter).map((attachment: Office.AttachmentDetailsCompose) => (
            <AttachmentChip
              filename={attachment.name}
              id={attachment.id}
              key={attachment.id}
              handleSelection={handleSelection}
              isSelectedAttachment={isSelectedAttachment}
              onRenameFile={onRenameFile}
              onFolderDestinationChange={onFolderDestinationChange}
            />
          ))}
          {selectedFileSize > maxFileSize
            && (
            <div className={css.emailIncludeAttachmentsErrorText}>
              <ErrorMsg errorText={tooMuchErrorMessage} contactSupport={false} />
            </div>
            )}
          {hasCloudAttachments
            && (
            <div className={css.emailIncludeAttachmentsErrorText}>
              <ErrorMsg errorText={cloudErrorMessage} contactSupport={false} />
            </div>
            )}
        </div>
      )}
    </>
  );
};

export default EmailActivityAttachment;
