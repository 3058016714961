import { useLazyQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useCallback } from 'react';
import { IProject, IProjectSearchPayload } from './models/project';

interface IProjectSearchResponse {
  projects: {
    called: boolean;
    count: number;
    items: IProject[];
    hasMore: boolean;
    limit: number;
  };
}

export interface IQueryProjectsHook {
  projectSearchResults: IProjectSearchPayload,
  getProjects: (searchTerm: string) => void;
}

export const PROJECTS_QUERY = gql`
  query projectsQuery {
    projects(searchTerm: $searchTerm) 
      @rest(type: "Projects", path: "/projects?{args}") {
      count,
      items,
      hasMore,
      limit
    }
  }`;

const useQueryProjects = (): IQueryProjectsHook => {
  const [executeQuery, {
    data, error, loading, called,
  }] = useLazyQuery<IProjectSearchResponse>(
    PROJECTS_QUERY, { notifyOnNetworkStatusChange: true, fetchPolicy: 'cache-and-network' },
  );

  const getProjects = useCallback(
    (searchTerm: string) => executeQuery(
      { variables: { searchTerm } },
    ), [executeQuery],
  );

  return {
    projectSearchResults: {
      called,
      error,
      loading,
      count: 0,
      items: [],
      hasMore: false,
      limit: 0,
      ...(data && data.projects && data.projects.count && {
        count: data.projects.count,
        items: data.projects.items,
        hasMore: data.projects.hasMore,
        limit: data.projects.limit,
      }),
    },
    getProjects,
  };
};

export default useQueryProjects;
