/* eslint-disable react/no-array-index-key */
import React, {
  FC, useEffect, useRef, useState, ReactNode,
} from 'react';
import dayjs from 'dayjs';

import useQueryNoteComments, { IComment } from '../../../../util-api/useQueryNoteComments';
import { dateFormat } from '../../../../util-helpers/common';
import useScroll from '../../../../util-hooks/useScroll';
import ErrorMsg from '../../../../ErrorMsg';

const cssCommon = require('../../../../Common.module.scss');
const css = require('./EmailActivityChat.module.scss');

const formatDate = (value: string) => dayjs(value).format(dateFormat.DATE_AND_TIME);

interface IEmailActivityChat {
  getTeamMemberName: (id: number) => string;
  isTopVisible?: boolean;
  onCommentsRefreshed?: VoidFunction;
  isRefreshingComments?: boolean;
  isCommentAdded?: boolean;
  noteId?: number;
  // when we get away from the one scroll container approach
  // we can remove this
  scrollContainer?: React.RefObject<HTMLDivElement>;
}

const EmailActivityChat: FC<IEmailActivityChat> = (
  {
    getTeamMemberName,
    onCommentsRefreshed,
    isRefreshingComments,
    isCommentAdded,
    noteId,
    scrollContainer,
  }: IEmailActivityChat,
) => {
  const commentsErrTxt = 'We had trouble getting comments for this email.';
  const topRef = useRef<HTMLDivElement>(null);
  const bottomRef = useRef<HTMLDivElement>(null);

  const { isTopVisible } = useScroll(scrollContainer, topRef, bottomRef, 150, 0);
  const [newCommentAdded, setNewCommentAdded] = useState(false);

  // Fetch comments setup
  const {
    getNoteComments, comments, commentsLoading,
    commentsErr, getMoreNoteComments, refreshNoteComments,
  } = useQueryNoteComments();

  // When noteId changes get the new comments if we got a noteId
  useEffect(() => {
    if (noteId) {
      getNoteComments(noteId);
    }
  }, [noteId, getNoteComments]);

  const hasScrolled = useRef(false);
  // Reset scroll if email changed
  useEffect(() => {
    hasScrolled.current = false;
  }, [noteId]);

  const scrollToBottom = (behavior?: 'auto' | 'smooth') => {
    if (bottomRef && bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior });
    }
  };

  // this will trigger on isRefreshing to get the latest comments
  useEffect(() => {
    if (isRefreshingComments && !commentsLoading && noteId && onCommentsRefreshed) {
      onCommentsRefreshed();
      refreshNoteComments(noteId, false);
    }
  }, [isRefreshingComments, commentsLoading, noteId, refreshNoteComments, onCommentsRefreshed]);

  // this will trigger on isRefreshing to get the latest comments
  useEffect(() => {
    if (isCommentAdded && !commentsLoading && noteId && onCommentsRefreshed) {
      onCommentsRefreshed();
      refreshNoteComments(noteId, true);
      setNewCommentAdded(true);
    }
  }, [isCommentAdded, commentsLoading, noteId, refreshNoteComments, onCommentsRefreshed]);

  // when the isTopVisible is true we fetch more comments
  useEffect(() => {
    if (isTopVisible && noteId && !commentsLoading) {
      getMoreNoteComments(noteId);
    }
  // even though getMoreNoteComments is a callback having it in
  // the dependency array triggers the function more times than
  // should be necessary
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTopVisible, noteId]);

  const numComments = comments?.items.length;

  // when the comments list changes and the newCommentsAdded
  // state has been tripped then we will scroll to the bottom
  useEffect(() => {
    if (newCommentAdded) {
      scrollToBottom('smooth');
      setNewCommentAdded(false);
    }
  // it may seem a bit odd that newCommentAdded is not in the deps but its for a reason
  // i don't want to scroll untill those comments have been actually rendered
  // so i need to wait for the comments variable to change.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comments?.items]);

  // on first load of the component we need to scroll to the bottom
  useEffect(() => {
    if (!hasScrolled.current && numComments) {
      scrollToBottom('auto');
      hasScrolled.current = true;
    }
  }, [numComments]);

  const highlightText = (text: string): ReactNode => {
    const usernameRegex = /\B(@[a-zA-Z\d]+\b)/;
    const hashtagRegex = /\B(#[\w+-]+\b)/;
    const parts = text.split(/\B(@[a-zA-Z\d]+\b)|\B(#[\w+-]+\b)/);
    return parts.map((item, index) => {
      if (item && item.match(usernameRegex)) {
        return <span key={index} className={cssCommon.highlightYellow} data-test="highlighted-username">{item}</span>;
      }
      if (item && item.match(hashtagRegex)) {
        return <span key={index} className={cssCommon.highlightBlue} data-test="highlighted-hashtag">{item}</span>;
      }
      return item;
    });
  };

  return (
    <>
      <div ref={topRef} />
      <div className={css.emailActivityChat}>
        {noteId && !!comments?.items?.length
        && comments.items.map((comment: IComment) => (
          <div
            key={comment.commentId.native.toString()}
            className={css.emailActivityComment}
            data-test="comment-card"
          >
            <div className={css.emailActivityCommentBody}>{highlightText(comment.body)}</div>
            <div className={css.emailActivityCommentPosted}>
              <div className={css.emailActivityCommentAuthor}>{`Posted by ${getTeamMemberName(comment.authorId.native)}`}</div>
              <div>{`- ${formatDate(comment.createdAt)}`}</div>
            </div>
          </div>
        ))}
        {commentsErr && <ErrorMsg errorText={commentsErrTxt} />}
      </div>
      <div ref={bottomRef} />
    </>
  );
};

export default EmailActivityChat;
