import React, { FC } from 'react';
import ErrorMsg from '../ErrorMsg';

const cssCommon = require('../Common.module.scss');
const css = require('./FullPageError.module.scss');

interface IFullPageErrorProps {
    errorMessage: string;
}
const FullPageError: FC<IFullPageErrorProps> = ({ errorMessage }: IFullPageErrorProps) => (
  <div className={css.fullPageErrorContainer}>
    <img src="assets/fv-icon-64.png" alt="Filevine" />
    <span className={cssCommon.brandText}>Filevine</span>
    <ErrorMsg errorText={errorMessage} />
  </div>
);

export default FullPageError;
