import { useMutation } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client/errors/ApolloError';
import gql from 'graphql-tag';
import { useCallback } from 'react';
import { ExecutionResult } from 'graphql';

interface ICommentBody {
  body: string;
  authorId: {
    native: number;
  };
}

interface ICommentResponse {
  commentId: {
    native: number;
  };
}

interface ICommentPayload {
  addComment: (noteId: number, commentBody: ICommentBody) => Promise<ExecutionResult>;
  loading: boolean;
  error?: ApolloError;
  called: boolean;
  commentRes?: {
    commentId: {
      native: number;
    };
  };
}

export const ADD_COMMENT = gql`
  mutation AddComment ( $noteId: number!, $commentBody: ICommentBody! ) {
    commentRes (noteId: $noteId, commentBody: $commentBody )
      @rest(
        type: "Comment",
        path: "/notes/{args.noteId}/comments",
        method: "POST",
        bodyKey: "commentBody"
      ) {
        commentId
    }
  }`;

const useMutateComment = (): ICommentPayload => {
  const [executeQuery, {
    data, error, loading, called,
  }] = useMutation<
    { commentRes: ICommentResponse }, { noteId: number; commentBody: ICommentBody; }
  >(
    ADD_COMMENT, { notifyOnNetworkStatusChange: true },
  );

  const addComment = useCallback(
    (noteId: number, commentBody:ICommentBody) => executeQuery(
      { variables: { noteId, commentBody } },
    ), [executeQuery],
  );

  return {
    addComment,
    error,
    loading,
    called,
    commentRes: data && { ...(data && data.commentRes) },
  };
};

export default useMutateComment;
