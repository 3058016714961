import { Auth } from 'aws-amplify';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import {
  getConfig, getTenantConfig, setTenantConfig, setUserTenant,
} from '../config';

const { hostUrl } = getConfig();

enum LocalStorageKey
{
  AccessToken = 'accessToken',
  OrgId = 'orgId',
  UserId = 'userId',
  UserOrgs = 'userOrgs',
  SAMLAuthProvider = 'SAMLAuthProvider',
}

interface IUserId {
  userId: {
    native: number;
    partnerId: string;
  }
}

export interface IUserOrg {
  name: string;
  orgId: number;
}

interface IUserOrgOptions {
  label: string;
  value: number;
}

interface IUserAndOrgId {
  user: IUserId;
  orgs: IUserOrg[];
}

const getAccessToken = async (): Promise<string> => {
  const session = await Auth.currentSession();
  const token = await session.getAccessToken().getJwtToken();
  return token;
};

const getUserId = (): string => (
  localStorage.getItem(LocalStorageKey.UserId) || ''
);

const setUserId = (userId: number | string) => (
  localStorage.setItem(LocalStorageKey.UserId, userId.toString())
);

const getUserOrgs = (): IUserOrg[] => {
  const orgs = localStorage.getItem(LocalStorageKey.UserOrgs);
  return orgs ? JSON.parse(orgs) : [];
};

const getUserOrgsAsOptions = (): IUserOrgOptions[] => getUserOrgs()?.map(
  (o) => ({ label: o.name, value: o.orgId }),
);

const setUserOrgs = (userOrgs: IUserOrg[]) => (
  localStorage.setItem(LocalStorageKey.UserOrgs, JSON.stringify(userOrgs))
);

const getOrgId = (): number | null => {
  const id = localStorage.getItem(LocalStorageKey.OrgId);
  return id ? +id : null;
};

const getCurrentOrg = (): IUserOrg | undefined => {
  const orgId = getOrgId();
  return getUserOrgs().find((o) => o.orgId === orgId);
};

const setOrgId = (orgId: number | string) => {
  localStorage.setItem(LocalStorageKey.OrgId, orgId.toString());
};

export const setSAMLAuth = (SAMLName:string) => {
  localStorage.setItem(LocalStorageKey.SAMLAuthProvider, SAMLName);
};

const getSAMLAuth = () => localStorage.getItem(LocalStorageKey.SAMLAuthProvider);

export const handleAmplifyConfigure = async (): Promise<void> => {
  const {
    appClientID, awsRegion, cognitoUserPoolID, tenantID,
  } = await getTenantConfig();

  const authConfig = {
    userPoolId: cognitoUserPoolID,
    userPoolWebClientId: appClientID,
    mandatorySignIn: true,
    oauth: {},
  };

  if (getSAMLAuth()) {
    authConfig.oauth = {
      domain: `${tenantID}.auth.${awsRegion}.amazoncognito.com`,
      scope: ['email', 'profile', 'openid'],
      redirectSignIn: `${hostUrl}/success`,
      responseType: 'code',
    };
  }

  Auth.configure({
    Auth: authConfig,
  });
};

export const fetchUserAndOrgId = async (session: string): Promise<IUserAndOrgId> => {
  const { filevineApiBaseUrl } = getConfig();
  return fetch(`${filevineApiBaseUrl}/utils/GetUserOrgsWithToken`, {
    method: 'post',
    headers: {
      Authorization: `Bearer ${session}`,
    },
  }).then((response: Response) => {
    if (response.ok) {
      return response.json();
    }
    throw new Error();
  }).catch(() => {
    throw new Error('There was an error fetching your user and org id from Filevine. Error: FVOA631');
  });
};

export const fetchAndSetUserAndOrgId = async (session: string) => {
  const userOrgAndId: IUserAndOrgId = await fetchUserAndOrgId(session);

  if (userOrgAndId?.orgs && userOrgAndId?.user?.userId?.native) {
    // User but has no org
    if (!userOrgAndId.orgs?.length) {
      throw new Error('This feature only supports active subscribed Filevine users.  If you believe this to be in error, please contact your organization administrator to be properly configured within the organization. Error: FVOA742');
    }

    // Good user w/ 1 or more orgs
    userOrgAndId.orgs.sort(
      (a:IUserOrg, b:IUserOrg) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1),
    );
    setUserOrgs(userOrgAndId.orgs);
    setUserId(userOrgAndId.user.userId.native);

    // Only set a default orgId if they don't already have one thats a valid org
    const userOrgId = getOrgId();
    if (!userOrgId || !(userOrgAndId.orgs.findIndex((x) => x.orgId === userOrgId) > -1)) {
      setOrgId(userOrgAndId.orgs[0].orgId);
    }
    return true;
  }
  throw new Error('There was an error setting your Filevine user or org id. Error: FVOA853');
};

const updateUserFromSession = () => new Promise<void>((resolve, reject) => {
  const asyncFunction = async () => {
    await Auth.currentSession()
      .then((data: CognitoUserSession) => {
        const session = data.getIdToken().getJwtToken();
        fetchAndSetUserAndOrgId(session)
          .then(() => resolve())
          .catch((e) => reject(e));
      }).catch(() => {
        reject(new Error('Unable to get current session.'));
      });
  };

  asyncFunction();
});

const validateUserAuthentication = async () => {
  try {
    return await Auth.currentAuthenticatedUser();
  } catch {
    return false;
  }
};

const logout = () => {
  setUserId('');
  setOrgId('');
  setUserTenant('');
  setTenantConfig('');
  setSAMLAuth('');
  Auth.signOut().then(() => {
    window.location.assign(hostUrl);
  });
};

export {
  getCurrentOrg,
  getUserId,
  getUserOrgs,
  getUserOrgsAsOptions,
  getOrgId,
  setOrgId,
  getAccessToken,
  logout,
  updateUserFromSession,
  validateUserAuthentication,
};
