import React, { useState, FC } from 'react';
import FilevineIcon from '../../FilevineIcon';

const css = require('./CollapsibleSection.module.scss');

interface ICollapsibleSectionProps {
  collapsed?: boolean;
  content: string|JSX.Element;
  icon: string;
  title?: string;
}

const CollapsibleSection: FC<ICollapsibleSectionProps> = ({
  collapsed,
  content,
  icon,
  title,
}: ICollapsibleSectionProps) => {
  const [isOpen, setIsOpen] = useState(!collapsed);

  return (
    <>
      <div
        role="button"
        tabIndex={0}
        className={`${css.sectionHead} ${isOpen ? css.sectionHeadExpanded : ''}`}
        onClick={() => { setIsOpen((o) => !o); }}
        onKeyDown={(e: React.KeyboardEvent<HTMLSpanElement>) => {
          if (e.key === 'Enter') {
            setIsOpen((o) => !o);
          }
        }}
      >
        <span className={css.sectionHeadLeft}>
          <FilevineIcon className={css.sectionHeadIcon} icon={icon} />
          {title}
        </span>
        <FilevineIcon
          className={css.sectionExpandIcon}
          icon={isOpen ? 'chevron-down' : 'chevron-left'}
        />
      </div>
      <div
        className={`${css.sectionBody} ${isOpen ? css.show : css.hide}`}
      >
        {content}
      </div>
    </>
  );
};

export default CollapsibleSection;
