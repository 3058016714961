import React, {
  FC, useContext, useEffect, useState,
} from 'react';

import useQueryProjectDocuments, { IDocument } from '../../../../util-api/useQueryProjectDocuments';
import useQueryFolderList, { IFolder } from '../../../../util-api/useQueryFolderList';
import useDebounce from '../../../../util-hooks/useDebounce';
import useInput from '../../../../util-hooks/useInput';
import FilevineIcon from '../../../FilevineIcon';
import ProjectContext from '../../../ProjectContext';
import Document from '../Document';

import SecondarySearchBar from '../../SecondarySearchBar';
import FolderBreadcumbs from './FolderBreadcrumbs';
import Button from '../../../../components/packages/button/src/Button';

const css = require('./DocumentSearch.module.scss');

interface IDocumentSearchProps {
  editorMode?: boolean,
  setSelectedCallback?: (folder: IFolder) => void;
}

const DocumentSearch: FC<IDocumentSearchProps> = ({ editorMode = false, setSelectedCallback }
  : IDocumentSearchProps) => {
  const { currentProjectId, currentProjectData } = useContext(ProjectContext);
  const [isProjectRoot, setIsProjectRoot] = useState(true);
  const rootDocFolderId = currentProjectData?.rootDocFolderId?.native.toString();
  const [currFolderId, setCurrFolderId] = useState<string | undefined>(rootDocFolderId);
  const showDocuments = !editorMode;

  // Doc list
  const [values, handleValueChange] = useInput({ documentsSearchTerm: '' });
  const {
    getProjectDocuments,
    projectDocumentsSearchResult: {
      documents,
      loading: docsLoading,
    },
  } = useQueryProjectDocuments();

  // Doc search on project change or searchTerm change
  const debouncedDocumentSearchTerm = useDebounce(values.documentsSearchTerm, 300).trimStart();
  useEffect(() => {
    if (currentProjectId) {
      // If we have a search term and at the project root search all doc folders
      if (debouncedDocumentSearchTerm && currFolderId === rootDocFolderId) {
        getProjectDocuments(currentProjectId, debouncedDocumentSearchTerm);
      } else {
        getProjectDocuments(currentProjectId, debouncedDocumentSearchTerm, currFolderId);
      }
    }
    // We dont want to search if the folderId changes we already do that in the click handler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProjectId, debouncedDocumentSearchTerm]);

  // Folder List
  const {
    getFolderList,
    folderListResult: {
      folderList,
      loading: foldersLoading,
    },
  } = useQueryFolderList();

  useEffect(() => {
    // Get initial folder list on new project
    getFolderList(undefined, currentProjectId);
    // We dont want to get folders again when the folder list changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProjectId]);

  const parentFolder = folderList?.links?.parent;
  const parentFID = parentFolder?.replace('/folders/', '');
  const rootFolder = currentProjectData?.links?.rootFolder;
  const shouldHideParentLink = foldersLoading || rootFolder === parentFolder;

  // Track Folder names
  interface IFolderNames {[key: string]: string;}
  const [folderNames, setFolderNames] = useState<IFolderNames>({});
  useEffect(() => {
    if (folderList?.items?.length) {
      let newFolders: IFolderNames | undefined;
      folderList.items.forEach((folder) => {
        const folderId = folder.folderId.native;
        if (!folderNames[folderId]) {
          newFolders = {
            ...newFolders,
            [folderId]: folder.name,
          };
        }
      });

      if (newFolders) {
        setFolderNames({
          ...folderNames,
          ...newFolders,
        });
      }
    }
    // We don't want to include folderNames as it is updated here and will loop
    // eslint-disable-next-line
  }, [folderList]);

  // Click handlers
  const handleProjectFolderClicked = () => {
    setIsProjectRoot(true);
    setCurrFolderId(rootDocFolderId);
    getFolderList(undefined, currentProjectId);
    if (currentProjectId) {
      // If we have a search term search all doc folders
      if (debouncedDocumentSearchTerm) {
        getProjectDocuments(currentProjectId, debouncedDocumentSearchTerm);
      } else {
        getProjectDocuments(currentProjectId, debouncedDocumentSearchTerm, rootDocFolderId);
      }
    }
  };

  const handleParentFolderClicked = () => {
    setCurrFolderId(parentFID);
    getFolderList(`${parentFolder}/children`);
    if (currentProjectId) {
      getProjectDocuments(currentProjectId, debouncedDocumentSearchTerm, parentFID);
    }
  };

  const handleChildFolderClicked = (folder: IFolder) => {
    const fid = folder.folderId.native.toString();
    setIsProjectRoot(false);
    setCurrFolderId(fid);
    getFolderList(folder.links.children);
    if (currentProjectId) {
      getProjectDocuments(currentProjectId, debouncedDocumentSearchTerm, fid);
    }
  };

  const setFolderDestination = (folder: IFolder) => {
    if (setSelectedCallback) {
      setSelectedCallback(folder);
    }
  };

  return (
    <>
      {!editorMode && (
        <SecondarySearchBar
          searchInputName="documentsSearchTerm"
          searchPlaceholderText="Search Filevine Documents"
          searchTerm={values.documentsSearchTerm}
          handleSearchTermChange={handleValueChange}
          loading={docsLoading || foldersLoading}
        />
      )}
      {currentProjectData && (
        <FolderBreadcumbs
          currentProjectName={currentProjectData?.projectOrClientName || 'Project'}
          isProjectRoot={isProjectRoot}
          shouldHideParentLink={shouldHideParentLink}
          parentFID={parentFID || ''}
          folderNames={folderNames}
          currFolderId={currFolderId || ''}
          handleParentFolderClicked={handleParentFolderClicked}
          handleProjectFolderClicked={handleProjectFolderClicked}
        />
      )}
      <div className={css.documentsContainer}>
        {/* Display a list of child folders that can be navigated to */}
        {folderList?.items?.map((folder) => (
          <div className={css.docContainer} key={folder.folderId.native}>
            <div
              key={`${folder.folderId.native}`}
              className={css.docItem}
              role="button"
              tabIndex={0}
              title={folder.name.length > 26 ? folder.name : ''}
              onClick={() => handleChildFolderClicked(folder)}
              onKeyDown={(e: React.KeyboardEvent<HTMLSpanElement>) => {
                if (e.key === 'Enter') {
                  handleChildFolderClicked(folder);
                }
              }}
            >
              <span>
                <FilevineIcon icon="folder" className={css.docIcon} />
                {folder.name}
              </span>
            </div>
            <div className={css.folderIdSelectDiv}>
              {editorMode && (
                <Button className={css.folderIdSelectButton} type="button" onClick={() => setFolderDestination(folder)}>
                  <FilevineIcon icon="paperclip" />
                </Button>
              )}
            </div>
          </div>
        ))}
        {showDocuments && documents?.items?.map((doc: IDocument) => (
          <Document
            doc={doc}
            key={doc.documentId.native}
            currentFolderId={currFolderId || ''}
          />
        ))}
        {showDocuments && documents?.hasMore
          // eslint-disable-next-line no-extra-boolean-cast
          && <div className={css.moreResults}>{`More than ${documents.limit} documents. ${(!!values.documentsSearchTerm ? 'Please refine your search.' : 'Please use search.')}`}</div>}
        {showDocuments && !documents?.items?.length && !docsLoading
          && <div className={css.noResults}>No results</div>}
      </div>
    </>
  );
};

export default DocumentSearch;
