import gql from 'graphql-tag';
import { useCallback } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client/errors/ApolloError';

interface ICode {
  description: string;
  code: string;
}

export interface IBillingCodes {
  name: string;
  fieldSelectorName: string;
  assignedToBillingTypes: string[];
  codes?: ICode[];
}

interface IBillingCodesResponse {
  billingCodes: IBillingCodes[];
}

export interface IBillingCodesPayload {
  getBillingCodes: VoidFunction;
  called: boolean;
  loading: boolean;
  error?: ApolloError;
  billingCodes?: IBillingCodes[];
}

export const BILLING_CODES_QUERY = gql`
  query billingCodesQuery {
    billingCodes
      @rest(type: "billingCodes", path: "/billing/availablebillingcodes") {
          name
          fieldSelectorName
          assignedToBillingTypes
          codes
      }
  }`;

const useQueryBillingCodes = (): IBillingCodesPayload => {
  const [executeQuery, {
    data, error, loading, called,
  }] = useLazyQuery<IBillingCodesResponse>(
    BILLING_CODES_QUERY, { notifyOnNetworkStatusChange: true, fetchPolicy: 'cache-and-network' },
  );

  const getBillingCodes = useCallback(executeQuery, [executeQuery]);

  return {
    getBillingCodes,
    called,
    loading,
    error,
    // If a 2nd call was made that errors we don't want to return the old data
    billingCodes: (!error && data) ? data.billingCodes : undefined,
  };
};

export default useQueryBillingCodes;
