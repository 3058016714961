import React, {
  FC, useState, useEffect, useCallback, useRef,
} from 'react';

// Utilities
import {
  nrPageAction,
  nrCustomAttribute,
  nrInteraction,
  nrInteractionTrace,
} from '../../../../util-helpers/newrelic';
import {
  clearAttachmentArray,
  isAttachmentInArray,
} from '../../../../util-helpers/common';

// FV API
import useMutateEmail, { EmailPayload } from '../../../../util-api/useMutateEmail';
import useSendOutlookItem from '../../../../util-api/useSendOutlookItem';

// Child Components
import DocumentWorkspace from '../../DocumentWorkspace';
import EmailActivityAttachment from '../EmailActivityAttachment';
import EmailActivityInput from '../EmailActivityInput';
import GenericCard from '../../GenericCard';
import { IEmlInformation } from '../EmailActivity';

// Styles
const css = require('./EmailCompose.module.scss');

const MISSING_RECIPIENT_ERROR = 'You need to add at least one recipient.';
const EMAIL_SEND_ERROR = 'We had trouble sending this email and adding to your Filevine project.';
const FV_UPLOAD_ERROR = 'The email was successfully sent but failed to upload to Filevine.  Please find the message in your sent box and try again.';

const updatePartnerId = (currentEmailId?: string, currentProjectId?: number) => {
  if (currentEmailId && currentProjectId) {
    return `outlookId:${currentEmailId}-projectId:${currentProjectId}`;
  }
  return `outlookId:${Date.now()}-projectId:developerMode`;
};

// NOTE: currentEmailId is passed in via ProjectContext which is set in /src/index.tsx
// message id from Office is used if defined, ie: Office.context.mailbox.item.internetMessageId
// otherwise an id is generated, ie: `NO-EMAIL-ID-YET-${new Date().getTime()}`
interface IEmailComposeProps {
  currentUserId: string;
  currentProjectId: number;
  currentEmailId?: string;
}

export interface IComposeAttachment {
  attachment: Office.AttachmentDetailsCompose;
  folderId: number;
}

const EmailCompose: FC<IEmailComposeProps> = (
  {
    currentUserId,
    currentProjectId,
    currentEmailId,
  }: IEmailComposeProps,
) => {
  const [
    attachmentDetails,
    setAttachmentDetails,
  ] = useState<IComposeAttachment[]>();
  const [
    selectedAttachmentDetails,
    setSelectedAttachmentDetails,
  ] = useState<IComposeAttachment[]>([]);
  const [
    isCommentPending,
    setIsCommentPending,
  ] = useState(false);
  const [
    isLoading,
    setIsLoading,
  ] = useState(false);
  const [
    isSendEmailSuccess,
    setIsSendEmailSuccess,
  ] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string|undefined>();
  // please leave this code here for future debugging of desktop client send functionality
  // const [errors, setErrors] = useState<string[]>([]);

  // Custom hooks
  const {
    addEmail,
    called: addEmailToFVCalled,
    noteId,
  } = useMutateEmail(currentProjectId);
  const {
    sendOutlookItem,
  } = useSendOutlookItem();

  // You do not need to set the encoding value when your mail app is running in Outlook on the web.
  // You can determine whether your mail app is running in Outlook or Outlook on the web by using
  // the mailbox.diagnostics.hostName property.
  // You can determine what version of Outlook is running by using
  // the mailbox.diagnostics.hostVersion property.

  const onCommentAdded = () => {
    setIsCommentPending(false);
    setIsLoading(false);
  };

  enum AttachmentStatus {
    Added = 'added',
    Removed = 'removed',
  }

  const itemRef = useRef(Office?.context?.mailbox?.item);

  interface Attachment {
    name?: string
  }
  const attachmentChangeHandler = useCallback((args: Office.AttachmentsChangedEventArgs) => {
    if (args.attachmentStatus?.toLocaleLowerCase() === AttachmentStatus.Added) {
      const newAttachment = args.attachmentDetails as unknown as Office.AttachmentDetailsCompose;
      setAttachmentDetails((previousState: any) => (previousState ? [
        ...previousState.filter(
          (att: IComposeAttachment) => att.attachment.id !== newAttachment.id,
        ),
        { attachment: newAttachment, folderId: 0 },
      ] : [{ attachment: newAttachment, folderId: 0 }]));
      if (!isAttachmentInArray((args.attachmentDetails as Attachment)?.name || '') && !newAttachment.isInline) {
        setSelectedAttachmentDetails((previousState: any) => [
          ...previousState,
          { attachment: newAttachment, folderId: 0 },
        ]);
        clearAttachmentArray();
      }
    } else {
      // eslint-disable-next-line max-len
      const removedAttachment = args.attachmentDetails as unknown as Office.AttachmentDetailsCompose;
      setAttachmentDetails((previousState: any) => previousState && [
        ...previousState.filter(
          (att: IComposeAttachment) => att.attachment.id !== removedAttachment.id,
        ),
      ]);
      setSelectedAttachmentDetails((previousState: any) => [
        ...previousState.filter(
          (att: IComposeAttachment) => att.attachment.id !== removedAttachment.id,
        ),
      ]);
    }
  }, [AttachmentStatus.Added]);

  const onSelectionChange = useCallback((selectedIds: string[]) => {
    if (attachmentDetails) {
      setSelectedAttachmentDetails(
        attachmentDetails.filter(
          (attachmentDetail: IComposeAttachment) => (
            selectedIds.includes(attachmentDetail.attachment.id)),
        ),
      );
    }
  }, [attachmentDetails]);

  const onRenameFile = useCallback((id: string, newName:string) => {
    if (attachmentDetails !== undefined) {
      const item = attachmentDetails.find((att) => att.attachment.id === id);
      if (item) {
        item.attachment.name = newName;
      }

      const mutatedAttachments = attachmentDetails.map((att) => (
        att.attachment.id === id ? { ...att, name: newName } : att));
      setAttachmentDetails(mutatedAttachments);
    }
  }, [attachmentDetails]);

  const addEmailToFV = (email: EmailPayload, id: string) => {
    const partnerId = updatePartnerId(id, currentProjectId);
    const message = {
      ...email,
      emailId: {
        ...email.emailId,
        partner: partnerId,
      },
    };

    // New Relic
    nrPageAction('end_handleSend', { currentProjectId });

    addEmail(message)
      .then(() => {
        setIsSendEmailSuccess(true);
        setErrorMessage('');
        if (!isCommentPending) {
          setIsLoading(false);
          setIsSending(false);
          // New Relic
          nrCustomAttribute('emailComposeAttach', partnerId);
        }
      })
      .catch((e: Error) => {
        setErrorMessage(e?.message || FV_UPLOAD_ERROR);
        setIsLoading(false);
        setIsSending(false);
      });
  };

  const [emlInformation, setEmlInformation] = useState<IEmlInformation>({ name: 'Subject.eml', isAttached: true, folderId: 0 });

  const saveOutlookMessage = () => {
    const callback = (asyncId: Office.AsyncResult<string>) => {
      // New Relic
      nrInteraction();
      sendOutlookItem(asyncId.value, selectedAttachmentDetails, emlInformation)
        .then(({ email, id }) => {
          addEmailToFV(email, id);
        })
        .catch((e: Error) => {
          // please leave for future desktop debugging
          // setErrors((previousErrors) => [...previousErrors, error.message]);
          setIsLoading(false);
          setIsSending(false);
          setErrorMessage(e?.message || EMAIL_SEND_ERROR);

          // New Relic
          nrPageAction('error_sendOutlookItem', { emailId: asyncId.value });
        });
    };

    // saveAsync returns an itemId however in cache mode on desktop the item
    // might not have propogated to the server yet, so in sendOutlookItem
    // we check for the existence on the server and manually sync w/ server
    itemRef.current.saveAsync(callback);
  };

  const handleSend = (commentPending: boolean) => {
    setIsCommentPending(commentPending);
    setIsSending(true);
    setErrorMessage('');

    // New Relic
    nrPageAction('start_handleSend', { currentProjectId });
    nrInteractionTrace('handleSend', () => nrCustomAttribute('emailSent', currentProjectId));

    if (itemRef.current.to) {
      itemRef.current.to.getAsync(
        (asyncResult: Office.AsyncResult<Office.EmailAddressDetails[]>) => {
          if (asyncResult.value.length > 0) {
            setIsLoading(true);
            // Save the email to Outlook and FV and send
            saveOutlookMessage();
          } else {
            setErrorMessage(MISSING_RECIPIENT_ERROR);
            setIsLoading(false);
            setIsSending(false);
          }
        },
      );
    }
  };

  useEffect(() => {
    if (addEmailToFVCalled
        && !isLoading
        && !isCommentPending
        && isSendEmailSuccess
        && itemRef.current
    ) {
      itemRef.current.close();
    }
  }, [isLoading, addEmailToFVCalled, isCommentPending, isSendEmailSuccess]);

  // populate the list of attachments on load
  useEffect(() => {
    if (Office?.context?.mailbox?.item) {
      Office.context.mailbox.item.addHandlerAsync(
        Office.EventType.AttachmentsChanged,
        attachmentChangeHandler,
      );
      Office.context.mailbox.item.getAttachmentsAsync(
        {},
        (asyncResult: Office.AsyncResult<Office.AttachmentDetailsCompose[]>) => {
          const attachments = asyncResult.value.map(
            (att) => ({ attachment: { ...att }, folderId: 0 }),
          );

          setAttachmentDetails(
            attachments,
          );
          setSelectedAttachmentDetails(
            attachments.filter((att) => !att.attachment.isInline),
          );
        },
      );
    } else {
      setAttachmentDetails([]);
    }

    if (itemRef.current) {
      itemRef.current.removeHandlerAsync(Office.EventType.AttachmentsChanged);
    }
    itemRef.current = Office?.context?.mailbox?.item;
    return () => {
      if (itemRef.current) {
        itemRef.current.removeHandlerAsync(Office.EventType.AttachmentsChanged);
      }
    };
  }, [attachmentChangeHandler, currentEmailId]);

  const onFolderDestinationChange = useCallback((id: string, folderId: number) => {
    const mutated = selectedAttachmentDetails
      .map((item: IComposeAttachment) => (item.attachment.id === id
        ? {
          attachment: { ...item.attachment },
          folderId,
        }
        : item));
    setSelectedAttachmentDetails(mutated);
  }, [selectedAttachmentDetails]);

  return (
    <>
      <GenericCard buttonTitle="Attach Filevine Documents" child={<DocumentWorkspace />} />
      {attachmentDetails && (
      <EmailActivityAttachment
        onSelectionChange={onSelectionChange}
        attachments={attachmentDetails.map((att) => att.attachment)}
        selectedIds={selectedAttachmentDetails
          .map((att: IComposeAttachment) => att.attachment.id)}
        onRenameFile={onRenameFile}
        onFolderDestinationChange={onFolderDestinationChange}
        setEmlInformation={setEmlInformation}
        isComposeMode
        currentEmailId={currentEmailId}
      />
      )}
      {!isSendEmailSuccess && (
        <EmailActivityInput
          userId={currentUserId}
          onCommentAdded={onCommentAdded}
          projectId={currentProjectId}
          onAddNote={handleSend}
          isSavingNote={isLoading}
          noteId={noteId}
          isComposing
          refreshComments={() => {}}
          onIsInputFocus={() => {}}
          doneSending={() => isSending}
          errorMessage={errorMessage}
        />
      )}
      {isSendEmailSuccess && (
        <div className={css.emailComposeSendErrorText}>
          Message sent!
          <br />
          Check your Sent Items folder.
        </div>
      )}
      {/* Please leave for future desktop debugging */}
      {/* {errors.map((error) => <ErrorMsg errorText={error} />)} */}
    </>
  );
};

export default EmailCompose;
