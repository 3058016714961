import { useCallback, useState } from 'react';
import {
  IEmailContact,
  EmailPayload,
  IAttachment,
} from './useMutateEmail';
import {
  IOutlookItem,
  IOutlookItemEmailAddress,
  IOutlookAttachmentContentResponse,
} from './models/outlookItem';
import {
  getEmailAttachmentsCompose,
  getEmailMimeFromRestId,
  getLocalItemPayload,
  getIsWeb,
  getIsMobile,
} from '../util-helpers/office';
import { simplifyHtml } from '../util-helpers/common';
import { IComposeAttachment } from '../Taskpane/ProjectWorkspace/EmailActivity/EmailCompose/EmailCompose';
import { IEmlInformation } from '../Taskpane/ProjectWorkspace/EmailActivity/EmailActivity';

interface ISendOutlookItemHook {
  loading: boolean;
  error?: string;
  called: boolean;
  sendOutlookItem: (
    itemId: string,
    attachmentsContainer: IComposeAttachment[],
    emlInformation: IEmlInformation,
  ) => Promise<{ email: EmailPayload, id: string }>;
  item?: EmailPayload;
  id?: string;
  conversationId?: string;
}

interface ISendOutlookItemState {
  called: boolean;
  loading: boolean;
  error?: string;
  item?: EmailPayload;
  id?: string;
  conversationId?: string;
}

const apiAddressToJsAddress = (contact: IOutlookItemEmailAddress): IEmailContact => {
  if (contact && contact.EmailAddress) {
    return {
      address: contact.EmailAddress.Address,
      name: contact.EmailAddress.Address === contact.EmailAddress.Name ? '' : contact.EmailAddress.Name,
    };
  }
  return { name: '', address: '' };
};

const sendDraft = (
  restId: string,
  headers: Headers,
  callback: (
    item?: EmailPayload,
    internetMessageId?: string,
    conversationId?: string,
    error?: string
  ) => void,
  emailPayload: EmailPayload,
  internetMessageId: string,
  conversationId: string,
) => {
  // TODO: More descriptive error messaging and logging
  fetch(`${Office.context.mailbox.restUrl}/v2.0/me/messages/${restId}/send`, {
    headers,
    method: 'POST',
  }).then(async (response: Response) => {
    if (response.ok) {
      callback(
        emailPayload,
        internetMessageId,
        conversationId,
      );
    } else {
      callback(
        undefined,
        undefined,
        undefined,
        `There was an error trying to send the draft status: ${response.status} ${response.statusText} ${await response.text()}`,
      );
    }
  }).catch((error: Error) => {
    callback(
      undefined,
      undefined,
      undefined,
      `There was an error trying to send the draft ${error.message}`,
    );
  });
};

const convertRestResponseToEmailPayload = (
  restResponse: IOutlookItem,
  attachments: IAttachment[],
  emlAttachment?: IAttachment,
): EmailPayload => {
  const emailPayload = new EmailPayload();

  const payload = {
    ...emailPayload,
    subject: restResponse.Subject || emailPayload.subject,
    from: {
      address: Office.context.mailbox.userProfile.emailAddress,
      name: Office.context.mailbox.userProfile.displayName,
    },
    to: restResponse.ToRecipients.map(apiAddressToJsAddress) || emailPayload.to,
    cc: restResponse.CcRecipients.map(apiAddressToJsAddress) || emailPayload.cc,
    html: simplifyHtml(restResponse.Body.Content),
    headers: {
      ...emailPayload.headers,
      date: restResponse.SentDateTime || emailPayload.headers.date,
    },
    attachments: [
      ...attachments,
    ],
  };
  if (emlAttachment) {
    payload.attachments.push(emlAttachment);
  }
  return payload;
};

const getEmailFromRestId = (
  restId: string,
  headers: Headers,
  callback: (item?: EmailPayload, id?: string, conversationId?: string, error?: string) => void,
  attachmentsContainer: IComposeAttachment[],
  emlInformation: IEmlInformation,
) => {
  fetch(`${Office.context.mailbox.restUrl}/v2.0/me/messages/${restId}`, {
    headers,
    method: 'GET',
  }).then(async (response: Response) => {
    if (response.ok) {
      response.json().then((restResponse: IOutlookItem) => {
        getEmailMimeFromRestId(restResponse.Subject, restId, emlInformation)
          .then((emlAttachment: IAttachment) => {
            if (attachmentsContainer && !!attachmentsContainer.length) {
              getEmailAttachmentsCompose(Office.context.mailbox.item, attachmentsContainer)
                .then((downloadedAttachments: IAttachment[]) => {
                  sendDraft(restId,
                    headers,
                    callback,
                    convertRestResponseToEmailPayload(
                      restResponse,
                      downloadedAttachments,
                      emlInformation?.isAttached ? emlAttachment : undefined,
                    ),
                    restResponse.InternetMessageId
                      .substring(1, restResponse.InternetMessageId.length - 1),
                    restResponse.ConversationId);
                })
                .catch((error: Error) => {
                  callback(
                    undefined,
                    undefined,
                    undefined,
                    `Unable to download selected attachments ${error.message}`,
                  );
                });
            } else {
              sendDraft(
                restId,
                headers,
                callback,
                convertRestResponseToEmailPayload(
                  restResponse,
                  [],
                  emlInformation?.isAttached ? emlAttachment : undefined,
                ),
                restResponse.InternetMessageId
                  .substring(1, restResponse.InternetMessageId.length - 1),
                restResponse.ConversationId,
              );
            }
          })
          .catch((error: Error) => {
            callback(
              undefined,
              undefined,
              undefined,
              `Unable to download mime content for eml file attachment  ${error.message}`,
            );
          });
      });
    } else {
      callback(
        undefined,
        undefined,
        undefined,
        `There was an error trying get the email contents status: ${response.status} ${response.statusText} ${await response.text()}`,
      );
    }
  }).catch((error: Error) => {
    callback(
      undefined,
      undefined,
      undefined,
      `There was an error trying get the email contents ${error.message}`,
    );
  });
};

const useSendOutlookItem = (): ISendOutlookItemHook => {
  const [state, setState] = useState<ISendOutlookItemState>({
    called: false,
    loading: false,
  });

  const onCompletion = useCallback(
    (
      resolve: (value: { email: EmailPayload, id: string }) => void,
      reject: (error: Error) => void,
      item?: EmailPayload,
      internetMessageId?: string,
      conversationId?: string,
      error?: string,
    ) => {
      setState({
        called: true,
        error,
        loading: false,
        item,
        id: internetMessageId,
        conversationId,
      });

      // needed so the state can update before i resolve the promise
      setTimeout(() => {
        if (!error && item && internetMessageId) {
          resolve({
            email: item,
            id: internetMessageId,
          });
        } else {
          reject(new Error(`there was an error sending your email: ${error}`));
        }
      }, 1);
    }, [],
  );

  const sendOutlookItem = useCallback(
    (
      itemId: string,
      attachmentsContainer: IComposeAttachment[],
      emlInformation: IEmlInformation,
    ) => {
      setState({
        called: true,
        loading: true,
        error: undefined,
        item: undefined,
        id: undefined,
        conversationId: undefined,
      });
      // 1. Convert the ItemId to something we can use with the REST API
      const isMobile = getIsMobile();
      const restId = isMobile ? Office.context.mailbox.item.itemId
        : Office.context.mailbox.convertToRestId(
          itemId,
          Office.MailboxEnums.RestVersion.v2_0,
        );

      return new Promise<{ email: EmailPayload, id: string }>((resolve, reject) => {
        // 2. Get a fresh token for the user
        Office.context.mailbox.getCallbackTokenAsync(
          { isRest: true },
          async (tokenResult: Office.AsyncResult<string>) => {
            if (!tokenResult.error) {
              // 2a. Set up headers for working with REST API
              const headers = new Headers();
              headers.append('Authorization', `Bearer ${tokenResult.value}`);
              headers.append('Content-Type', 'application/json');

              // 3. Sync the data on server with desktop app local cache
              // 3a. Outlook web app doesn't have local cache so skip the sync logic
              if (getIsWeb()) {
                getEmailFromRestId(
                  restId,
                  headers,
                  (
                    item?: EmailPayload,
                    internetMessageId?: string,
                    conversationId?: string,
                    error?: string,
                  ) => onCompletion(
                    resolve,
                    reject,
                    item,
                    internetMessageId,
                    conversationId,
                    error,
                  ),
                  attachmentsContainer,
                  emlInformation,
                );
              // 3b. Outlook desktop app has local cache we need to sync server
              } else {
                // 4. Get the current email from the local cache
                const localCacheItem = await getLocalItemPayload(Office.context.mailbox.item);

                // 4a. We're using Office.context.mailbox.item.saveAsync() in the onSend handler
                // to get the ItemId however if it's a new message then it might not have
                // propogated from the local Outlook cache to the server so we call the update
                // function recursively if it returns an error it likely means the new message
                // hasn't yet propogated so we call it again until the PATCH call succeeds or
                // we reach the call limit but draft messages that have been saved to the server
                // this call will simply update the message on the server directly with the
                // data stored in the local cache that was most recently edited by the user
                const localAttachments = localCacheItem.Attachments || [];
                const updateMessage = (body: string, callInt: number) => {
                  // 5. Call REST API to update the message with payload from local cache
                  fetch(`${Office.context.mailbox.restUrl}/v2.0/me/messages/${restId}`, {
                    headers,
                    method: 'PATCH',
                    body,
                  })
                    .then(async (res: Response) => {
                      if (res.ok) {
                        res.json().then((updatedMessage: IOutlookItem) => {
                          // 6. We need to sync the attachments manually
                          // the PATCH doesn't update attachments on a saved draft
                          // only sync the attachments if there are attachments locally or
                          // the server returns HasAttachments: true but locally there are none
                          // eslint-disable-next-line max-len
                          if (localAttachments.length > 0 || (updatedMessage.HasAttachments && localAttachments.length === 0)) {
                            // GET the attachments on the server to sync against local
                            fetch(`${Office.context.mailbox.restUrl}/v2.0/me/messages/${restId}/attachments?$select=Name`, {
                              headers,
                              method: 'GET',
                            }).then(async (attachmentsResponse: Response) => {
                              if (attachmentsResponse.ok) {
                                attachmentsResponse.json()
                                  .then((serverItem: IOutlookAttachmentContentResponse) => {
                                    const serverAttachments = serverItem.value || [];
                                    // array of items that ARE ON LOCAL BUT NOT SERVER
                                    const addAttachments = localAttachments.filter(
                                      (la) => !serverAttachments.some(
                                        (sa) => sa.Name === la.Name,
                                      ),
                                    );
                                    // array of items that ARE ON SERVER BUT NOT LOCAL
                                    const dropAttachments = serverAttachments.filter(
                                      (sa) => !localAttachments.some(
                                        (la) => la.Name === sa.Name,
                                      ),
                                    );

                                    // Removes Attachments
                                    const removeAttachments = (index: number) => {
                                      fetch(`${Office.context.mailbox.restUrl}/v2.0/me/messages/${restId}/attachments/${dropAttachments[index].Id}`, {
                                        headers,
                                        method: 'DELETE',
                                      }).then((dropResponse: Response) => {
                                        if (dropResponse.ok) {
                                          if (dropAttachments[index + 1]) {
                                            removeAttachments(index + 1);
                                          } else {
                                            getEmailFromRestId(
                                              restId,
                                              headers,
                                              (
                                                item?: EmailPayload,
                                                internetMessageId?: string,
                                                conversationId?: string,
                                                error?: string,
                                              ) => onCompletion(
                                                resolve,
                                                reject,
                                                item,
                                                internetMessageId,
                                                conversationId,
                                                error,
                                              ),
                                              attachmentsContainer,
                                              emlInformation,
                                            );
                                          }
                                        }
                                      }).catch((error: Error) => {
                                        onCompletion(
                                          resolve,
                                          reject,
                                          undefined,
                                          undefined,
                                          undefined,
                                          `Failed to remove attachments from the server: ${error.message}`,
                                        );
                                      });
                                    };

                                    // Adds and Removes Attachments
                                    const syncAttachments = (index: number) => {
                                      fetch(`${Office.context.mailbox.restUrl}/v2.0/me/messages/${restId}/attachments`, {
                                        headers,
                                        method: 'POST',
                                        body: JSON.stringify(addAttachments[index]),
                                      }).then((addResponse: Response) => {
                                        if (addResponse.ok) {
                                          if (addAttachments[index + 1]) {
                                            syncAttachments(index + 1);
                                          // Adding complete, check attachments to remove
                                          } else if (dropAttachments.length) {
                                            removeAttachments(0);
                                          } else {
                                            getEmailFromRestId(
                                              restId,
                                              headers,
                                              (
                                                item?: EmailPayload,
                                                internetMessageId?: string,
                                                conversationId?: string,
                                                error?: string,
                                              ) => onCompletion(
                                                resolve,
                                                reject,
                                                item,
                                                internetMessageId,
                                                conversationId,
                                                error,
                                              ),
                                              attachmentsContainer,
                                              emlInformation,
                                            );
                                          }
                                        }
                                      }).catch((error: Error) => {
                                        onCompletion(
                                          resolve,
                                          reject,
                                          undefined,
                                          undefined,
                                          undefined,
                                          `Failed to sync attachments with the server: ${error.message}`,
                                        );
                                      });
                                    };

                                    // server has attachments but local doesn't then remove all
                                    if (dropAttachments.length && !addAttachments.length) {
                                      removeAttachments(0);
                                    // local attachments so we need to sync
                                    } else if (addAttachments.length) {
                                      syncAttachments(0);
                                    // nothing to sync
                                    } else {
                                      getEmailFromRestId(
                                        restId,
                                        headers,
                                        (
                                          item?: EmailPayload,
                                          internetMessageId?: string,
                                          conversationId?: string,
                                          error?: string,
                                        ) => onCompletion(
                                          resolve,
                                          reject,
                                          item,
                                          internetMessageId,
                                          conversationId,
                                          error,
                                        ),
                                        attachmentsContainer,
                                        emlInformation,
                                      );
                                    }
                                  });
                              }
                            }).catch((error: Error) => {
                              onCompletion(
                                resolve,
                                reject,
                                undefined,
                                undefined,
                                undefined,
                                `Failed to get attachments from the server with: ${error.message}`,
                              );
                            });
                          } else {
                            getEmailFromRestId(
                              restId,
                              headers,
                              (
                                item?: EmailPayload,
                                internetMessageId?: string,
                                conversationId?: string,
                                error?: string,
                              ) => onCompletion(
                                resolve,
                                reject,
                                item,
                                internetMessageId,
                                conversationId,
                                error,
                              ),
                              attachmentsContainer,
                              emlInformation,
                            );
                          }
                        });
                      } else {
                      // 7. ErrorItemNotFound error, item hasn't propogated so call recursively
                        res.json().then((error: Error) => {
                          if (callInt < 10) {
                            setTimeout(() => {
                              updateMessage(JSON.stringify(localCacheItem), (callInt + 1));
                            }, 3000);
                          } else {
                            onCompletion(
                              resolve,
                              reject,
                              undefined,
                              undefined,
                              undefined,
                              `Failed updating the message on the server with: ${error.message}`,
                            );
                          }
                        });
                      }
                    })
                    .catch((error: Error) => {
                      onCompletion(
                        resolve,
                        reject,
                        undefined,
                        undefined,
                        undefined,
                        `Failed updating the message on the server with: ${error.message}`,
                      );
                    });
                };

                // 4. Call updateMessage recursively
                updateMessage(JSON.stringify(localCacheItem), 0);
              }
            } else {
              onCompletion(
                resolve,
                reject,
                undefined,
                undefined,
                undefined,
                `Failed to get callback token message: ${tokenResult.error.message}`,
              );
            }
          },
        );
      });
    }, [onCompletion],
  );

  return {
    error: state.error,
    loading: state.loading,
    called: state.called,
    item: state.item,
    id: state.id,
    sendOutlookItem,
    conversationId: state.conversationId,
  };
};

export default useSendOutlookItem;
