import React, { FC, useEffect, useState } from 'react';
import useQueryDocumentLocator from '../../../../util-api/useQueryDocumentLocator';
import { IDocument } from '../../../../util-api/useQueryProjectDocuments';
import FilevineIcon from '../../../FilevineIcon';
import { addAttachmentToEmail } from '../../../../util-helpers/office';
import getIcon from './docUtilities';
import {
  formatBytes, formatDate, getFileExtension, pushToAttachmentArray,
} from '../../../../util-helpers/common';

interface IDocumentProps {
  doc: IDocument,
  currentFolderId: string,
}

const css = require('./Document.module.scss');

const Document: FC<IDocumentProps> = ({
  doc,
  currentFolderId,
}: IDocumentProps) => {
  const {
    called: dlCalled,
    loading: dlLoading,
    documentLocator,
    getDocumentLocator,
  } = useQueryDocumentLocator();

  const [document, setDocument] = useState<IDocument>();
  const [extension, setExtension] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [hasErrorLoading, setErrorLoading] = useState(false);

  const handleOnClick = (docForAttach: IDocument) => {
    if (isLoading) return;
    pushToAttachmentArray(docForAttach.filename);
    getDocumentLocator(docForAttach.documentId.native);
    setDocument(docForAttach);
  };

  const callback = (asyncResult: Office.AsyncResult<string>): void => {
    if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
      setIsLoading(false);
    } else {
      // eslint-disable-next-line no-console
      console.error(asyncResult);
      setErrorLoading(true);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (dlCalled && !dlLoading && documentLocator && document) {
      setIsLoading(true);
      addAttachmentToEmail({ url: documentLocator?.url, name: document?.filename },
        callback, true, false);
    }
  }, [dlCalled, dlLoading, documentLocator, document]);

  useEffect(() => {
    if (doc.filename) {
      setExtension(getFileExtension(doc.filename));
    }
  }, [doc]);

  const statusIndicator = (): JSX.Element => {
    if (hasErrorLoading) return <FilevineIcon icon="exclamation-triangle" className={css.errorLoading} data-test="error-loading-doc" />;
    if (isLoading) return <FilevineIcon icon="spinner fa-spin" className={css.docIcon} data-test="loading-indicator" />;
    return <FilevineIcon icon={getIcon(extension).fontAwesome} className={`${css.docIcon} ${getIcon(extension).cssClass}`} />;
  };

  return (
    <div
      className={isLoading ? css.loadingOverlay : css.docItem}
      role="button"
      tabIndex={0}
      onClick={() => handleOnClick(doc)}
      data-test="doc-component"
      onKeyDown={(e: React.KeyboardEvent<HTMLSpanElement>) => {
        if (e.key === 'Enter') {
          handleOnClick(doc);
        }
      }}
    >
      <div className={css.top}>
        {statusIndicator()}
        <span>{doc.filename}</span>
      </div>
      <div className={css.middle}>
        <span className={css.uploaderFullname}>
          {doc.uploaderFullname}
          &nbsp;
          (
          {formatBytes(doc.size)}
          )
        </span>
        <span className={css.formatDate}>
          {formatDate(doc.uploadDate)}
        </span>
      </div>
      {doc && doc.hashtags.length > 0
      && (
        <div className={css.bottom}>
          <span className={css.hashTags}>{doc.hashtags.map((ht) => ht).join(' ')}</span>
        </div>
      )}
      {currentFolderId
        && currentFolderId !== doc.folderId.native.toString()
        && doc.folderName
        && (
          <div className={css.footer}>
            <FilevineIcon icon="folder" className={css.docIcon} />
            <small>{doc.folderName}</small>
          </div>
        )}
    </div>
  );
};

export default Document;
