import React, { FC, useState } from 'react';
import { IFolder } from '../../util-api/useQueryFolderList';
import DocumentEditor from '../DocumentEditor/DocumentEditor';
import FilevineIcon from '../FilevineIcon';
import GenericCard from '../ProjectWorkspace/GenericCard';

const css = require('./AttachmentChip.module.scss');

export interface IAttachmentChipProps {
  filename: string;
  id: string;
  handleSelection: (id: string) => void;
  isSelectedAttachment: (id: string) => boolean;
  onRenameFile: (id: string, newName: string) => void;
  onFolderDestinationChange: (id: string, folderId: number) => void;
  isEml?: boolean;
}
const AttachmentChip: FC<IAttachmentChipProps> = ({
  filename,
  id,
  handleSelection,
  isSelectedAttachment,
  onRenameFile,
  onFolderDestinationChange,
  isEml,
}: IAttachmentChipProps) => {
  const [parentList, setParentList] = useState<IFolder[]>([]);
  const updateParentFolder = (pFolder: IFolder) => {
    if (pFolder && !parentList.some((folder:IFolder) => folder === pFolder)) {
      setParentList(parentList?.concat(pFolder));
    }
  };

  const [selectedFolder, setSelectedFolder] = useState<IFolder>();
  const setSelected = (folder: IFolder | undefined) => {
    setSelectedFolder(folder);
    onFolderDestinationChange(id, folder?.folderId?.native || 0);
  };
  const clearSelected = () => {
    setSelectedFolder(undefined);
    onFolderDestinationChange(id, 0);
  };

  const getParentFromKey = (key: number): IFolder => parentList?.filter(
    (p:IFolder) => p?.folderId.native === key)[0];

  return (
    <div className={css.component} key={id}>
      {selectedFolder && (
      <div className={
        isSelectedAttachment(id)
          ? css.folderDestinationContainer
          : css.folderDestinationContainerUnselected
      }
      >
        <FilevineIcon icon="folder" className={css.folderIcon} />
        <div>
          {selectedFolder?.name || 'None'}
        </div>
        <span>&nbsp;</span>
      </div>
      )}
      <div className={selectedFolder ? css.filenameContainerIndent : css.filenameContainer}>
        <button className={css.checkButton} type="button" onClick={() => handleSelection(id)} data-test="selectAttachment">
          <FilevineIcon className={css.checkIcon} icon={isSelectedAttachment(id) ? 'check-square-o' : 'square-o'} />
        </button>
        <div className={isSelectedAttachment(id) ? css.filenameDisplay : css.filenameDisplayUnselected} data-test="filename">
          {!!isEml
            && <FilevineIcon className={css.emlIcon} icon="envelope-o" data-test="emlIcon" />}
          { filename }
        </div>
        <GenericCard
          outlined={false}
          buttonIcon={<FilevineIcon className={css.editIcon} icon="arrow-circle-right" />}
          child={(
            <DocumentEditor
              filename={filename}
              id={id}
              handleSelection={handleSelection}
              isSelectedAttachment={isSelectedAttachment}
              onRenameFile={onRenameFile}
              onFolderSelectionCallback={setSelected}
              clearSelected={clearSelected}
              selectedFolder={selectedFolder}
              setParentFolder={updateParentFolder}
              getParentFolder={getParentFromKey}
            />
          )}
        />
      </div>
    </div>
  );
};

export default AttachmentChip;
