import React from 'react';
import { getConfig } from '../../config';
import EphemeralId from '../../EphemeralId';

const { hostUrl } = getConfig();

function Success() {
  if (Office?.context?.host) {
    Office.context.ui.messageParent('');
  } else {
    window.location.assign(hostUrl);
  }

  return (
    <>
      <EphemeralId />
      <div>Success</div>
    </>
  );
}

export default Success;
