import React, { useRef } from 'react';
import Menu, { MenuList, MenuListItem } from '@fv-components/menu';
import { Corner } from '@fv-components/menu-surface';
import Persona from '../../ProjectWorkspace/Persona';

const css = require('./UserLookupMenu.module.scss');

interface IUser {
  fullname: string;
  username: string;
  pictureUrl: string;
}

interface IUserLookupMenuProps<T extends IUser> {
  filter?: string;
  isOpen: boolean;
  onMenuItemClicked: (item: T) => void;
  highlightedIndex: number;
  menuItemRef: React.RefObject<HTMLDivElement>;
  users?: T[];
}

const UserLookupMenu = <T extends IUser>(
  {
    isOpen, onMenuItemClicked, highlightedIndex, menuItemRef, users,
  }: IUserLookupMenuProps<T>,
) => {
  const menuAnchorRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  return (
    <>
      <div ref={menuAnchorRef} />
      <Menu
        open={isOpen && (users && !!users.length)}
        anchorCorner={Corner.BOTTOM_LEFT}
        anchorElement={menuAnchorRef.current}
        anchorMargin={{ left: 5, top: 5 }}
        onSelected={(index: number) => users && users[index] && onMenuItemClicked(users[index])}
      >
        <MenuList>
          {users && users.map((
            { fullname, username, pictureUrl }: IUser, index,
          ) => (
            <MenuListItem key={username} className={index === highlightedIndex ? css.userLookupSelected : ''}>
              {(index === (users.length - 1)) ? (
                <div tabIndex={-1} ref={menuItemRef}>
                  <Persona
                    small
                    avatarUrl={pictureUrl}
                    backgroundColor="white"
                    name={fullname}
                    secondLine={`@${username}`}
                  />
                </div>
              ) : (
                <div>
                  <Persona
                    small
                    avatarUrl={pictureUrl}
                    backgroundColor="white"
                    name={fullname}
                    secondLine={`@${username}`}
                  />
                </div>
              )}
            </MenuListItem>
          ))}
        </MenuList>
      </Menu>
    </>
  );
};

export default UserLookupMenu;
