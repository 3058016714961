import React, { FC } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { dateFormat } from '../../../util-helpers/common';

const css = require('./Vitals.module.scss');

dayjs.extend(utc);

interface IVital {
  fieldName: string;
  friendlyName: string;
  fieldType: string;
  value?: any;
  position: number;
}

interface IVitalsProps {
  vitals: IVital[];
  phaseName?: string;
}

const vital = (key: string, value: string, name: string) => (
  <div key={key} className={css.vitalRow}>
    <div className={css.vitalName}>{`${name}:`}</div>
    <div className={css.vitalValueContainer}>
      <span className={css.vitalValue}>{value}</span>
    </div>
  </div>
);

const formatTime = (value: string, type: 'DateUtc'|'DateTimeUtc'|'ComboDate'|'DateOnly', isUtc: boolean) => {
  if (!value) return '';
  if (type === 'DateTimeUtc' || type === 'ComboDate') {
    const fDateTime = dateFormat.DATE_AND_TIME;
    return isUtc ? dayjs(`${value}Z`).format(fDateTime) : dayjs(value).format(fDateTime);
  }
  const fDate = dateFormat.DATE_ONLY;
  return isUtc ? dayjs(`${value}Z`).format(fDate) : dayjs(value).format(fDate);
};

const formatAge = (value: string) => {
  const birthdate = dayjs(value);
  if (!birthdate.isValid()) {
    return value;
  }

  const now = dayjs();
  return now.diff(birthdate, 'year');
};

const filteredVitals = ({
  fieldName, fieldType, friendlyName, value,
}: IVital) => {
  let newVal;

  if (fieldName === 'phase') return null;

  switch (fieldType) {
    case 'StringList':
      newVal = value.slice(1, -1).replace(/,/g, ' '); // [#tag,#two,#three] > #tag #two #three
      break;
    case 'DateOnly':
    case 'ComboDate':
      newVal = formatTime(value, fieldType, false);
      break;
    case 'DateUtc':
    case 'DateTimeUtc':
      newVal = formatTime(value, fieldType, true);
      break;
    case 'AgeYears':
      newVal = formatAge(value);
      break;
    case 'Currency':
      newVal = value ? `$${value}` : value;
      break;
    case 'Percent':
      newVal = value ? `${value}%` : value;
      break;
    default:
      newVal = value;
      break;
  }

  return vital(fieldName, newVal, friendlyName);
};

const Vitals: FC<IVitalsProps> = ({ vitals, phaseName }: IVitalsProps) => (
  <div className={css.vitalsContainer}>
    {phaseName && vital('phaseName', phaseName, 'Phase')}
    {!!vitals.length && vitals.map((v: IVital) => (filteredVitals(v)))}
  </div>
);
export default Vitals;
