import React, {
  useContext, FC, useEffect, useRef, useState,
} from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import LinearProgress from '@fv-components/linear-progress';

import useQueryProjects from '../../util-api/useQueryProjects';
import useQueryRecentProjects from '../../util-api/useQueryRecentProjects';
import { getIsDesktop } from '../../util-helpers/office';
import useDebounce from '../../util-hooks/useDebounce';
import useInput from '../../util-hooks/useInput';
import {
  getUserId,
  getUserOrgsAsOptions,
  setOrgId,
  logout,
} from '../../Auth/auth';
import FilevineIcon from '../FilevineIcon';
import InlineOrgPicker from '../InlineOrgPicker';
import ProjectContext from '../ProjectContext';
import SearchLimitWarning from '../SearchLimitWarning';

const css = require('./ProjectSearchBar.module.scss');

interface IProjectSearchBarProps {
  clearCurrentProject: VoidFunction;
}

const ProjectSearchBar: FC<IProjectSearchBarProps> = ({ clearCurrentProject }) => {
  const userId = getUserId();
  const userOrgs = getUserOrgsAsOptions();

  const {
    currentProjectData,
    setCurrentProject,
    setProjectSearchResults,
  } = useContext(ProjectContext);
  const isDesktop = getIsDesktop();

  // The actual selectedOrgId is not used for the search we just need to know it changed
  const [selectedOrgId, setSelectedOrgId] = useState<number>();
  const handleOrgIdSelected = (id:number) => {
    setSelectedOrgId(id);
    setOrgId(id);

    // If the user is in a project view and changes orgs we need to go back to the search
    if (currentProjectData) {
      setCurrentProject(undefined);
    }
  };

  const [values, handleValueChange] = useInput({ projectSearchTerm: '' });
  const { projectSearchResults, getProjects } = useQueryProjects();
  const { recentProjectsResults, getRecentProjects } = useQueryRecentProjects();
  const debouncedSearchTerm = useDebounce(values.projectSearchTerm, 300);
  const inputRef = useRef<HTMLInputElement>(null);
  const projectResults = !debouncedSearchTerm ? recentProjectsResults : projectSearchResults;

  useDeepCompareEffect(() => {
    setProjectSearchResults(projectSearchResults);
  }, [projectSearchResults, setProjectSearchResults]);

  useDeepCompareEffect(() => {
    setProjectSearchResults(recentProjectsResults);
  }, [recentProjectsResults, setProjectSearchResults]);

  // GET projects by search term
  useEffect(() => {
    if (!currentProjectData && !!debouncedSearchTerm) {
      getProjects(debouncedSearchTerm);
    }
  }, [
    selectedOrgId,
    debouncedSearchTerm,
    currentProjectData,
    getProjects,
  ]);

  // GET default recent projects
  useEffect(() => {
    if (!currentProjectData && !debouncedSearchTerm && userId) {
      getRecentProjects(userId);
    }
  }, [
    selectedOrgId,
    userId,
    currentProjectData,
    getRecentProjects,
    debouncedSearchTerm,
  ]);

  useEffect(() => {
    const logOutOnCombination = (e: KeyboardEvent) => {
      if (e.key === 'o' && e.altKey && e.ctrlKey) {
        logout();
        e.preventDefault();
        e.stopPropagation();
      }
    };
    document.addEventListener('keydown', logOutOnCombination);

    return () => {
      document.removeEventListener('keydown', logOutOnCombination);
    };
  }, []);

  return (
    <div className={css.searchHeader}>
      {userOrgs && userOrgs.length > 1 && (
        <InlineOrgPicker onOrgSelected={handleOrgIdSelected} />
      )}

      <div className={css.searchContainer}>
        {isDesktop
          ? (
            <span className={css.searchBarLogoContainer}>
              <img className={css.searchBarLogo} src="assets/fv-icon-32.png" alt="Filevine" />
            </span>
          )
          : <span />}
        {!currentProjectData
          ? (
            <input
              ref={inputRef}
              className={css.searchBar}
              data-test="project-search"
              name="projectSearchTerm"
              placeholder="Search for a project by name or client."
              value={values.projectSearchTerm}
              onChange={handleValueChange}
            />
          )
          : (
            <div className={css.searchTitle}>
              <a href={currentProjectData.projectUrl} target="_blank" rel="noopener noreferrer">
                <span className={css.projectName}>{currentProjectData.projectOrClientName}</span>
              </a>
              <button
                aria-label="search"
                className={css.searchBtn}
                type="button"
                onClick={clearCurrentProject}
                onKeyDown={(e: React.KeyboardEvent<HTMLSpanElement>) => {
                  if (e.key === 'Enter') {
                    clearCurrentProject();
                  }
                }}
              >
                <FilevineIcon className={css.searchIcon} icon="search" />
              </button>
            </div>
          )}
      </div>

      {!currentProjectData && projectResults && (
        <>
          <LinearProgress
            indeterminate={projectResults.loading}
            closed={!projectResults.loading}
          />

          {!projectResults.hasMore ? (
            <div className={css.searchBarResultsCount}>
              {`Project Results: ${projectResults.count}`}
            </div>
          ) : (
            <SearchLimitWarning limit={projectResults.limit} />
          )}
        </>
      )}
    </div>
  );
};

export default ProjectSearchBar;
