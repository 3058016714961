import React, { FC } from 'react';

const css = require('./ErrorMsg.module.scss');

interface IErrorMsgProps {
  errorText?: string;
  contactSupport?: boolean;
}

const ErrorMsg: FC<IErrorMsgProps> = ({ errorText, contactSupport = true }: IErrorMsgProps) => (
  <div className={css.errorMsgContainer}>
    {errorText && <div>{errorText}</div>}
    {contactSupport && (
      <div className={css.errorMsgSupport}>
        <div className={css.firstLine}>Please try again.</div>
        If the problem continues contact&nbsp;
        <a href="https://support.filevine.com?utm_source=outlook" target="_blank" rel="noopener noreferrer">support</a>
        .
      </div>
    )}
  </div>
);

export default ErrorMsg;
