import React, { FC, useState } from 'react';
import FilevineIcon from '../FilevineIcon';

const css = require('./EditableChip.module.scss');

interface EditableChipProps {
  label: string;
  id: string;
  handleSelection: (id: string) => void;
  isSelectedAttachment: (id: string) => boolean;
  onRenameFile: (id: string, newName: string) => void;
  callback?: (filename: string) => void;
}

interface EditableChipErrors {
  hasErrors: boolean;
  errorMessage: string;
}

const EditableChip: FC<EditableChipProps> = ({
  label,
  id,
  handleSelection,
  isSelectedAttachment,
  onRenameFile,
}:EditableChipProps) => {
  const [filename, setFilename] = useState(label);
  const [isEditMode, setIsEditMode] = useState(false);
  const [showSaved, setShowSaved] = useState(false);
  const [errors, setErrors] = useState<EditableChipErrors>({ hasErrors: false, errorMessage: '' });
  const clearErrors = () => {
    setErrors({ hasErrors: false, errorMessage: '' });
  };

  const invalidCharactersRegex = /[<>:"|?*/\\].*/;
  const filenameSplitterRegex = /(.+?)(\.[^.]*$|$)/;
  const splitFilename = filenameSplitterRegex.exec(filename)!;
  const extension = splitFilename[2];
  const filenameWithoutExtension = splitFilename[1];
  const [tempFilename, setTempFilename] = useState(filenameWithoutExtension);

  const handleSave = (e: React.FormEvent) => {
    e.preventDefault();
    if (errors.hasErrors) return;
    setFilename(`${tempFilename}${extension}`);
    onRenameFile(id, `${tempFilename}${extension}`);
    setIsEditMode(false);
    setShowSaved(true);
  };

  const handleCancel = () => {
    clearErrors();
    setTempFilename(filenameWithoutExtension);
    setIsEditMode(false);
  };

  const handleFilenameChange = (e: React.FormEvent<HTMLInputElement>) => {
    setIsEditMode(true);
    setShowSaved(false);
    const newName = e.currentTarget.value;
    let errorstruct = { hasErrors: false, errorMessage: '' };
    if (invalidCharactersRegex.exec(newName)) {
      errorstruct = { hasErrors: true, errorMessage: 'Filename cannot contain < > : " \\ / | ? or *' };
    }
    if ((`${newName}${extension}`).length > 255) {
      errorstruct = { hasErrors: true, errorMessage: 'Filename is longer than 255 characters.' };
    }
    if (newName.trim() === '') {
      errorstruct = { hasErrors: true, errorMessage: 'Filename cannot be missing.' };
    }
    setErrors(errorstruct);
    setTempFilename(newName);
  };

  const handleKeypress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' && errors.hasErrors) {
      e.preventDefault();
    }
  };

  return (
    <div className={css.component} key={id}>
      <button className={css.checkButton} type="button" onClick={() => handleSelection(id)} data-test="selectAttachment">
        <FilevineIcon className={css.checkIcon} icon={isSelectedAttachment(id) ? 'check-square-o' : 'square-o'} />
      </button>
      <>
        <form onSubmit={handleSave} className={isEditMode ? css.editFilenameView : css.filenameView} data-test="form">
          <input
            className={errors.hasErrors ? css.inputHasErrors : css.input}
            type="text"
            value={tempFilename}
            onChange={handleFilenameChange}
            data-test="editFilenameInput"
            onKeyPress={handleKeypress}
          />
          {showSaved
            && <span className={css.saved}>Saved!</span>}
          {isEditMode && (
          <>
            <button className={css.cancelButton} type="button" onClick={handleCancel} data-test="cancelButton">
              <FilevineIcon className={css.cancelIcon} icon="close" />
            </button>
            <button className={css.saveButton} type="button" onClick={handleSave} data-test="saveButton">
              Save
            </button>
          </>
          )}
        </form>
      </>
      { errors.hasErrors
        ? <div className={css.errorMessage} data-test="errorMsg">{errors.errorMessage}</div> : <></>}
    </div>
  );
};

export default EditableChip;
