import React, { FC } from 'react';

import {
  addRecipientToEmail, getIsOutlook, getIsReading,
} from '../../../util-helpers/office';

const css = require('./EmailLink.module.scss');

interface IEmailLinkProps {
  displayName: string;
  emailAddress: string;
  emailField: 'to' | 'cc' | 'bcc' | 'requiredAttendees' | 'optionalAttendees';
  emailLabel?: string;
}

const EmailLink: FC<IEmailLinkProps> = (
  {
    emailAddress,
    emailField,
    emailLabel = '',
    displayName,
  }: IEmailLinkProps,
) => {
  const isOutlook = getIsOutlook();
  const isReading = getIsReading();

  return !isReading
    ? (
      <>
        <span
          role="button"
          tabIndex={0}
          className={css.emailLink}
          data-test="email-link"
          onClick={() => addRecipientToEmail(
            { emailAddress, displayName },
            emailField,
            isOutlook,
            isReading,
          )}
          onKeyDown={(e: React.KeyboardEvent<HTMLSpanElement>) => {
            if (e.key === 'Enter') {
              addRecipientToEmail(
                { emailAddress, displayName },
                emailField,
                isOutlook,
                isReading,
              );
            }
          }}
        >
          {emailAddress}
        </span>
        <span className={css.emailLinkLabel}>{emailLabel}</span>
      </>
    ) : (
      <span>
        {emailAddress}
        <span className={css.emailLinkLabel}>{emailLabel}</span>
      </span>
    );
};

export default EmailLink;
