import React, {
  useRef, useEffect, useState, useCallback, FC,
} from 'react';
import UserLookupMenu from './UserLookupMenu';
import useQueryProjectTeam, { ITeamMember } from '../../util-api/useQueryProjectTeam';

interface IUserMenuLookupHookProps {
  inputRef: React.RefObject<HTMLTextAreaElement> | React.RefObject<HTMLInputElement>;
  currentProjectId?: number;
  onSelect: (username: string, searchState: IUserSearchState) => void;
  inputValue: string;
}

export interface IUserSearchState {
  startPos?: number;
  endPos?: number;
  searchTerm?: string;
}

interface ITeamMemberLookupState {
  isOpen: boolean;
  userSearch?: IUserSearchState;
  highlightedIndex: number;
  isStartingNewSearch: boolean;
}

const defaultState : ITeamMemberLookupState = {
  isOpen: false,
  userSearch: undefined,
  highlightedIndex: 0,
  isStartingNewSearch: false,
};

export const findEndPositionOfSearch = (input: string, startPos: number) => {
  if (input.indexOf(' ', startPos) > startPos) {
    return input.indexOf(' ', startPos);
  }
  if (input.indexOf('@', startPos) > startPos) {
    return input.indexOf('@', startPos);
  }
  return input.length;
};

const UserMenuLookupContainer: FC<IUserMenuLookupHookProps> = ({
  inputRef, currentProjectId, onSelect, inputValue,
}: IUserMenuLookupHookProps) => {
  const menuItemRef = useRef<HTMLDivElement>(null);
  const [state, setState] = useState<ITeamMemberLookupState>({ ...defaultState });
  const {
    isStartingNewSearch, isOpen, userSearch, highlightedIndex,
  } = state;
  const { searchTerm, startPos } = userSearch || { searchTerm: '' };

  const {
    getProjectTeam,
    projectTeamSearchResults: { team },
  } = useQueryProjectTeam();

  useEffect(() => {
    if (currentProjectId) {
      getProjectTeam(currentProjectId, '', 1000);
    }
  }, [currentProjectId, getProjectTeam]);

  const [filteredTeam, setFilteredTeam] = useState(team && team.items);

  const closeMenu = useCallback(() => {
    if (state.isOpen) {
      const clearedSearchState = {
        ...defaultState,
      };
      setState(clearedSearchState);
    }
  }, [state.isOpen]);

  const onUserSelected = useCallback((user: ITeamMember) => {
    closeMenu();
    onSelect(user.username, userSearch || {});
  }, [userSearch, onSelect, closeMenu]);

  const updateFilteredTeam = useCallback(
    (newSearchTerm: string) => {
      const filtered = team?.items.filter(({ fullname, username }: ITeamMember) => (
        (fullname && fullname.toLocaleLowerCase().includes(newSearchTerm))
        || (username && username.toLocaleLowerCase().includes(newSearchTerm))
      ));
      setFilteredTeam(filtered);
      if (filtered && filtered.length === 1
        && filtered[0].username && filtered[0].username.toLocaleLowerCase() === newSearchTerm) {
        closeMenu();
      }
    },
    [team, closeMenu],
  );

  useEffect(() => {
    // after starting a new search we have to wait for the inputValue to catch up and get
    // the @ symbol in the correct place where the trigger handler expected it
    if (startPos && isStartingNewSearch && inputValue.indexOf('@', startPos - 1) === startPos - 1) {
      const endPos = findEndPositionOfSearch(inputValue, startPos);
      setState((previousState: ITeamMemberLookupState) => ({
        ...previousState,
        highlightedIndex: 0,
        userSearch: {
          ...previousState.userSearch,
          endPos,
        },
        isStartingNewSearch: false,
        isOpen: true,
      }));
    } else if (!isStartingNewSearch && isOpen && (!startPos || inputValue.indexOf('@', startPos - 1) !== startPos - 1)) {
      // if they deleted the @ symbol immediately before our search term we gotta
      // close the menu
      closeMenu();
    } else if (!isStartingNewSearch && isOpen && startPos) {
      const endPos = findEndPositionOfSearch(inputValue, startPos);
      const newSearchTerm = inputValue.substring(startPos, endPos)
        .trim().toLocaleLowerCase();
      if (searchTerm !== newSearchTerm) {
        setState((previousState: ITeamMemberLookupState) => ({
          ...previousState,
          highlightedIndex: 0,
          userSearch: {
            ...previousState.userSearch,
            endPos,
            searchTerm: newSearchTerm,
          },
        }));
        updateFilteredTeam(newSearchTerm);
      }
    }
  }, [inputValue, closeMenu, isStartingNewSearch,
    searchTerm, isOpen, startPos, updateFilteredTeam]);

  const handleInputKeyboardEevents = useCallback((e: Event) => {
    const keyboardEvent = e as KeyboardEvent;
    if (keyboardEvent.key === '@') {
      const newstartPos = ((inputRef.current?.selectionEnd) || 0) + 1;
      const beginSearchState: ITeamMemberLookupState = {
        ...defaultState,
        userSearch: {
          ...defaultState.userSearch,
          startPos: newstartPos,
          endPos: newstartPos,
        },
        isStartingNewSearch: true,
      };
      setState(beginSearchState);
    } else if (isOpen) {
      if (filteredTeam && filteredTeam.length > 0) {
        if (keyboardEvent.key === 'ArrowUp') {
          keyboardEvent.stopPropagation();
          keyboardEvent.preventDefault();
          if (highlightedIndex > 0) {
            setState((currentState: ITeamMemberLookupState) => ({
              ...currentState,
              highlightedIndex: highlightedIndex - 1,
            }));
          }
        } else if (keyboardEvent.key === 'ArrowDown') {
          keyboardEvent.stopPropagation();
          keyboardEvent.preventDefault();
          if (highlightedIndex < filteredTeam.length - 1) {
            setState((currentState: ITeamMemberLookupState) => ({
              ...currentState,
              highlightedIndex: highlightedIndex + 1,
            }));
          }
        } else if (keyboardEvent.key === 'Enter') {
          keyboardEvent.stopPropagation();
          keyboardEvent.preventDefault();
          onUserSelected(filteredTeam[highlightedIndex]);
          closeMenu();
        } else if (keyboardEvent.key === 'Escape' && isOpen) {
          keyboardEvent.stopPropagation();
          keyboardEvent.preventDefault();
          closeMenu();
        }
      }
    }
  }, [isOpen, highlightedIndex, inputRef, filteredTeam, onUserSelected, closeMenu]);

  useEffect(() => {
    if (inputRef && inputRef.current) {
      const ref = inputRef.current;
      ref.addEventListener('keydown', handleInputKeyboardEevents);
      return () => {
        ref.removeEventListener('keydown', handleInputKeyboardEevents);
      };
    }
    return undefined;
  }, [handleInputKeyboardEevents, inputRef]);

  return (
    <UserLookupMenu<ITeamMember>
      menuItemRef={menuItemRef}
      users={filteredTeam}
      isOpen={state.isOpen}
      highlightedIndex={highlightedIndex}
      onMenuItemClicked={onUserSelected}
    />
  );
};

export default UserMenuLookupContainer;
