import React, {
  useEffect, useCallback, MutableRefObject, useState, useRef,
} from 'react';
import Menu, { MenuList, MenuListItem } from '@fv-components/menu';
import { Corner } from '@fv-components/menu-surface';
import { Input } from '@fv-components/text-field';

const css = require('./LookupMenu.module.scss');

interface ILookupMenuProps<T> {
  isOpen: boolean;
  onMenuItemSelected: (item: T) => void;
  descriptionField: string;
  keyField: string;
  selectedOrgId?: number;
  descriptionPrefix?: string;
  inputRef: MutableRefObject<Input | HTMLElement | null >;
  onMenuClosed?: VoidFunction;
  items?: T[];
}

const LookupMenu: <T>(
  props: ILookupMenuProps<T>,
) => React.ReactElement = <T extends { [key: string]: any }>(
  {
    isOpen,
    onMenuItemSelected,
    keyField,
    descriptionField,
    descriptionPrefix,
    inputRef,
    onMenuClosed,
    items,
    selectedOrgId,
  }: ILookupMenuProps<T>,
) => {
  const [highlightedIndex, setHighlightedIndex] = useState<number>(0);
  const menuRef = useRef<Menu>(null);
  const [anchorElement, setAnchorElement] = useState<HTMLElement>();

  const stopFurtherAction = (keyboardEvent: KeyboardEvent) => {
    keyboardEvent.stopPropagation();
    keyboardEvent.preventDefault();
  };

  useEffect(() => {
    if (items) {
      const num: number = selectedOrgId
        ? items.findIndex((item) => item.orgId === selectedOrgId)
        : 0;
      setHighlightedIndex(num);
    }
  }, [items, selectedOrgId]);

  const scroll = useCallback((index: number) => {
    const li = menuRef.current?.listElements[index];
    if (li) {
      li.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, []);

  const handleInputKeyboardEevents = useCallback((e: Event) => {
    const keyboardEvent = e as KeyboardEvent;
    if (isOpen && items) {
      if (keyboardEvent.key === 'ArrowUp') {
        stopFurtherAction(keyboardEvent);
        if (highlightedIndex !== 0) {
          setHighlightedIndex(highlightedIndex - 1);
          scroll(highlightedIndex - 1);
        }
      } else if (keyboardEvent.key === 'ArrowDown') {
        stopFurtherAction(keyboardEvent);
        if (highlightedIndex < items.length - 1) {
          setHighlightedIndex(highlightedIndex + 1);
          scroll(highlightedIndex + 1);
        }
      } else if (keyboardEvent.key === 'Enter') {
        stopFurtherAction(keyboardEvent);
        if (items[highlightedIndex]) {
          onMenuItemSelected(items[highlightedIndex]);
          if (onMenuClosed) {
            onMenuClosed();
          }
        }
      } else if ((keyboardEvent.key === 'Escape' || keyboardEvent.key === 'Tab') && isOpen) {
        if (onMenuClosed) {
          onMenuClosed();
        }
      }
    }
  }, [highlightedIndex, isOpen, items, onMenuClosed, onMenuItemSelected, scroll]);

  useEffect(() => {
    if (inputRef?.current) {
      if (inputRef.current instanceof Input && inputRef.current.inputElement) {
        const ref = inputRef.current.inputElement;
        setAnchorElement(ref);
        ref.addEventListener('keydown', handleInputKeyboardEevents);
        return () => {
          ref.removeEventListener('keydown', handleInputKeyboardEevents);
        };
      }
      if (inputRef.current instanceof HTMLElement) {
        const ref = inputRef.current;
        setAnchorElement(ref);
        ref.addEventListener('keydown', handleInputKeyboardEevents);
        return () => {
          ref.removeEventListener('keydown', handleInputKeyboardEevents);
        };
      }
    }

    return undefined;
  }, [handleInputKeyboardEevents, inputRef]);

  const onSelected = (index: number) => {
    if (items) {
      onMenuItemSelected(items[index]);
    }
  };

  return (
    <Menu
      open={isOpen}
      anchorCorner={Corner.BOTTOM_LEFT}
      anchorElement={anchorElement}
      anchorMargin={{ left: 5, top: 5 }}
      onClose={onMenuClosed}
      quickOpen
      onSelected={onSelected}
      ref={menuRef}
    >
      <MenuList>
        {items?.map(
          (item: T, index) => (
            <MenuListItem
              key={item[keyField]}
              tabIndex={-1}
              className={index === highlightedIndex ? css.highlighted : ''}
            >
              <div className={css.liDetail}>
                {`${descriptionPrefix || ''}${item[descriptionField]}`}
              </div>
            </MenuListItem>
          ),
        )}
      </MenuList>
    </Menu>
  );
};

export default LookupMenu;
