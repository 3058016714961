import React, { FC, useState, useEffect } from 'react';
import LinearProgress from '@fv-components/linear-progress';
import ErrorMsg from '../../ErrorMsg';

const css = require('./Progress.module.scss');

interface IProgressProps {
  logo: string;
  message?: string;
  title?: string;
  errorMsg?: string;
}

const Progress: FC<IProgressProps> = ({
  logo,
  message,
  title,
  errorMsg,
}: IProgressProps) => {
  const [showSupportMessage, setShowSupportMessage] = useState<boolean>(false);

  // Display a "support message" after 15 seconds
  useEffect(() => {
    if (!showSupportMessage) {
      setTimeout(() => {
        setShowSupportMessage(true);
      }, 15000);
    }
  }, [showSupportMessage, setShowSupportMessage]);

  return (
    <>
      <div className={css.progressWrapper}>
        <img width="90" height="90" src={logo} alt={title} title={title} />
        <h1>{title}</h1>
        {!showSupportMessage && (
          <>
            <LinearProgress indeterminate />
            <>{message}</>
          </>
        )}
        {showSupportMessage && (
          <ErrorMsg errorText={errorMsg} contactSupport={showSupportMessage} />
        )}
      </div>
    </>
  );
};

export default Progress;
