import React, {
  FC, useEffect, useState, useCallback, useRef,
} from 'react';

// Utilities
import LinearProgress from '@fv-components/linear-progress';
import { ExecutionResult } from 'apollo-link';
import {
  getIsComposing,
  getCurrentEmailPayload,
} from '../../../util-helpers/office';
import {
  nrPageAction,
  nrCustomAttribute,
  nrInteraction,
  nrInteractionTrace,
} from '../../../util-helpers/newrelic';

// FV API
import useQueryNote from '../../../util-api/useQueryNote';
import useQueryProjectTeam from '../../../util-api/useQueryProjectTeam';
import useMutateEmail from '../../../util-api/useMutateEmail';

// Child Components
import EmailActivityInput from './EmailActivityInput';
import EmailActivityAttachment from './EmailActivityAttachment';
import EmailActivityHeader from './EmailActivityHeader';
import EmailActivityChat from './EmailActivityChat';

// Styles
const css = require('./EmailActivity.module.scss');

interface IEmailActivityProps {
  currentUserId: string;
  currentEmailId?: string;
  currentProjectId: number;
  scrollContainer: React.RefObject<HTMLDivElement>;
}

export interface IEmlInformation {
  name: string,
  isAttached: boolean,
  folderId: number,
}

export interface IOfficeAttachment {
  attachmentDetails: Office.AttachmentDetails,
  isAttached: boolean,
  folderId: number,
}

const updatePartnerId = (currentEmailId?: string, currentProjectId?: number) => {
  if (currentEmailId && currentProjectId) {
    return `outlookId:${currentEmailId}-projectId:${currentProjectId}`;
  }
  return `outlookId:${Date.now()}-projectId:developerMode`;
};

const EMAIL_ERROR_TEXT = 'We had trouble adding this email to your Filevine project.';
const FETCHING_PAYLOAD_ERROR_TEXT = 'We had trouble fetching this email from Outlook.';

const EmailActivity: FC<IEmailActivityProps> = (
  {
    currentUserId,
    currentEmailId,
    currentProjectId,
    scrollContainer,
  }: IEmailActivityProps,
) => {
  const isComposing = getIsComposing();

  const officeAttachments = Office?.context?.mailbox?.item?.attachments;
  const [mutatedAttachments, setMutatedAttachments] = useState<IOfficeAttachment[]>(
    officeAttachments.map((item: Office.AttachmentDetails) => ({
      attachmentDetails: item,
      isAttached: true,
      folderId: 0,
    })),
  );

  const [isInputFocus, setIsInputFocus] = useState(false);
  const [isRefreshingComments, setIsRefreshingComments] = useState(false);
  const [isCommentAdded, setIsCommentAdded] = useState(false);
  const [errorMsg, setErrormsg] = useState('');
  const [isSending, setIsSending] = useState(false);

  const {
    // error: emailErr, error doesn't update on mutation bug fixed in apollo v3 beta
    addEmail,
    loading: isSendingEmail,
    error: addEmailError,
    called: addEmailCalled,
  } = useMutateEmail(currentProjectId);

  const {
    getProjectTeam,
    projectTeamSearchResults: { team },
  } = useQueryProjectTeam();

  const getTeamMemberName = (id: number) => {
    let author;
    if (team && team.items) {
      author = team.items.find((t) => t.userId.native === id);
    }
    return author ? author.fullname : '';
  };

  // Set the email partner id and keep it updated if the user changes emails
  const [partnerId, setPartnerId] = useState(
    updatePartnerId(currentEmailId, currentProjectId),
  );
  useEffect(() => {
    const updatedPartnerId = updatePartnerId(currentEmailId, currentProjectId);
    setPartnerId(updatedPartnerId);
  }, [currentEmailId, currentProjectId]);

  useEffect(() => {
    if (currentProjectId) {
      getProjectTeam(currentProjectId, '', 1000);
    }
  }, [currentProjectId, getProjectTeam]);

  useEffect(() => {
    if (addEmailError) {
      setErrormsg(addEmailError.message);
    }
  }, [addEmailError]);

  // Note fetch setup
  const {
    getNote,
    note,
    noteLoading,
  } = useQueryNote();

  const noteId = note?.noteId?.native;

  useEffect(() => {
    const getNoteAsync = async () => {
      await getNote(`@${partnerId}`);
    };
    getNoteAsync();
  }, [partnerId, getNote]);

  const onCommentsAdded = () => {
    nrInteraction();
    setIsCommentAdded(true);
  };

  const onCommentsRefreshed = () => {
    setIsRefreshingComments(false);
    setIsCommentAdded(false);
    getNote(`@${partnerId}`);
  };

  const [emlInformation, setEmlInformation] = useState<IEmlInformation>();

  // entry function for "send" button click
  const onCommentPending = async () => {
    setIsSending(true);
    // putting this here so as to not need to fetch the entire body until sending to FV
    try {
      // New Relic
      nrPageAction('start_onCommentPending', { partnerId });
      nrInteractionTrace('onCommentPending', () => nrCustomAttribute('emailAttached', partnerId));

      // if the outlook UI gets stale in the web
      // the download can fail
      // the addin gets in a bad state
      const payload = await getCurrentEmailPayload(
        partnerId,
        emlInformation!,
        mutatedAttachments
          .filter((item: IOfficeAttachment) => item.isAttached),
      );
      if (payload) {
        addEmail(payload)
          .then((executionResult: ExecutionResult) => {
            if (!executionResult.errors) {
              getNote(`@${partnerId}`);
              setIsSending(false);
              // New Relic
              nrPageAction('end_onCommentPending', { partnerId });
            }
          })
          .catch((error: Error) => {
            setErrormsg(error?.message || EMAIL_ERROR_TEXT);
            setIsSending(false);
            nrPageAction('error_onCommentPending', { error: `${error.message}` });
          });
      }
    } catch (e) {
      setErrormsg(e?.message || FETCHING_PAYLOAD_ERROR_TEXT);
      setIsSending(false);
      nrPageAction('error_onCommentPending', { error: `${e.message}` });
    }
  };

  const prevEmailId = useRef<string>();
  const prevProjectId = useRef<number>();
  useEffect(() => {
    // ensure when we first render we're not sending
    // setter is called from child component
    // this component should always render isSending = false
    if (isSendingEmail) setIsSending(true);
    setIsSending(false);
    if (currentEmailId !== prevEmailId.current
      || currentProjectId !== prevProjectId.current) {
      setMutatedAttachments(officeAttachments
        .map((item: Office.AttachmentDetails) => ({
          attachmentDetails: item,
          isAttached: !item.isInline,
          folderId: 0,
        })));
    }
    prevEmailId.current = currentEmailId;
    prevProjectId.current = currentProjectId;
  }, [currentEmailId, currentProjectId, isSending, isSendingEmail, officeAttachments]);

  const onSelectionChange = useCallback((selectedIds: string[]) => {
    const mutated = mutatedAttachments.map((item: IOfficeAttachment) => (selectedIds?.includes(
      item.attachmentDetails.id)
      ? { attachmentDetails: item.attachmentDetails, folderId: item.folderId, isAttached: true }
      : { attachmentDetails: item.attachmentDetails, folderId: item.folderId, isAttached: false }));
    setMutatedAttachments(mutated);
  }, [mutatedAttachments]);

  const onRenameFile = useCallback((id: string, newName:string) => {
    const mutated = mutatedAttachments
      .map((item:IOfficeAttachment) => (item.attachmentDetails.id === id
        ? {
          attachmentDetails: { ...item.attachmentDetails, name: newName },
          isAttached: item.isAttached,
          folderId: item.folderId,
        }
        : item));
    setMutatedAttachments(mutated);
  }, [mutatedAttachments]);

  const onFolderDestinationChange = useCallback((id: string, folderId: number) => {
    const mutated = mutatedAttachments
      .map((item:IOfficeAttachment) => (item.attachmentDetails.id === id
        ? {
          attachmentDetails: { ...item.attachmentDetails },
          folderId,
          isAttached: item.isAttached,
        }
        : item));
    setMutatedAttachments(mutated);
  }, [mutatedAttachments]);

  const isDoneSending = (completed: boolean) => {
    if (isSending && completed) setIsSending(false);
  };

  return (
    <div className={css.emailActivityContainer}>
      <EmailActivityHeader
        getTeamMemberName={getTeamMemberName}
        currentEmailId={currentEmailId}
        isComposing={isComposing}
        note={note}
        isInputFocus={isInputFocus}
      />

      {!!noteId
      && (
      <EmailActivityChat
        getTeamMemberName={getTeamMemberName}
        noteId={noteId}
        isRefreshingComments={isRefreshingComments}
        isCommentAdded={isCommentAdded}
        onCommentsRefreshed={onCommentsRefreshed}
        scrollContainer={scrollContainer}
      />
      )}

      <div className={css.emailActivityInputContainer}>
        <div className={css.emailActivityDivider}>
          <LinearProgress
            indeterminate={noteLoading}
            closed={!noteLoading}
          />
        </div>

        <EmailActivityAttachment
          isHidden={noteLoading || !!noteId}
          onSelectionChange={onSelectionChange}
          attachments={mutatedAttachments.map((att) => att.attachmentDetails)}
          onRenameFile={onRenameFile}
          selectedIds={
            mutatedAttachments
              .filter((attachment: IOfficeAttachment) => attachment.isAttached)
              .map((item) => item.attachmentDetails.id)
          }
          onFolderDestinationChange={onFolderDestinationChange}
          setEmlInformation={setEmlInformation}
          currentEmailId={currentEmailId}
        />

        <EmailActivityInput
          projectId={currentProjectId}
          noteId={noteId}
          userId={currentUserId}
          onCommentAdded={onCommentsAdded}
          onAddNote={onCommentPending}
          isSavingNote={isSendingEmail && isSending}
          doneSending={isDoneSending}
          addEmailCalled={addEmailCalled}
          isComposing={false}
          refreshComments={() => setIsRefreshingComments(true)}
          onIsInputFocus={(focus: boolean) => setIsInputFocus(focus)}
          errorMessage={errorMsg}
        />
      </div>
    </div>
  );
};

export default EmailActivity;
