import React, { FC } from 'react';

const css = require('./SearchLimitWarning.module.scss');

interface ISearchLimitWarningProps {
  limit?: number;
}

const SearchLimitWarning: FC<ISearchLimitWarningProps> = (
  { limit }: ISearchLimitWarningProps,
) => (
  <div className={css.searchLimitWarning}>
    {`More than ${limit} results, please refine your search.`}
  </div>
);

export default SearchLimitWarning;
