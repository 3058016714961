import Button from '@fv-components/button';
import React, { FC, useState } from 'react';
import FilevineIcon from '../../FilevineIcon';

const css = require('./GenericCard.module.scss');

interface GenericCardProps {
  buttonTitle?: string;
  buttonIcon?: React.ReactElement<typeof FilevineIcon>;
  child: React.ReactElement;
  outlined?: boolean;
}

const GenericCard: FC<GenericCardProps> = (
  {
    buttonIcon, buttonTitle, child, outlined = true,
  }
  : GenericCardProps,
) => {
  const [isCardShowing, setIsCardShowing] = useState(false);

  const handleShowCard = () => {
    setIsCardShowing(true);
  };

  const handleCloseCard = () => {
    setIsCardShowing(false);
  };

  const page = (
    <div className={css.card}>
      <Button
        className={css.backButton}
        data-test="card-back-button"
        onClick={handleCloseCard}
        onKeyDown={handleCloseCard}
      >
        <FilevineIcon icon="chevron-left" />
        &nbsp;Back
      </Button>
      {child}
    </div>
  );

  const button = (
    <Button
      outlined={outlined}
      className={css.cardButton}
      onClick={handleShowCard}
      onKeyDown={handleShowCard}
    >
      {buttonIcon}
      {buttonTitle}
    </Button>
  );

  return (isCardShowing ? page : button);
};

export default GenericCard;
