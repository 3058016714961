import React, { FC } from 'react';
import DocumentSearch from './DocumentSearch';

// This will have more logic as we build out eg return a single doc manage view or a doc list etc.
// Getting the structure in place now knowing this.
// Likely also need state in here for currentDocId etc.
const DocumentWorkspace: FC = () => (
  <DocumentSearch />
);
export default DocumentWorkspace;
