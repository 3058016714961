import React, { FC } from 'react';

const css = require('./Persona.module.scss');

interface IPersonaProps {
  avatarUrl?: string;
  backgroundColor?: string;
  initials?: string;
  name?: string;
  rounded?: boolean;
  secondaryText?: string;
  secondLine?: string | JSX.Element;
  small?: boolean;
  large?: boolean;
  loading?: boolean;
}

export const getInitials = (name: string) => {
  // Used if there is no avatarUrl or initials prop. Or called externally.
  const nameParts = name.split(' ');
  let initials = nameParts[0].substring(0, 1).toUpperCase();

  if (nameParts.length > 1) {
    initials += nameParts[nameParts.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};

// TODO: Function to get random color from backgroundColors array
//   - remove from array and restock when empty
//   - If no backgroundColor is passed get a random color

// Suggestions for background color options to use with random or loop
export const backgroundColors = [
  'lightblue',
  'grey',
  'midnightblue',
  'lightgreen',
  'darkred',
  'orange',
  'lightpink',
  'teal',
  'mediumpurple',
  'darkgrey',
  'lightskyblue',
  'greenyellow',
  'steelblue',
  'palegreen',
  'mediumvioletred',
  'tan',
  'hotpink',
  'slategrey',
  'mediumslateblue',
  'lightseagreen',
  'orangered',
  'sandybrown',
];

const Persona: FC<IPersonaProps> = ({
  avatarUrl,
  backgroundColor,
  initials,
  name,
  rounded = true,
  secondaryText,
  secondLine,
  small,
  large,
  loading,
}: IPersonaProps) => (
  <span className={css.personaContainer}>
    <>
      {avatarUrl
        ? (
          <>
            <img
              alt=""
              className={[
                css.personaAvatar,
                small ? css.small : '',
                large ? css.large : '',
                rounded ? css.rounded : '',
              ].join(' ')}
              style={{ backgroundColor: backgroundColor || '' }}
              src={avatarUrl}
            />
          </>
        )
        : (
          <>
            <span
              className={[
                css.personaAvatar,
                small ? css.small : '',
                large ? css.large : '',
                rounded ? css.rounded : '',
                loading ? css.loading : '',
              ].join(' ')}
              style={{ backgroundColor: backgroundColor || '' }}
            >
              <span
                className={[
                  css.personaInitials,
                  small ? css.small : '',
                  large ? css.large : '',
                ].join(' ')}
              >
                {initials || (name && getInitials(name)) || ''}
              </span>
            </span>
          </>
        )}
    </>
    {loading
      ? <div className={css.personaNameLoading}>Loading User Details...</div>
      : (
        <div>
          <div className={css.personaDetails}>
            <span className={css.personaName}>{name}</span>
            {secondaryText && <span className={css.personaSecondaryText}>{secondaryText}</span>}
          </div>
          {secondLine && <span className={css.personaSecondaryText}>{secondLine}</span>}
        </div>
      )}
  </span>
);

export default Persona;
