import { useState } from 'react';

export type useInputReturn = [
  {[key: string]: string;},
  (event: {target: { name: string; value: string; } }
    | React.ChangeEvent<HTMLInputElement>
    | React.ChangeEvent<HTMLTextAreaElement>
  ) => void,
];

const useInput = (initialValues: {}): useInputReturn => {
  const [values, setValues] = useState(initialValues);

  return [
    values,
    (e) => {
      setValues({
        ...values,
        [e.target.name]: e.target.value,
      });
    },
  ];
};

export default useInput;
