import React, { FC, useState } from 'react';
import Button from '@fv-components/button';
import FilevineIcon from '../../FilevineIcon';

const css = require('./RefreshButton.module.scss');

interface IRefreshButtonProps {
  onRefresh: VoidFunction;
}
const RefreshButton: FC<IRefreshButtonProps> = ({ onRefresh }) => {
  const [fabDisabled, setFabDisabled] = useState(false);
  const onRefreshClick = () => {
    if (!fabDisabled) {
      setFabDisabled(true);
      setTimeout(() => {
        setFabDisabled(false);
      }, 3000);
      onRefresh();
    }
  };
  return (
    <Button
      disabled={fabDisabled}
      onClick={onRefreshClick}
      icon={<FilevineIcon icon="refresh" />}
      className={css.refreshButton}
    />
  );
};

export default RefreshButton;
