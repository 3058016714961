import { useLazyQuery } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client/errors/ApolloError';
import gql from 'graphql-tag';
import { useCallback } from 'react';

export interface IDocument {
  documentId: {
    native: number;
    partner: number | null;
  };
  filename: string,
  size: number,
  projectId: {
    native: number;
    partner?: number;
  };
  uploaderId: {
    native: number;
    partner: number | null;
  };
  folderId: {
    native: number;
    partner: number | null;
  };
  folderName?: string;
  uploadDate: string,
  hashtags: string[],
  uploaderFullname: string,
}

interface IProjectDocuments {
  count: number;
  offset: number;
  limit: number;
  hasMore: boolean;
  requestedFields: string;
  items: IDocument[];
}

interface IProjectDocumentsResponse {
  documents: IProjectDocuments;
}

interface IProjectDocumentPayload {
  called: boolean;
  loading: boolean;
  error?: ApolloError;
  documents?: IProjectDocuments;
}

export interface IQueryProjectDocumentsHook {
  projectDocumentsSearchResult: IProjectDocumentPayload;
  getProjectDocuments: (projectId: number, searchTerm: string, folderId?: string) => void;
}

export const DOCUMENTS_QUERY = gql`
  query documentsQuery {
    documents(id: $id, searchTerm: $searchTerm, folderId: $folderId)
      @rest(type: "Documents", path: "/projects/{args.id}/documents?searchTerm={args.searchTerm}&folderId={args.folderId}") {
        count
        offset
        limit
        hasMore
        requestedFields
        items
    }
  }`;

const useQueryProjectDocuments = (): IQueryProjectDocumentsHook => {
  const [executeQuery, {
    data, error, loading, called,
  }] = useLazyQuery<IProjectDocumentsResponse>(
    DOCUMENTS_QUERY, { notifyOnNetworkStatusChange: true, fetchPolicy: 'cache-and-network' },
  );

  const getProjectDocuments = useCallback(
    (id: number, searchTerm: string, folderId: string = '') => executeQuery(
      { variables: { id, searchTerm: encodeURIComponent(searchTerm), folderId } },
    ), [executeQuery],
  );

  return {
    projectDocumentsSearchResult: {
      called,
      error,
      loading,
      documents: data && data.documents,
    },
    getProjectDocuments,
  };
};

export default useQueryProjectDocuments;
