import { getCurrentOrg, getUserId } from '../Auth/auth';
import { getConfig } from '../config';

const { hostUrl } = getConfig();

interface PendoInitOptions {
  visitor: {};
  account: {};
}

declare global {
  interface Window {
    pendo: {
      initialize: (options: PendoInitOptions) => void;
    };
  }
}

const { pendo } = window;

const pendoInitialize = () => {
  if (pendo) {
    const org = getCurrentOrg();
    const userId = getUserId();
    pendo.initialize({
      visitor: {
        id: userId,
        app: hostUrl,
      },
      account: {
        id: org?.orgId,
        name: org?.name,
      },
    });
  }
};

// eslint-disable-next-line import/prefer-default-export
export { pendoInitialize };
