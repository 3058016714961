const css = require('./Document.module.scss');

interface IconCSS {
  fontAwesome: string;
  cssClass: string;
}

const getIcon = (ext: string): IconCSS => {
  switch (ext) {
    case 'pdf': return { fontAwesome: 'file-pdf-o', cssClass: css.pdf };
    case 'docx':
    case 'doc': return { fontAwesome: 'file-word-o', cssClass: css.doc };
    case 'xlsx':
    case 'xls':
    case 'csv': return { fontAwesome: 'file-excel-o', cssClass: css.excel };
    case 'pptx':
    case 'ppt': return { fontAwesome: 'file-powerpoint-o', cssClass: css.powerpoint };
    case 'msg':
    case 'eml': return { fontAwesome: 'envelope-o', cssClass: css.eml };
    case 'tiff':
    case 'jpeg':
    case 'jpg':
    case 'gif':
    case 'bmp':
    case 'png': return { fontAwesome: 'file-image-o', cssClass: css.image };
    case 'html':
    case 'txt':
    case 'rtf': return { fontAwesome: 'file', cssClass: css.text };
    case 'mpeg':
    case 'mp4':
    case 'avi': return { fontAwesome: 'file-video-o', cssClass: css.video };
    case 'mp3':
    case 'wav':
    case 'flac': return { fontAwesome: 'file-audio-o', cssClass: css.audio };
    default:
      return { fontAwesome: 'file', cssClass: css.defaultFile };
  }
};

export default getIcon;
