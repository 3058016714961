import React, { FC, Fragment } from 'react';
import Button from '@fv-components/button';

import FilevineIcon from '../../FilevineIcon';
import EmailLink from '../EmailLink';
import EmailActionButtons from '../EmailActionButtons';
import Persona from '../Persona';

const css = require('./ContactCard.module.scss');

interface IContactProps {
  emails: {
    address: string;
    emailId: {
      native: number;
    };
    label: string;
  }[];
  fullName: string;
  isExpanded: boolean;
  contactId: number;
  pictureUrl: string;
  primaryEmail: string;
  primaryEmailLabel: string;
  userRole?: string;
  setExpandedContactId: (contactId: number) => void;
  isComposing: boolean;
  isMeeting: boolean;
}

const Contact: FC<IContactProps> = ({
  emails,
  fullName,
  isExpanded,
  contactId,
  pictureUrl,
  primaryEmail,
  primaryEmailLabel,
  userRole,
  setExpandedContactId,
  isComposing,
  isMeeting,
}: IContactProps) => (
  <div className={isExpanded ? css.contactContainerExpanded : ''}>
    <div className={css.contactPersonaContainer}>
      <div className={css.contactPersona}>
        <Persona
          small
          backgroundColor="white"
          name={fullName}
          secondaryText={userRole}
          secondLine={(
            <EmailLink
              displayName={fullName}
              emailAddress={primaryEmail}
              emailField={!isMeeting ? 'to' : 'requiredAttendees'}
              emailLabel={primaryEmailLabel}
            />
          )}
          avatarUrl={pictureUrl}
        />
        { (isExpanded && primaryEmail)
            && (
            <div className={css.contactPrimaryEmailActions}>
              <EmailActionButtons
                emailAddress={primaryEmail}
                displayName={fullName}
              />
            </div>
            )}
      </div>
      {((!isComposing && emails.length > 1)
          || (isComposing && emails.length > 0))
          && (
          <Button
            className="contact-more-btn"
            onClick={() => setExpandedContactId(
              isExpanded ? 0 : contactId,
            )}
          >
            <FilevineIcon
              className={css.contactMoreIcon}
              icon={isExpanded ? 'chevron-down' : 'chevron-left'}
            />
          </Button>
          )}

    </div>
    {(emails.length > 1 && isExpanded) && (
      <>
        <hr className={css.contactMoreSeparator} />
        <div className={css.contactMoreContainer}>
          {emails.map(({ address, emailId, label }) => {
            if (address !== primaryEmail) {
              return (
                <Fragment key={emailId.native}>
                  <div className={css.contactSecondaryEmail}>
                    <EmailLink
                      displayName={fullName}
                      emailAddress={address}
                      emailField={!isMeeting ? 'to' : 'requiredAttendees'}
                      emailLabel={label}
                    />
                  </div>
                  <EmailActionButtons
                    emailAddress={address}
                    displayName={fullName}
                  />
                </Fragment>
              );
            }
            return false;
          })}
        </div>
      </>
    )}
  </div>
);

export default Contact;
