import React, { FC } from 'react';
import { getIsProdEnv } from '../util-helpers/common';
import { getConfig } from '../config';

const css = require('./EphemeralId.module.scss');

const EphemeralId: FC = () => {
  const { ephemeralId } = getConfig();
  const isProd = getIsProdEnv();

  if (!isProd && ephemeralId) {
    return (
      <div className={css.idText}>
        {`Ephemeral Id: ${ephemeralId}`}
      </div>
    );
  }

  return <></>;
};

export default EphemeralId;
