import React, {
  FC, useContext, useState, useRef, useEffect,
} from 'react';

// Utilities
import {
  getIsComposing,
  getIsMeeting,
  getIsReading,
  getIsOutlook,
} from '../../util-helpers/office';

// FV API
import useMutateRecentProjects from '../../util-api/useMutateRecentProjects';
import useCountRenders from '../../util-hooks/useCountRenders';
import { getUserId } from '../../Auth/auth';

// Child Components
import ErrorMsg from '../../ErrorMsg';
import ProjectContext from '../ProjectContext';
import Contacts from './Contacts';
import EmailActivity from './EmailActivity';
import EmailCompose from './EmailActivity/EmailCompose';
import ProjectHeader from './ProjectHeader';
import Team from './Team';
import ProjectWorkspaceNavigation from './ProjectWorkspaceNavigation';

// Styles
const cssCommon = require('../../Common.module.scss');
const css = require('./ProjectWorkspace.module.scss');

interface ITabContentItem {
  name: string;
  element: JSX.Element;
}

const selectMailItemErrorMessage = "Select a mail item to begin. If you have selected an email and are still seeing this error message. Press the 'Pop out' icon button in the top right hand corner of the email";

// pure function that is easy to unit test
const getEmailActivityComponent = (
  userId: string,
  isComposing: boolean,
  isReading: boolean,
  scrollContainer: React.RefObject<HTMLDivElement>,
  currentProjectId?: number,
  currentEmailId?: string,
): JSX.Element => {
  // because of these if statement its safe to assume
  // that isOffice is true in EmailCompose and EmailActivity and all child
  // components

  if (isComposing && !!currentProjectId) {
    return (
      <EmailCompose
        currentUserId={userId}
        currentProjectId={currentProjectId}
        currentEmailId={currentEmailId}
      />
    );
  }
  if (isReading && !!currentProjectId) {
    return (
      <EmailActivity
        currentUserId={userId}
        currentProjectId={currentProjectId}
        currentEmailId={currentEmailId}
        scrollContainer={scrollContainer}
      />
    );
  }
  return (<></>);
};

const ProjectWorkspace: FC = () => {
  useCountRenders('ProjectWorkspace');
  const isOutlook = getIsOutlook();
  const isComposing = getIsComposing();
  const isReading = getIsReading();
  const isMeeting = getIsMeeting();

  const { currentProjectData, currentProjectId, currentEmailId } = useContext(ProjectContext);
  const { updateRecentProjects } = useMutateRecentProjects();

  const [activeTabIdx, setActiveTabIdx] = useState(0);
  const handleActiveTabUpdate = (idx: number) => setActiveTabIdx(idx);

  const scrollContainer = useRef<HTMLDivElement>(null);
  const scrollTopRef = useRef<HTMLDivElement>(null);
  const scrollBottomRef = useRef<HTMLDivElement>(null);

  const scrollTo = (pos: 'top' | 'bottom') => {
    if (pos === 'bottom' && scrollBottomRef && scrollBottomRef.current) {
      scrollBottomRef.current.scrollIntoView({ behavior: 'auto' });
    }
    if (pos === 'top' && scrollTopRef && scrollTopRef.current) {
      scrollTopRef.current.scrollIntoView({ behavior: 'auto' });
    }
  };
  useEffect(() => {
    if (activeTabIdx === 0) {
      scrollTo('bottom');
    } else {
      scrollTo('top');
    }
  }, [activeTabIdx]);

  useEffect(() => {
    const userId = getUserId();
    if (userId && currentProjectId) {
      updateRecentProjects(userId, currentProjectId);
    }
  }, [currentProjectId, updateRecentProjects]);

  const tabContentItems: ITabContentItem[] = [
    {
      name: 'EmailActivity',
      element: getEmailActivityComponent(
        getUserId(),
        isComposing,
        isReading,
        scrollContainer,
        currentProjectId,
        currentEmailId,
      ),
    },
    {
      name: 'Contacts',
      element: <Contacts
        projectId={currentProjectId}
        projectEmail={currentProjectData?.projectEmailAddress}
      />,
    },
    {
      name: 'Team',
      element: <Team projectId={currentProjectId} />,
    },
  ];

  return (
    <>
      <div className={css.projectWorkspaceHeader}>
        <ProjectHeader />

        {(isOutlook && !isMeeting) && (
          <ProjectWorkspaceNavigation
            activeTabIdx={activeTabIdx}
            handleActiveTabUpdate={handleActiveTabUpdate}
          />
        )}
      </div>

      <div className={css.projectWorkspaceContent} ref={scrollContainer}>
        <div ref={scrollTopRef} />

        {isOutlook && (
        <>
          {!isReading && !isComposing
            && (<ErrorMsg contactSupport errorText={selectMailItemErrorMessage} />)}
          {!isMeeting ? tabContentItems.map((c: ITabContentItem, i: number) => (
            <div
              key={c.name}
              className={i === activeTabIdx ? '' : cssCommon.hidden}
            >
              {c.element}
            </div>
          )) : (
            <Contacts
              projectId={currentProjectId}
              projectEmail={currentProjectData?.projectEmailAddress}
            />
          )}
        </>
        )}

        <div ref={scrollBottomRef} />
      </div>

    </>
  );
};

export default ProjectWorkspace;
