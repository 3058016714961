import React, { FC, useState, useEffect } from 'react';
import Button from '@fv-components/button';

import { getConfig } from '../../config';
import { getIsOfficeJs } from '../../util-helpers/office';
import { isIPadWebBrowser, isSafariWebBrowser } from '../../util-helpers/common';
import EphemeralId from '../../EphemeralId';

const css = require('./Landing.module.scss');

let dialog: Office.Dialog;

const Landing: FC = () => {
  // NOTE: Safari web browser currently has an issue with latest auth api
  // this is to disable auth on Safari while the issue is addressed
  const navigator = global.window && global.window?.navigator;
  const isSafari = isSafariWebBrowser(navigator?.userAgent, navigator?.vendor);
  const isIpadBrowser = isIPadWebBrowser(navigator?.userAgent);
  const isBrowserSupported = !isSafari && !isIpadBrowser;

  const [isLoginComplete, setIsLoginComplete] = useState(false);

  const { hostUrl } = getConfig();

  useEffect(() => {
    if (isLoginComplete) {
      if (dialog) {
        dialog.close();
      }
      window.location.assign(hostUrl);
    }
  }, [isLoginComplete, hostUrl]);

  const dialogCallback = (asyncResult: any) => {
    dialog = asyncResult.value;
    dialog.addEventHandler(
      Office.EventType.DialogMessageReceived, () => setIsLoginComplete(true),
    );
  };

  const openLogin = () => {
    if (!getIsOfficeJs()) {
      window.location.assign(`${hostUrl}/login`);
    } else {
      setIsLoginComplete(false);

      const dialogOptions = {
        height: 70,
        width: 30,
        promptBeforeOpen: false,
      };

      Office.context.ui.displayDialogAsync(
        `${hostUrl}/login`,
        dialogOptions,
        dialogCallback,
      );
    }
  };

  const supportMsgTitle = 'Browser Support is Coming Soon.';
  const safariMsgDesc = 'We are improving our support for Safari, but until then you may have a better experience using Google Chrome, Mozilla Firefox, or Microsoft Edge.';
  const iOSBrowserMessage = 'We are improving our support for iOS web browsers. Please check back soon.';
  const defaultMsgTitle = 'Sign in with your Filevine account.';
  const defaultMsgDesc = 'The Filevine Add-in provides a window into your Filevine projects from inside Outlook. Quickly send emails to your Filevine project, add activities, and reference project data, all without leaving Outlook.';
  const ctaMessage = isBrowserSupported ? defaultMsgTitle : supportMsgTitle;
  // eslint-disable-next-line no-nested-ternary
  const ctaDescription = isBrowserSupported ? defaultMsgDesc
    : isIpadBrowser ? iOSBrowserMessage : safariMsgDesc;
  return (
    <div className={css.loginContainer}>
      <EphemeralId />
      <img src="assets/fv-logo.png" alt="Filevine" />
      <span className={css.loginCTA}>
        {ctaMessage}
      </span>
      {isBrowserSupported && (
        <Button
          raised
          type="submit"
          className={css.loginButton}
          data-test="sign-in"
          onClick={openLogin}
        >
          Sign In
        </Button>
      )}
      <small>
        <div>
          {ctaDescription}
        </div>
        <br />
        <br />
        <div>
          Don’t have a Filevine account?
        </div>
        <a href="https://www.filevine.com/demo-request?utm_source=outlook" target="_blank" rel="noopener noreferrer">Schedule your demo today.</a>
      </small>
    </div>
  );
};

export default Landing;
